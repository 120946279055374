import { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Button } from "primereact/button"
import { ProgressSpinner } from "primereact/progressspinner"
import InvitationsService from "service/InvitationsService"
import { useUser } from "hooks/useUser"
import { useInvitation } from "hooks/useInvitation"
import { Card } from "primereact/card"
import { getCurrencyString } from "utilities/format"

const WireInvitationIndex = () => {
  const { token } = useUser()
  const headers = { Authorization: "bearer " + token }

  const { wireId } = useParams()

  const invitationsService = new InvitationsService()

  const navigate = useNavigate()

  const fetchInvitation = () => invitationsService.getWireInvite(headers, wireId)

  const handleAccept = () => invitationsService.resolveWireInvite(headers, wireId, "Accepted")

  const handleReject = () => invitationsService.resolveWireInvite(headers, wireId, "Rejected")

  const { isFetchingInvitation, invitationData, acceptingInvitation, rejectingInvitation, invitationStatus, acceptInvitation, rejectInvitation } = useInvitation(handleAccept, handleReject, fetchInvitation)

  useEffect(() => {
    if (invitationStatus === "Error") {
      navigate("/")
    }

    let timeout = null
    if (invitationStatus === "Accepted" || invitationStatus === "Rejected" || invitationStatus === "Canceled") {
      timeout = setTimeout(() => {
        navigate("/")
      }, 10000)
    }
    
    return () => clearTimeout(timeout)
  }, [invitationStatus, navigate])

  const invitationFooter = (
    <>
      <Button loading={acceptingInvitation} disabled={rejectingInvitation} label="Accept" className="mr-2" onClick={acceptInvitation} />
      <Button loading={rejectingInvitation} disabled={acceptingInvitation} label="Reject" onClick={rejectInvitation} />
    </>
  )

  const resolvedInvitationFooter = (
    <p>
      If you are not redirected automatically, <Link to="/">click here</Link>.
    </p>
  )

  return (
    <>
      {isFetchingInvitation ? (
        <ProgressSpinner />
      ) : (
        <div className="flex justify-content-center">
          {invitationStatus === "Accepted" && (
            <Card className="text-xl p-4" style={{ width: "780px" }} title="Invitation" subTitle={`The invitation was accepted`} footer={resolvedInvitationFooter}>
              You are now being redirected to the home page.
            </Card>
          )}
          {invitationStatus === "Rejected" && (
            <Card className="text-xl p-4" style={{ width: "780px" }} title="Invitation" subTitle={`The invitation was rejected`} footer={resolvedInvitationFooter}>
              You are now being redirected to the home page.
            </Card>
          )}
          {invitationStatus === "Canceled" && (
            <Card className="text-xl p-4" style={{ width: "780px" }} title="Invitation" subTitle={`The invitation was canceled`} footer={resolvedInvitationFooter}>
              You are now being redirected to the home page.
            </Card>
          )}
          {invitationStatus === "Wire Receiver Invited" && invitationData &&
            (
              <Card className="text-xl p-4" style={{ width: "780px" }} title="Invitation" subTitle={<p className="text-color">You have been invited by the organization / company <strong>{invitationData.organization}</strong> to receive the following wire:</p>} footer={invitationFooter}>
                <ul className="list-none p-0 mt-0">
                  <li>
                    <span className="font-bold">Amount: </span>
                    {getCurrencyString(invitationData.amount)}
                  </li>
                  <li>
                    <span className="font-bold">Role: </span>
                    {invitationData.role}
                  </li>
                  <li>
                    <span className="font-bold">Purpose: </span>
                    {invitationData.purpose}
                  </li>
                  {invitationData.purpose === "Other" && 
                    <li>
                      <span className="font-bold">Other Purpose: </span>
                      {invitationData.otherPurpose}
                    </li>}
                </ul>
                <h5>Transaction</h5>
                <ul className="list-none p-0 mt-0">
                  <li>
                    <span className="font-bold">Name: </span>
                    {invitationData.transactionName}
                  </li>
                  <li>
                    <span className="font-bold">Issuer: </span>
                    {invitationData.transactionIssuer}
                  </li>
                  <li>
                    <span className="font-bold">Par Amount: </span>
                    {getCurrencyString(invitationData.transactionParAmount)}
                  </li>
                  <li>
                    <span className="font-bold">Method of Sale: </span>
                    {invitationData.transactionType}
                  </li>
                </ul>
              </Card>
            )}
        </div>
      )}
    </>
  )
}

export default WireInvitationIndex
