import constants from "constants/constants"

const notificationIcon = (notificationType) => {
  switch (notificationType) {
    case constants.NOTIFICATION_TYPES.WIRE_INVITATION:
    case constants.NOTIFICATION_TYPES.PARTICIPANT_INVITATION:
      return "pi-envelope"
    case constants.NOTIFICATION_TYPES.WIRE_ACCEPTED:
      return "pi-check"
    case constants.NOTIFICATION_TYPES.WIRE_REJECTED:
      return "pi-times"
    default:
      return "pi-envelope"
  }
}

function NotificationListItem({ notification, onNotificationClick }) {
  return (
    <li role="menuitem">
      <button type="button" className="p-link" onClick={onNotificationClick} tabIndex="0">
        <i className={`pi ${notificationIcon(notification.type)}`} />
        <div className="notification-item">
          <div className="notification-summary">{notification.payload.Summary}</div>
          <div className="notification-detail">{notification.payload.Message}</div>
        </div>
      </button>
    </li>
  )
}

export default NotificationListItem
