import instructionsPdf from "assets/MuniSafe_Instructions_April_2023.pdf"

function InstructionsIndex() {
  return (
    <object data={instructionsPdf} type="application/pdf" className="w-full min-h-screen">
      <p>
        Your browser does not support PDFs. <a href={instructionsPdf} download="MuniSafe Instructions April 2023.pdf">Download the PDF</a>.
      </p>
    </object>
  )
}

export default InstructionsIndex