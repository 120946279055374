import { useMemo, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { TrackerContext } from "Context/TrackerContext"

const TrackerProvider = ({ children }) => {
  const location = useLocation()

  const value = useMemo(() => ({
    sendLoginEvent() {
      window.gtag('event', 'login', { method : 'User and Password' });
    },
    sendSignupEvent() {
      window.gtag('event', 'sign_up', { method : 'User and Password' });
    }
  }), [])

  useEffect(() => {
    window.gtag('set', 'page_location', window.location.href)
    window.gtag('event', 'page_view')
  }, [location.pathname])

  return (
    <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>
  )
}

export default TrackerProvider