import axios from "providers/ConfigureAxios"
import { getUrl } from "./config/serviceUrls"

export default class InvitationsService {
  getWireInvites(headers, type = "all") {
    return axios({
      method: "get",
      url: `${getUrl()}/invitations/wires?type=${type}`,
      headers,
    })
  }

  getWireInvite(headers, wireId) {
    return axios({
      method: "get",
      url: `${getUrl()}/invitations/wires/${wireId}`,
      headers
    })
  }

  resolveWireInvite(headers, wireId, status) {
    return axios({
      method: "put",
      url: `${getUrl()}/invitations/wires/${wireId}`,
      headers,
      data: {
        status
      }
    })
  }

  cancelWireInvite(headers, wireId, cancelationReason) {
    return axios({
      method: "put",
      url: `${getUrl()}/invitations/wires/${wireId}`,
      headers,
      data: {
        status: "Canceled",
        cancelationReason: cancelationReason ?? null
      }
    })
  }

  getParticipantInvite(headers, participantId) {
    return axios({
      method: "get",
      url: `${getUrl()}/invitations/participants/${participantId}`,
      headers
    })
  }

  getParticipantInvites(headers, type = "all") {
    return axios({
      method: "get",
      url: `${getUrl()}/invitations/participants?type=${type}`,
      headers,
    })
  }

  resolveParticipantInvite(headers, participantId, status) {
    return axios({
      method: "put",
      url: `${getUrl()}/invitations/participants/${participantId}`,
      headers,
      data: { status }
    })
  }

  cancelParticipantInvite(headers, participantId) {
    return axios({
      method: "put",
      url: `${getUrl()}/invitations/participants/${participantId}`,
      headers,
      data: {
        status: "Canceled"
      }
    })
  }

  getAllInvites(headers, type = "all") {
    return axios({
      method: "get",
      url: `${getUrl()}/invitations?type=${type}`,
      headers,
    })
  }
}
