import axios from "providers/ConfigureAxios"
import { getUrl } from "service/config/serviceUrls"

export default class WiresService {
  createWire(headers, wire) {
    return axios({
      method: "post",
      url: `${getUrl()}/wires/`,
      headers,
      data: wire,
    })
  }

  editWire(headers, wireId, wireEdit) {
    return axios({
      method: "put",
      url: `${getUrl()}/wires/${wireId}`,
      headers,
      data: wireEdit,
    })
  }

  getWires(headers) {
    return axios({
      method: "get",
      url: `${getUrl()}/wires/`,
      headers,
    })
  }

  getWireHistory(headers, wireId, txnId) {
    return axios({
      method: "get",
      url: `${getUrl()}/wires/history?wireId=${wireId}&txnId=${txnId}`,
      headers,
    })
  }

  deleteWire(headers, wireId) {
    return axios({
      method: "delete",
      url: `${getUrl()}/wires/${wireId}`,
      headers,
    })
  }

  sendInvitations(headers, transactionId) {
    return axios({
      method: "post",
      url: `${getUrl()}/wires/sendinvitations?transactionId=${transactionId}`,
      headers,
    })
  }

  getWiresInTransaction(headers, transactionId) {
    return axios({
      method: "get",
      url: `${getUrl()}/wires?transactionId=${transactionId}`,
      headers,
    })
  }
}
