import { Controller, useForm } from "react-hook-form"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { Calendar } from "primereact/calendar"
import { InputNumber } from "primereact/inputnumber"
import TransactionService from "service/TransactionService"
import { useUser } from "hooks/useUser"
import { useState } from "react"
import { useToast } from "hooks/useToast"
import { trimObjectValues } from "utilities/format"
import constants from "constants/constants"

const TransactionForm = ({ onTransactionCreated, setOpenTransactionForm }) => {
  const { control, handleSubmit, setFocus, trigger, watch, getValues } = useForm({
    defaultValues: {
      issuer: "",
      transactionName: "",
      closingDate: null,
      parAmount: null,
      type: null,
      isWireSender: false,
      wireSenderEmail: "",
      underwriterDiscount: null,
    },
    reValidateMode: "onChange",
  })
  const isWireSender = watch("isWireSender")
  const wireSenderEmail = watch("wireSenderEmail")

  const { token, userData } = useUser()
  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const [erIssuer, setErIssuer] = useState("")
  const [erTrname, setErTrname] = useState("")
  const [discountError, setDiscountError] = useState("")

  const transactionService = new TransactionService()

  const typeOptions = [
    { name: constants.TRANSACTION_TYPES.NEGOTIATED, value: constants.TRANSACTION_TYPES.NEGOTIATED, disabled: false },
    { name: constants.TRANSACTION_TYPES.COMPETITIVE, value: constants.TRANSACTION_TYPES.COMPETITIVE, disabled: true },
    { name: constants.TRANSACTION_TYPES.PRIVATE_PLACEMENT, value: constants.TRANSACTION_TYPES.PRIVATE_PLACEMENT, disabled: true },
  ]

  const isWireSenderOptions = [
    { name: "Yes", value: "Yes" },
    { name: "No", value: "No" },
  ]

  const onSubmit = (event) => {
    const isWireSender = event.isWireSender === "Yes"

    if (event.parAmount <= 0) {
      return toast.current.show({ severity: "error", summary: "Invalid PAR Amount", life: 8000 })
    }
    if (event.underwriterDiscount <= 0 || discountError.length > 0) {
      return toast.current.show({ severity: "error", summary: "Invalid Underwriter Discount Amount", life: 8000 })
    }
    setIsLoading(true)

    const closingDateMilliseconds = event.closingDate.setUTCHours(23, 59, 59, 999)
    const closingDate = new Date(closingDateMilliseconds)

    const body = trimObjectValues({
      issuer: event.issuer,
      transactionName: event.transactionName,
      closingDate: closingDate.toISOString(),
      parAmount: event.parAmount,
      underwriterDiscount: event.underwriterDiscount,
      type: event.type,
      isWireSender,
      ...(!isWireSender && { wireSenderEmail }),
    })

    if (body.type === constants.TRANSACTION_TYPES.NEGOTIATED) {
      body.wireSenderEmail = userData.email
    }

    const header = { Authorization: `bearer ${token}` }
    transactionService
      .createTransaction(header, body)
      .then((response) => {
        onTransactionCreated(response.data.id)
        if (isWireSender) {
          toast.current.show({ severity: "success", summary: "Transaction created", life: 5000 })
        } else {
          toast.current.show({
            severity: "success",
            summary: "Transaction Created",
            //detail: `An invitation to be the Wire Sender was sent to ${wireSenderEmail}`,
            sticky: true,
          })
        }
      })
      .catch((error) => toast.current.show({ severity: "error", summary: "Can't create the transaction", detail: error.response.data, sticky: true }))
      .finally(() => setIsLoading(false))
  }

  const onError = (errors) => {
    const firstErrorName = Object.keys(errors)[0]
    setFocus(firstErrorName)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit, onError)} onError={(e) => console.error(e)} className="p-fluid formgrid grid row-gap-3" style={{ paddingTop: 25 }}>
        <>
          <div className="col-12 p-fluid formgrid grid form-content-block">
            <div className="field col-12 md:col-6">
              <label htmlFor="transactionName">Transaction Name</label>
              <Controller
                name="transactionName"
                control={control}
                rules={{
                  required: "Required field",
                  pattern: {
                    value: /^[A-Za-z0-9, -]+$/,
                    message: "Only alphanumeric characters are allowed",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <InputText
                      id={field.name}
                      ref={field.ref}
                      className={fieldState.error ? "input-error" : "input-correct"}
                      type="text"
                      placeholder="Type Transaction name here"
                      value={field.value}
                      onChange={(e) => {
                        const inputValue = e.target.value.trimStart()
                        if (/^[A-Za-z0-9, -]+$/.test(inputValue)) {
                          setErTrname("")
                          // If input contains only characters or spaces, update the field value
                        } else {
                          setErTrname("Only alphanumeric characters are allowed")
                        }
                        field.onChange(inputValue)
                      }}
                    />
                    <br />
                    {fieldState.error && <span className="p-error ">{fieldState.error.message}</span>}
                    {erTrname && !fieldState.error && <span className="p-error ">{erTrname}</span>}
                  </div>
                )}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="issuer">Issuer Name</label>
              <Controller
                name="issuer"
                control={control}
                rules={{
                  required: "Required field",
                  pattern: {
                    value: /^[A-Za-z\s]+$/,
                    message: "Only alphabetic characters are allowed",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <InputText
                      id={field.name}
                      ref={field.ref}
                      className={fieldState.error ? "input-error" : "input-correct"}
                      type="text"
                      placeholder="Type issuer name here"
                      value={field.value}
                      onChange={(e) => {
                        const inputValue = e.target.value.trimStart()
                        if (/^[A-Za-z\s]*$/.test(inputValue)) {
                          setErIssuer("")
                          // If input contains only characters or spaces, update the field value
                        } else {
                          setErIssuer("Only alphabetic characters are allowed.")
                        }
                        field.onChange(inputValue)
                      }}
                    />
                    <br />
                    {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                    {erIssuer && !fieldState.error && <span className="p-error ">{erIssuer}</span>}
                  </div>
                )}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="parAmount">PAR Amount</label>
              <Controller
                name="parAmount"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                  min: {
                    value: 0,
                    message: "Value must be greater than or equal to 0",
                  },
                  max: {
                    value: 10000000000,
                    message: "Value must be less than or equal to 10,000,000,000",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <InputNumber
                      id={field.name}
                      inputRef={field.ref}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value)
                        const underwriterDiscount = parseFloat(getValues("underwriterDiscount"))

                        if (underwriterDiscount >= parseFloat(e.value)) {
                          setDiscountError("Underwriter's Discount cannot exceed PAR Amount")
                        } else {
                          setDiscountError("")
                        }
                      }}
                      mode="currency"
                      min="0"
                      max="10000000000"
                      currency="USD"
                      locale="en-US"
                      inputClassName={fieldState.error ? "input-error" : "input-correct"}
                      placeholder="$"
                    />
                    <br />
                    {fieldState.error && <span className="p-error ">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="underwriterDiscount">Underwriter's Discount</label>
              <Controller
                name="underwriterDiscount"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                  min: {
                    value: 0,
                    message: "Value must be greater than 0",
                  },
                  max: {
                    value: 10000000000,
                    message: "Value exceeds 10,000,000,000",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <InputNumber
                      id={field.name}
                      inputRef={field.ref}
                      value={field.value}
                      onChange={(e) => {
                        // Check if the entered value is less than or equal to PAR Amount
                        field.onChange(e.value)
                        const parAmountValue = parseFloat(getValues("parAmount"))
                        if (parseFloat(e.value) >= parAmountValue) {
                          setDiscountError("Underwriter's Discount cannot exceed PAR Amount")
                        } else {
                          setDiscountError("")
                        }
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      min="0"
                      max="10000000000"
                      inputClassName={fieldState.error ? "input-error" : "input-correct"}
                      placeholder="$"
                    />
                    <br />
                    {(fieldState.error || discountError) && <span className="p-error ">{fieldState.error?.message || discountError}</span>}
                  </div>
                )}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="type">Method of Sale</label>
              <Controller
                name="type"
                control={control}
                rules={{ required: "Select a type" }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <Dropdown id={field.name} value={field.value} optionDisabled="disabled" onChange={(e) => field.onChange(e.value)} className={fieldState.error ? "input-error" : "input-correct"} placeholder="Select from options" options={typeOptions} optionLabel="name" optionValue="value" />
                    {/* Hidden input for getting focus on submit when there's an error with the field.
                    The inputRef does not work on Dropdown component with the ref from the field argument from React Hook Form
                    (it works with the InputNumber though) */}
                    <div style={{ position: "absolute", top: 0, width: 0, overflow: "hidden" }}>
                      <input style={{ maxHeight: 0, width: 0, position: "absolute", top: 0, opacity: 0 }} ref={field.ref} />
                    </div>
                    {fieldState.error && <span className="p-error ">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="closingDate">Closing Date</label>
              <Controller
                name="closingDate"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <Calendar id={field.name} value={field.value} minDate={new Date()} placeholder={"DD/MM/YYYY"} onChange={(e) => field.onChange(e.value)} inputClassName={fieldState.error ? "input-error border-right-none" : "input-correct border-right-none"} showIcon showButtonBar />
                    {/* Hidden input for getting focus on submit when there's an error with the field.
                    The inputRef does not work on Dropdown component with the ref from the field argument from React Hook Form
                    (it works with the InputNumber though) */}
                    <div style={{ position: "absolute", top: 0, width: 0, overflow: "hidden" }}>
                      <input style={{ maxHeight: 0, width: 0, position: "absolute", top: 0, opacity: 0 }} ref={field.ref} />
                    </div>
                    {fieldState.error && <span className="p-error ">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>
          </div>
          <div className="field col-12 md:col-12 form-bottom-section">
            <Button disabled={isLoading} label="Cancel" id="register-button" onClick={() => setOpenTransactionForm(false)} className="p-button-secondary p-button-text" />
            <Button disabled={isLoading} isLoading={isLoading} label="Create" id="register-button" className="border-round-sm" />
          </div>
        </>
      </form>
    </div>
  )
}

export default TransactionForm
