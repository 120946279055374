import axios from "providers/ConfigureAxios"
import { getUrl } from "service/config/serviceUrls"

export default class AccountDetailsService {
  editAccountDetails(headers, accountDetailsId, data) {
    return axios({
      method: "put",
      url: `${getUrl()}/AccountDetails/${accountDetailsId}`,
      headers,
      data,
    })
  }

  approveAccountDetails(headers, accountDetailsId, data) {
    return axios({
      method: "put",
      url: `${getUrl()}/AccountDetails/${accountDetailsId}/approve`,
      headers,
      data,
    })
  }
}
