import axios from "providers/ConfigureAxios"
import { getUrl } from "service/config/serviceUrls"

export default class UserService {
  getUsers(headers) {
    return axios({
      method: "get",
      url: `${getUrl()}/user/`,
      headers,
    })
  }

  search(headers, email) {
    return axios({
      method: "get",
      url: `${getUrl()}/user/search/${email}`,
      headers,
    })
  }

  isVerified(userId) {
    return axios({
      method: "get",
      url: `${getUrl()}/user/${userId}/is-verified`,
    })
  }

  editUser(headers, editUser) {
    return axios({
      method: "put",
      url: `${getUrl()}/user`,
      headers,
      data: editUser,
    })
  }

  editProfile(headers, editUserProfile) {
    return axios({
      method: "put",
      url: `${getUrl()}/user/profile`,
      headers,
      data: editUserProfile,
    })
  }

  updateProfilePhoto(headers, file) {
    const formData = new FormData()
    if (file) {
      formData.append("file", file)
    }
    return axios({
      method: "put",
      url: `${getUrl()}/user/profile-photo`,
      headers: {
        ...headers,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
  }
}
