import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import WiresService from "service/WiresService"
import { useUser } from "hooks/useUser"
import { useToast } from "hooks/useToast"
import constants from "constants/constants"
import { trimObjectValues, getCurrencyString } from "utilities/format"
import { wiretDetailssHeaderTemplate, recipientDetailssHeaderTemplate, remarksHeaderTemplate } from "./WireNew"
import buttonPlusImage from "./../../../../assets/Buttonplusbutton.png"
import { Panel } from "primereact/panel"
import { InputTextarea } from "primereact/inputtextarea"
import WireSummary from "./WireSummary.js"

const WireEdit = ({ wireData, wireSenderEmail, onWireEdited, roleInTR }) => {
  const [wirePurposes, setWirePurposes] = useState(wireData.wirePurposes)
  console.log("wiredata,", wireData)
  const { control, handleSubmit, resetField, setFocus, trigger, watch } = useForm({
    defaultValues: {
      // Wire
      role: wireData.role,
      otherPurpose: wireData.otherPurpose ?? "",
      wireReceiverEmail: wireData.wireReceiverEmail,
      // Account details
      receiver: wireData.accountDetails.receiver,
      aba: wireData.accountDetails.aba,
      fbo: wireData.accountDetails.fbo,
      accountNumber: wireData.accountDetails.accountNumber,
      references: wireData.references,
      otherComments: wireData.otherComments,
      fundApplicationInstructions: wireData.accountDetails.fundApplicationInstructions,
      //wirePurposes: wirePurposes
    },
    reValidateMode: "onChange",
  })
  const wireReceiverWireReceived = watch("wireReceiverWireReceived") === "Yes"
  const wireSenderWireSent = watch("wireSenderWireSent") === "Yes"
  const [newWire, setNewWire] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const wiresService = new WiresService()

  const { token, userData } = useUser()
  const toast = useToast()

  const roleOptions = [
    { name: "County", value: "County" },
    { name: "Escrow Agent", value: "Escrow Agent" },
    { name: "Issuer", value: "Issuer" },
    { name: "Trustee", value: "Trustee" },
    { name: "Bond Insurer", value: "Bond Insurer" },
  ]

  const purposeOptions = [
    { name: "Project Fund", value: "Project Fund" },
    { name: "Costs of Issuance", value: "Costs of Issuance" },
    { name: "Insurance", value: "Insurance" },
    { name: "Other", value: "Other" },
  ]

  const wireInvited = wireData.status === constants.WIRE_STATUS.INVITED
  const wireAcceptedAndApproved = wireData.status === constants.WIRE_STATUS.ACCEPTED && Object.values(wireData.accountDetailsApprovals).every((approval) => approval === "Approved")
  const wireAcceptedOrReceived = wireData.status === constants.WIRE_STATUS.ACCEPTED || wireData.status === constants.WIRE_STATUS.RECEIVED
  const wireAcceptedOrReceivedAndApproved = wireAcceptedOrReceived && Object.values(wireData.accountDetailsApprovals).every((approval) => approval === "Approved")
  const wireReceiverWireReceivedDisabled = wireData.wireReferenceNumber === null || wireData.wireReceiverEmail !== userData.email || wireData.status === constants.WIRE_STATUS.RECEIVED
  // Use wireSender email instead of roleInTR because the Transaction Manager can also be the Wire Sender, and in that case the roleInTR is "Wire Sender"
  const wireSenderWireSentDisabled = userData.email !== wireSenderEmail || wireData.wireReferenceNumber !== null
  const wireReferenceNumberDisabled = userData.email !== wireSenderEmail || wireData.wireReferenceNumber !== null || !wireSenderWireSent
  const submitDisabled = wireAcceptedAndApproved && ((!wireReceiverWireReceived && roleInTR === constants.TRANSACTION_ROLES.WIRE_RECEIVER) || (!wireSenderWireSent && userData.email === wireSenderEmail))

  const updateWire = () => {
    setIsLoading(true)
    const header = { Authorization: `bearer ${token}` }
    wiresService
      .editWire(header, wireData.id, newWire)
      .then(() => onWireEdited())
      .catch((error) => toast.current.show({ severity: "error", summary: "Can't edit Wire", detail: error.response.data, sticky: true }))
      .finally(() => setIsLoading(false))
  }
  const onSubmit = (values) => {
    if (values.wireReceiverEmail === userData.email) {
      return toast.current.show({ severity: "error", summary: "Can't assign yourself as the Wire Receiver", life: 8000 })
    }
    let invalidAmount = false
    wirePurposes.map((purp) => {
      if (purp.amount && purp.amount < 0) {
        invalidAmount = true
      }
    })
    if (invalidAmount) {
      return toast.current.show({ severity: "error", summary: "Invalid amount", life: 8000 })
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.wireReceiverEmail)) {
      return toast.current.show({ severity: "error", summary: "Invalid email Address", life: 8000 })
    }

    const wire = trimObjectValues({
      //amount: values.amount,
      role: values.role,
      wireReceiverEmail: values.wireReceiverEmail,
      transactionId: wireData.transactionId,
      //purpose: values.purpose,
      ...(values.purpose === "Other" ? { otherPurpose: values.otherPurpose ?? null } : { otherPurpose: null }),
      receiver: values.receiver || null,
      aba: values.aba || null,
      fbo: values.fbo || null,
      accountNumber: values.accountNumber || null,
      contact: values.contact,
      contactName: values.contactName,
      contactPhone: values.contactPhone,
      references: values.references || null,
      otherComments: values.otherComments || null,
      wirePurposes: wirePurposes.filter((wire) => wire.amount > 0),
      amount: wirePurposes.reduce((x, i) => {
        return x + i.amount
      }, 0),
      fundApplicationInstructions: values.fundApplicationInstructions || null,
    })

    setNewWire(wire)
  }

  const addAnother = () => {
    setWirePurposes([...wirePurposes, { amount: null, purpose: null }])
  }
  const removePurpose = (i) => {
    var wrs = wirePurposes.filter((_, index) => index !== i)
    setWirePurposes(wrs)
  }

  const onError = (errors) => {
    const firstErrorName = Object.keys(errors)[0]
    setFocus(firstErrorName)
  }

  const handleWireAmountChange = (amount, index) => {
    var wrs = wirePurposes
    wrs[index].amount = amount
    setWirePurposes(wrs)
  }

  const handleWirePurposeChange = (purpose, index) => {
    var wrs = wirePurposes
    wrs[index].purpose = purpose
    setWirePurposes(wrs)
  }
  const custm_grid = wirePurposes.length == 1 ? "field col-12 md:col-6" : "field col-12 md:col-5"
  return (
    <div>
      {newWire && <WireSummary newWire={newWire} createWire={updateWire} setIsLoading={setIsLoading} setNewWire={setNewWire} isLoading={isLoading} />}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="p-fluid formgrid grid">
        <Panel headerTemplate={recipientDetailssHeaderTemplate} toggleable className="col-12">
          <div className="col-12 p-fluid formgrid grid form-content-block">
            <div className="field col-12 md:col-6">
              <label htmlFor="wireReceiverEmail">Recipient Email ID</label>
              <Controller
                name="wireReceiverEmail"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Wire Recipient Official Email ID" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                    <br />
                    {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="role">Recipient Role</label>
              <Controller
                name="role"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <Dropdown id={field.name} className={fieldState.error ? "input-error" : "input-correct"} value={field.value} onChange={(e) => field.onChange(e.value)} options={roleOptions} optionLabel="name" optionValue="name" placeholder="Select Recipient Role" />
                    <br />
                    {/* Hidden input for getting focus on submit when there's an error with the field.
  The inputRef does not work on Dropdown component with the ref from the field argument from React Hook Form
  (it works with the InputNumber though) */}
                    <div style={{ position: "absolute", top: 0, width: 0, overflow: "hidden" }}>
                      <input style={{ maxHeight: 0, width: 0, position: "absolute", top: 0, opacity: 0 }} ref={field.ref} tabIndex="-1" />
                    </div>
                    {fieldState.error && <span className="p-error absolute bottom-0">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="receiver">Recipient Bank Name</label>
              <Controller
                name="receiver"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
                render={({ field, fieldState }) => (
                  <>
                    <div className="relative">
                      <InputText id={field.name} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} className={fieldState.error ? "input-error" : "input-correct"} placeholder="Recipient Bank Name" />
                      <br />
                    </div>
                    {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                  </>
                )}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="accountNumber">Recipient Account Number</label>
              <Controller
                name="accountNumber"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                  minLength: {
                    value: 8,
                    message: "Account number should have 8 to 12 digits",
                  },
                  maxLength: {
                    value: 12,
                    message: "Account number should have 8 to 12 digits",
                  },
                }}
                render={({ field, fieldState }) => (
                  <>
                    <div className="relative">
                      <InputText id={field.name} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.slice(0, 12))} className={fieldState.error ? "input-error" : "input-correct"} placeholder="XXXX XXXX XXXX XXXX" keyfilter="num" />
                      <br />
                    </div>
                    {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                  </>
                )}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="aba">ABA Number</label>
              <Controller
                name="aba"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                  minLength: {
                    value: 9,
                    message: "ABA number should have 9 digits",
                  },
                  maxLength: {
                    value: 9,
                    message: "ABA number should have 9 digits",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <InputText id={field.name} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.slice(0, 9))} className={fieldState.error ? "input-error" : "input-correct"} placeholder="Bank ABA Number" keyfilter="num" />
                    <br />
                    {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="fbo">Account Name</label>
              <Controller
                name="fbo"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Account Name of the Recipient" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                    <br />
                    {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>
          </div>
        </Panel>

        <Panel headerTemplate={wiretDetailssHeaderTemplate} className="col-12">
          <div>
            {wirePurposes &&
              wirePurposes.map((wire, i) => (
                <div key={i} className="col-12 p-fluid formgrid grid form-content-block" style={{ width: "100%", alignItems: "center" }}>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="amount">Amount</label>
                    <Controller
                      name={`amount_${i}`}
                      control={control}
                      defaultValue={wire.amount}
                      rules={{
                        required: {
                          value: true,
                          message: "Required field",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div className="relative">
                          <InputNumber
                            inputRef={field.ref}
                            id={field.name}
                            value={wire.amount}
                            onChange={(e) => {
                              field.onChange(e.value)
                              handleWireAmountChange(e.value, i)
                            }}
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            inputClassName={fieldState.error ? "input-error" : "input-correct"}
                            placeholder="$"
                          />
                          <br />
                          {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                        </div>
                      )}
                    />
                  </div>

                  <div className={custm_grid}>
                    <label htmlFor="purpose">Purpose</label>
                    <Controller
                      name={`purpose_${i}`}
                      control={control}
                      defaultValue={wire.purpose}
                      rules={{
                        required: {
                          value: true,
                          message: "Required field",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div className="relative">
                          <InputText
                            inputRef={field.ref}
                            id={field.name}
                            value={wire.purpose}
                            onChange={(e) => {
                              field.onChange(e.target.value)
                              handleWirePurposeChange(e.target.value, i)
                            }}
                            className={fieldState.error ? "input-error" : "input-correct"}
                            placeholder="Enter Purpose"
                          />
                          <br />
                          {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                        </div>
                      )}
                    />
                  </div>
                  {/* <div className="remove-wire-button-container"> */}
                  {wirePurposes.length > 1 && (
                    <Button
                      style={{ borderRadius: 100, width: 24, height: 24, paddingLeft: 8, backgroundColor: "#D92D20", borderWidth: 0, justifyContent: "center" }}
                      className="remove-wire-button"
                      icon="pi pi-minus"
                      type="button"
                      disabled={wirePurposes.length < 2}
                      onClick={() => {
                        removePurpose(i)
                      }}
                    ></Button>
                  )}
                  {/* </div> */}
                </div>
              ))}

            <div className="col-12">
              <Button
                type="button"
                style={{ float: "right", marginTop: 20, width: "auto", backgroundColor: "white", color: "#000F66", borderWidth: 0, fontSize: 14, fontWeight: "600px" }}
                onClick={(e) => {
                  e.stopPropagation()
                  addAnother()
                }}
              >
                <img src={buttonPlusImage} style={{ height: 23, width: 23, marginRight: 2 }}></img>
                Add Another
              </Button>
            </div>
          </div>
        </Panel>

        {/* <div className="field col-12 md:col-6">

</div> */}
        {/* 
<div className="field col-12 md:col-6" style={{ cursor: purpose === "Other" ? "auto" : "not-allowed" }}>
   <label htmlFor="otherPurpose" className={purpose === "Other" ? "text-color" : "text-400"}>
    Other Purpose
  </label>
  <Controller
    name="otherPurpose"
    control={control}
    rules={{
      required: {
        value: purpose === "Other",
        message: "Required field",
      },
    }}
    render={({ field, fieldState }) => (
      <div className="relative">
        <InputText disabled={purpose !== "Other"} id={field.name} ref={field.ref} className={fieldState.error ? "input-error" : "input-correct"} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
        <br />
        {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
      </div>
    )}
  /> 
</div> */}

        <Panel headerTemplate={remarksHeaderTemplate} toggleable className="col-12" collapsed={false}>
          <div className="col-12 p-fluid formgrid grid form-content-block">
            {/* <div className="field col-12">
      <label htmlFor="references">References (optional)</label>
      <Controller
        name="references"
        control={control}
        render={({ field, fieldState }) => (
          <>
            <InputTextarea id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
            <br />
            {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
          </>
        )}
      />
    </div> */}

            <div className="field col-12">
              <label htmlFor="fundApplicationInstructions">Instructions regarding application of funds received (optional)</label>
              <Controller
                name="fundApplicationInstructions"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <InputTextarea id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" rows={5} style={{ resize: "none" }} value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                    <br />
                    {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                  </>
                )}
              />
            </div>

            {/* <div className="field col-12">
      <label htmlFor="otherComments">Other Comments (optional)</label>
      <Controller
        name="otherComments"
        control={control}
        render={({ field, fieldState }) => (
          <>
            <InputTextarea id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
            <br />
            {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
          </>
        )}
      />
    </div> */}
          </div>
        </Panel>

        <div className="field col-12 md:col-12 form-bottom-section">
          {/* <a style={{color: "#000F66", fontWeight:"bold"}}>Save as Draft</a> */}
          <span style={{ paddingLeft: 2, fontWeight: "bold", float: "left", marginTop: 13, width: "70%" }}>
            Total:{" "}
            <span style={{ color: "#2563EB", fontSize: "16px" }}>
              {getCurrencyString(
                wirePurposes.reduce((accumulator, currWire) => {
                  return accumulator + currWire.amount
                }, 0)
              )}
            </span>
          </span>
          <Button loading={isLoading} label="Next: Summary" id="register-button" style={{ width: "153px", borderRadius: 4 }} />
        </div>
      </form>
    </div>
  )
}

export default WireEdit
