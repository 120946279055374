import { useState, useEffect } from "react"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { Tag } from "primereact/tag"
import UserService from "service/UserService"
import { useUser } from "hooks/useUser"
import constants from "constants/constants"
import { formatPhoneNumber } from "utilities/format"
import pencilIcon from "components/Icons/Pencil"
import UserEdit from "components/Users/UserEdit"

export default function UsersTable() {
  const [users, setUsers] = useState([])
  const [filters, setFilters] = useState(null)
  const [loading, setLoading] = useState(true)
  const [displayEditForm, setDisplayEditForm] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  const userService = new UserService()

  const { token } = useUser()

  const loadUsers = () => {
    setLoading(true)
    const header = { Authorization: `bearer ${token}` }
    userService
      .getUsers(header)
      .then((data) => setUsers(data.data))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const header = { Authorization: `bearer ${token}` }
    userService.getUsers(header).then((data) => {
      setUsers(data.data)
      setLoading(false)
    })

    initFilters()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onUserEdited = () => {
    setDisplayEditForm(false)
    loadUsers()
  }

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      role: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    })
  }

  const statusBodyTemplate = (rowData) => {
    const status = rowData.status === constants.USER_STATUS.ACTIVE ? "success" : rowData.status === constants.USER_STATUS.INACTIVE ? "info" : "danger"
    const icon = "pi pi-fw " + (rowData.status === constants.USER_STATUS.ACTIVE ? "pi-check-circle" : rowData.status === constants.USER_STATUS.INACTIVE ? "pi-exclamation-circle" : "pi-times-circle")
    return <Tag severity={status} icon={icon} value={rowData.status} />
  }

  const actionsBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          icon={<i className="flex mr-1">{pencilIcon}</i>}
          label="Edit"
          title="Edit user information"
          tooltip="Edit user information"
          tooltipOptions={{ position: "left" }}
          className="p-button-action p-button-raised"
          onClick={() => {
            setDisplayEditForm(true)
            setSelectedUser(rowData)
          }}
        />
      </div>
    )
  }

  const verifiedBodyTemplate = (rowData) => {
    const label = rowData.isVerified ? "VERIFIED" : "NOT VERIFIED"
    const icon = "pi pi-fw " + (rowData.isVerified ? "pi-check" : "pi-times")
    if (rowData.isVerified) {
      return <Tag severity="success" icon={icon} value={label} />
    } else {
      return <Tag className="w-max" severity="danger" icon={icon} value={label} />
    }
  }

  const phoneBodyTemplate = ({ phone }) => formatPhoneNumber(phone)

  return (
    <>
      <Dialog visible={displayEditForm} style={{ width: "60vw" }} header="Edit User" onHide={() => setDisplayEditForm(false)}>
        <UserEdit userData={selectedUser} onUserEdited={onUserEdited} />
      </Dialog>

      <div className="grid table-demo">
        <div className="col-12">
          <DataTable value={users} paginator className="p-datatable-gridlines" showGridlines rows={10} dataKey="id" filters={filters} filterDisplay="menu" loading={loading} responsiveLayout="scroll" emptyMessage="No users found.">
            <Column field="name" header="Name" filter filterPlaceholder="Search by name" style={{ minWidth: "12rem" }} />
            <Column field="email" header="Email" filter filterPlaceholder="Search by email" style={{ minWidth: "12rem" }} />
            <Column field="phone" header="Phone" body={phoneBodyTemplate} style={{ minWidth: "12rem" }} />
            <Column field="organization" body={(rowData) => rowData.organization ?? "-"} header="Organization / Company" style={{ minWidth: "12rem" }} />
            <Column field="title" header="Title" />
            <Column field="role" header="Role" filter filterPlaceholder="Search by role" style={{ minWidth: "12rem" }} />
            <Column field="status" body={statusBodyTemplate} header="Status" />
            <Column field="isVerified" header="Verified" body={verifiedBodyTemplate} />
            {/*<Column field="joinedOn" header="Joined On" style={{ minWidth: '12rem' }} /> 
                          <Column field="invitedBy" header="Invited By" filter filterPlaceholder="Search by invited by" style={{ minWidth: '12rem' }} />
                          <Column field="role" header="Role" filter filterPlaceholder="Search by role" style={{ minWidth: '12rem' }} />
                        
                          <Column field="status" header="Status" body={statusBodyTemplate} style={{ minWidth: '12rem' }} />*/}
            <Column header="Actions" body={actionsBodyTemplate} />
          </DataTable>
        </div>
      </div>
    </>
  )
}
