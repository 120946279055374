import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import FindUser from "components/Transactions/TransactionView/FindUser"
import ParticipantService from "service/ParticipantsService"
import { useUser } from "hooks/useUser"
import { useToast } from "hooks/useToast"
import { emailRegex } from "utilities/format"
import constants from "constants/constants"
import { InputText } from "primereact/inputtext"
import { Dialog } from "@headlessui/react"

const ParticipantNew = ({ transactionId, onParticipantAdded, role, trManagerEmail, internalOnly }) => {
  //TransactionManager and WireSenders are considered participants, same as the ones in the "Other Participants" section of the transaction.
  //So we use this form to add a TransactionMAnager / WireSender, and "Other participants" as well
  //if a role is provided ('Transaction Manager' or 'Wire Sender'), we pre-set that role and we do not show the Role selection dropdown
  //otherwise, if no role is provided, then its because the user is adding a new "Other participant" and we need to allow a role selection so we show it.
  const { control, handleSubmit, trigger } = useForm({
    defaultValues: {
      selectedRole: null,
      participantEmail: "",
    },
    reValidateMode: "onChange",
  })

  const [isLoading, setIsLoading, setOpenNewWireForm, openNewParticipantForm] = useState(false)

  const { token, userData } = useUser()
  const toast = useToast()

  const participantService = new ParticipantService()

  const roleOptions = Object.values(constants.TRANSACTION_ROLES_OTHER_PARTICIPANTS).map((role) => ({ name: role }))

  const onSubmit = (values) => {
    const { participantEmail, selectedRole } = values

    if (trManagerEmail === values.participantEmail && (role === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER || role === constants.TRANSACTION_ROLES.WIRE_RECEIVER)) {
      return toast.current.show({ severity: "error", summary: `Can't assign the Transaction Manager as the ${role ?? selectedRole}`, sticky: true })
    }

    setIsLoading(true)

    const header = { Authorization: `bearer ${token}` }
    const newParticipant = {
      role: role ?? selectedRole,
      participantEmail,
      transactionId,
    }

    participantService
      .addParticipant(header, newParticipant, internalOnly)
      .then(() => {
        onParticipantAdded()
        if (userData.email === newParticipant.participantEmail) {
          // Assigning myself
          toast.current.show({ severity: "success", summary: "Participant added", life: 5000 })
        } else {
          // Assigning other user
          toast.current.show({ severity: "success", summary: "Participant invited", detail: `An invitation was sent to ${newParticipant.participantEmail}`, sticky: true })
        }
      })
      .catch((error) => toast.current.show({ severity: "error", summary: "Can't add participant", detail: error.response.data, sticky: true }))
      .finally(() => setIsLoading(false))
  }

  const ShowRoleField = () => {
    return (
      <div className="field col-12 md:col-6">
        <label htmlFor="role">Role</label>
        <Controller
          name="selectedRole"
          control={control}
          rules={{
            required: {
              value: true,
              message: "Select a Role",
            },
          }}
          render={({ field, fieldState }) => (
            <div className="relative">
              <Dropdown id={field.name} className={fieldState.error ? "input-error" : "input-correct"} value={field.value} options={roleOptions} optionLabel="name" optionValue="name" placeholder="Select One" onChange={(e) => field.onChange(e.value)} />
              {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
            </div>
          )}
        />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid formgrid grid" style={{ paddingTop: 25 }}>
      <div className="col-12 p-fluid formgrid grid form-content-block">
        <div className="field col-12">
          <label htmlFor="wireReceiverEmail">Add either internal or external participants to the transaction</label>
        </div>

        <div className="field col-12">
          <label htmlFor="wireReceiverEmail">Participant Email ID</label>
          <Controller
            name="participantEmail"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required field",
              },
            }}
            render={({ field, fieldState }) => (
              <div className="relative">
                <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Email" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                <br />
                {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
              </div>
            )}
          />
        </div>
        {/* {!role && ShowRoleField()} */}
      </div>

      <div className="field col-12 md:col-12 form-bottom-section d-flex mb-6" style={{ paddingLeft: 40, height: "auto" }}>
        <Button label="Cancel" type="button" style={{ background: "transparent", color: "#000F66", padding: "10px 20px", height: "40px" }} badgeClassName="test" onClick={() => onParticipantAdded(false)} />
        <Button loading={isLoading} label="Submit" style={{ padding: "10px 20px", height: "40px" }} />
      </div>
    </form>
  )
}

export default ParticipantNew
