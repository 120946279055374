import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Button } from "primereact/button"
import { ProgressSpinner } from "primereact/progressspinner"
import { useUser } from "hooks/useUser"
import InvoiceService from "service/InvoiceService"
import { getCurrencyString } from "utilities/format"
import { getSiteUrl } from "service/config/serviceUrls"
import munisafeLogo from "assets/munisafe_logo_white_bg.png"


const InvoicesView = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [invoice, setInvoice] = useState(null)

  const { invoiceId } = useParams()

  const { token } = useUser()

  const invoiceService = new InvoiceService()

  useEffect(() => {
    loadInvoice()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const print = useCallback(() => window.print(), [])

  const loadInvoice = () => {
    const header = { Authorization: "bearer " + token }
		setIsLoading(true)
		invoiceService.getById(header, invoiceId)
		.then(({ data }) => {
			setInvoice(data)
			setIsLoading(false)
		})
  }

  if (isLoading) {
    return <ProgressSpinner />
  }

  return (
    <div>
      <Button type="button" label="Print" icon="pi pi-fw pi-print" onClick={print} style={{ display: "block", marginBottom: "20px" }} />

      <div className="grid">
        <div className="col">
          <div className="card">
            <div id="invoice-content">
              <div className="invoice">
                <div className="invoice-header">
                  <div className="invoice-company">
                    <img id="invoice-logo" className="logo-image" src={munisafeLogo} alt="diamond-layout" />
                    <div className="company-name">MuniSafe</div>
                    <div><a href={`${getSiteUrl()}`}>{`${getSiteUrl()}`}</a></div>
                  </div>
                  <div>
                    <div className="invoice-title">INVOICE</div>
                    <div className="invoice-details">
                      <div className="invoice-label">DATE</div>
                      <div className="invoice-value">{new Date(invoice.createdOn).toLocaleDateString()}</div>

                      <div className="invoice-label">INVOICE #</div>
                      <div className="invoice-value">{invoice.id}</div>
                    </div>
                  </div>
                </div>
                <div className="invoice-to">
                  <div className="bill-to">BILL TO</div>
                  <div className="invoice-to-info">
                    <div>{invoice.invoiceToName}</div>
                    <div>{invoice.invoiceToEmail}</div>
                  </div>
                </div>
                <div className="invoice-items">
                  <table>
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Unit Price</th>
                        <th>Line Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>MuniSafe Fee</td>
                        <td>{invoice.quantity}</td>
                        <td>{getCurrencyString(invoice.unitPrice)}</td>
                        <td>{getCurrencyString(invoice.lineTotal)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="invoice-summary">
                  <div className="invoice-notes">
                    <b>NOTES</b>
                    <div></div>
                  </div>
                  <div>
                    <div className="invoice-details">
                      <div className="invoice-label">TOTAL</div>
                      <div className="invoice-value">{getCurrencyString(invoice.total)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoicesView
