import constants from "constants/constants"
import { useUser } from "hooks/useUser"
import DashboardCharts from "components/Dashboard/DashboardCharts"
import DashboardForm from "components/Dashboard/DashboardForm"

const DashboardIndex = () => {
  const { role, userData } = useUser()

  return (
    <>
      {role === constants.ROLES.SUPER_ADMIN && <DashboardCharts />}
      {/* (role === constants.ROLES.ORGANIZATION_ADMIN) */}
      {/* The Organization Admins get redirected to the Transactions page */}
      {/* (role === constants.ROLES.USER && userData.organization) */}
      {/* The Users with Organization get redirected to the Transactions page */}
      {role === constants.ROLES.USER && !userData.organization && <DashboardForm />}
    </>
  )
}

export default DashboardIndex
