import { useState, useMemo, useRef, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { ConfirmDialog } from "primereact/confirmdialog"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import HistoryChangesTable from "components/HistoryChanges/HistoryChangesTable"
import WireNew from "components/Transactions/TransactionView/WireActions/WireNew"
import WireEdit from "components/Transactions/TransactionView/WireActions/WireEdit"
import HistoryChangesService from "service/HistoryChangesService"
import { useToast } from "hooks/useToast"
import constants from "constants/constants"
import { formatDateString, getCurrencyString } from "utilities/format"
import InvitationsService from "service/InvitationsService"
import CustomTag from "utilities/CustomTag"
import TransactionService from "service/TransactionService"
import WireOpen from "./WireActions/WireOpen"
import ApprovedWireView from "./WireActions/ApprovedWireView"
import { useWindowSize } from "react-use"
import { useUser } from "hooks/useUser"
import { ProgressSpinner } from "primereact/progressspinner"
import WiresService from "service/WiresService"

const TransactionWiresGrid = ({ loading, setLoadingWires, transactionId, transaction, wires, loadWires, roleInTR, wireSenderEmail, loadTransactionTables, showNewWire, reloadTr, loadParticipants, parAmount }) => {
  const [openNewWireForm, setOpenNewWireForm] = useState(false)
  const { token, userData, role } = useUser()

  const [openEditWireForm, setOpenEditWireForm] = useState(false)
  const [openAccountDetailsForm, setOpenAccountDetailsForm] = useState(false)
  const [displayConfirmation, setDisplayConfirmation] = useState(false)
  const [selectedWireId, setSelectedWireId] = useState(null)
  const [selectedWireData, setSelectedWireData] = useState(null)
  //State for canceling a Wire
  const [cancelingWire, setCancelingWire] = useState(false)
  // State for History Changes of a specific Wire
  const [openWireChangesHistory, setOpenWireChangesHistory] = useState(false)
  const [selectedWireHistoryChanges, setSelectedWireHistoryChanges] = useState(null)
  const [roleCategoryInTR, setRoleCategoryInTR] = useState(null)
  const [openWireSummary, setOpenWireSummary] = useState(false)
  const [loadingInvites, setLoadingInvites] = useState(false)
  const [showSendInvitesPopUp, setShowSendInvitesPopUp] = useState(false)
  const [expandedRows, setExpandedRows] = useState(null)
  const [wireHistory, setWireHistory] = useState([])
  const [loadingData, setLoadingData] = useState(false)
  const wireService = new WiresService()
  const [totalSourceofFund, setTotalSourceofFund] = useState(0)
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      cancelationReason: "",
    },
  })

  const isRowExpandable = (data, rowIndex) => {
    //Last row is not expandable
    if (rowIndex !== wires.length) {
      return data.wirePurposes && data.wirePurposes.length > 1
    }
    return false
  }

  const expanderTemplate = (data, extra) => {
    if (!isRowExpandable(data, extra.rowIndex)) {
      // Return none if the row should not be expandable
      return
    }
    return (
      <button style={{ backgroundColor: "transparent", border: "none" }} onClick={(e) => onRowExpand(e, data)}>
        <i
          style={{ color: "#667085", fontWeight: "400" }}
          // className="pi pi-angle-right"
          className={extra.expander.iconClassName}
        />
      </button>
    )
  }

  const onRowExpand = (event, rowData) => {
    let _expandedRows
    if (expandedRows) {
      _expandedRows = { ...expandedRows }
      if (_expandedRows[rowData.id]) {
        delete _expandedRows[rowData.id]
      } else {
        _expandedRows[rowData.id] = true
      }
    } else {
      _expandedRows = { [rowData.id]: true }
    }
    setExpandedRows(_expandedRows)
  }

  const rowExpansionTemplate = (data, extra) => {
    return <RowExpansionTemplate data={data} extra={extra} />
  }

  const amountBodyTemplate = (rowData) => {
    return getCurrencyString(rowData.amount)
  }

  const recipientEmailbodyCantWires = (rowData, extra) => {
    return (
      <div style={{ alignItems: "center" }}>
        <l className="inline-block " style={{ color: "#667085", fontSize: 16, fontWeight: "400px" }}>
          {rowData.wireReceiverName}
        </l>
        {isRowExpandable(rowData, extra.rowIndex) && <p className="p-text-bold p-text-center">{rowData.wirePurposes.length} Funds</p>}
      </div>
    )
  }

  const purposeBodyTemplate = (rowData, extra) => {
    if (isRowExpandable(rowData, extra.rowIndex)) {
      return (
        <div className="p-d-flex p-ai-center">
          <p className="p-text-bold p-text-center tag-multiple">Multiple</p>
        </div>
      )
    } else if (rowData.otherPurpose !== null) {
      return (
        <div className="p-d-flex p-ai-center">
          {/* <p className="p-text-bold p-text-center">Costs of Issuance</p> */}
          <p className="p-text-bold p-text-center">{rowData.otherPurpose}</p>
        </div>
      )
    } else if (rowData.wirePurposes.length === 1) {
      return (
        <div className="p-d-flex p-ai-center">
          <p className="p-text-bold p-text-center">{rowData.wirePurposes[0].purpose}</p>
        </div>
      )
    } else {
      return (
        <div className="p-d-flex p-ai-center">
          <p className="p-text-bold p-text-center">-</p>
        </div>
      )
    }
  }
  const indexBodyTemplate = (rowData, props) => {
    return (props.rowIndex + 1).toString().padStart(2, "0")
  }
  const historyChangesService = useMemo(() => new HistoryChangesService(), [])
  const invitationsService = useMemo(() => new InvitationsService(), [])
  const transactionsService = useMemo(() => new TransactionService(), [])

  const toast = useToast()

  const headers = useMemo(() => ({ Authorization: `bearer ${token}` }), [token])

  // const header = (
  //   <div className="table-header-container">
  //     {showNewWire && roleInTR === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER && transactionStatus === constants.TRANSACTION_STATUS.OPEN && <h3 style={{ float: "left", marginTop: "10px" }}>Wires to be sent</h3>}
  //     {showNewWire && roleInTR === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER && transactionStatus === constants.TRANSACTION_STATUS.OPEN && (
  //       <Button label="New Wire" title="Add new wire" tooltip="Add a new wire" tooltipOptions={{ position: "left" }} onClick={() => setOpenNewWireForm(true)} className="mr-2 mb-2 p-button-action p-button-raised" />
  //     )}
  //     {(roleInTR !== constants.TRANSACTION_ROLES.TRANSACTION_MANAGER || transactionStatus === constants.TRANSACTION_STATUS.CLOSED || !showNewWire) && <h3 style={{ textAlign: "left", marginTop: "10px" }}>Wires to be sent</h3>}
  //   </div>
  // )

  const organizationBodyTemplate = (rowData, extra) => {
    if (extra.rowIndex === wires.length) {
      return
    }
    return rowData.organization ?? "-"
  }

  const actionsBodyTemplate = (rowData, extra) => {
    const { id, status, wireReceiverEmail, roleCategory } = rowData
    if (extra.rowIndex === wires.length) {
      return
    }
    return (
      <div className="tb-actions ">
        <Button
          label={rowData.wireReceiverEmail === userData.email && rowData.status == "Invited" ? "Action" : "View"}
          className={rowData.wireReceiverEmail === userData.email && rowData.status == "Invited" ? "action-button border-round-sm" : "link-button"}
          // className="link-button"
          onClick={() => {
            setSelectedWireId(id)
            setSelectedWireData(rowData)
            loadWireHistory(rowData, transactionId)
          }}
        />
        {false && (
          <Button
            label="Delete"
            className="link-button"
            // style={{ color: "#667085" }}
            onClick={() => {
              setSelectedWireId(id)
              setSelectedWireData(wires.find((wire) => wire.id === id))
              setDisplayConfirmation(true)
            }}
          />
        )}
      </div>
    )
  }

  const statusBodyTemplate = (rowData, extra) => {
    if (extra.rowIndex === wires.length) {
      return
    }
    return CustomTag(rowData)
  }

  const approvalClassNames = (approval) =>
    classNames("border-circle border-1 w-2rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm", {
      "bg-red-100 text-red-900": approval === "Disapproved",
      "bg-white-100 text-blue-900": !approval,
      "bg-teal-100 text-teal-900": approval === "Approved",
    })

  const approvalsBodyTemplate = ({ accountDetailsApprovals }) => (
    <div className="flex justify-content-around">
      <Tooltip target=".trManagerApprovalInfoLabel" position="top" />
      <div className={`${approvalClassNames(accountDetailsApprovals?.trManagerApproval)} trManagerApprovalInfoLabel`} data-pr-tooltip="Transaction Manager">
        TM
      </div>
      <Tooltip target=".wireSenderApprovalInfoLabel" position="top" />
      <div className={`${approvalClassNames(accountDetailsApprovals?.wireSenderApproval)} wireSenderApprovalInfoLabel`} data-pr-tooltip="Wire Sender">
        WS
      </div>
      <Tooltip target=".wireReceiverApprovalInfoLabel" position="top" />
      <div className={`${approvalClassNames(accountDetailsApprovals?.wireReceiverApproval)} wireReceiverApprovalInfoLabel`} data-pr-tooltip="Wire Receiver">
        WR
      </div>
    </div>
  )

  const approvalsHeader = () => (
    <div>
      <Tooltip target=".approvalsInfoLabel" position="left" hideDelay={100} style={{ maxWidth: "80ch" }} />
      <span>
        Approvals <i className="pi pi-fw pi-info-circle approvalsInfoLabel" data-pr-tooltip="The approvals of the Account Details of each Wire: TM (Transaction Manager), WS (Wire Sender) and WR (Wire Receiver). They can be either approved (green), disapproved (red) or unrevised (white)." />
      </span>
    </div>
  )

  const onWireCreated = () => {
    setOpenNewWireForm(false)
    loadTransactionTables()
  }

  const onWireEdited = () => {
    setOpenEditWireForm(false)
    loadTransactionTables()
  }

  const onAccountDetailsUpdated = () => {
    setOpenAccountDetailsForm(false)
    loadWires()
  }

  const handleCancelWireYes = handleSubmit(({ cancelationReason }) => {
    setCancelingWire(true)
    const headers = {
      Authorization: `bearer ${token}`,
    }

    invitationsService
      .cancelWireInvite(headers, selectedWireId, cancelationReason === "" ? null : cancelationReason)
      .then(() => {
        setDisplayConfirmation(false)
        loadWires()
        reset({
          cancelationReason: "",
        })
        toast.current.show({ severity: "success", summary: "Wire canceled", life: 5000 })
      })
      .finally(() => {
        setCancelingWire(false)
      })
  })

  const ConfirmSendInvitations = () => {
    const sendWireInvitesForThisTr = () => {
      const headers = {
        Authorization: `bearer ${token}`,
      }
      transactionsService
        .sendParticipantsInvitations(headers, transactionId)
        .then(() => {
          loadWires()
          reloadTr(true)
          toast.current.show({ severity: "success", summary: "Wire invites sent.", life: 5000 })
        })
        .finally(() => {
          setLoadingInvites(false)
        })
    }

    return (
      <div className="confirmation-dialog-wire-send">
        <div className="dialog-send-wire-pop-up">
          <div x>
            <div className="dialog-delete-wire-first-text text-center">
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg> */}
            </div>

            {totalSourceofFund >= parAmount ? (
              <div className="dialog-delete-wire-second-text d-flex align-items-center" style={{ display: "flex" }}>
                <span class="p-confirm-dialog-icon pi pi-exclamation-triangle" style={{ fontSize: "2rem" }}></span>
                <span className="ml-3">All participants will be notified. Please carefully verify all details before proceeding.</span>
              </div>
            ) : (
              <div className="dialog-delete-wire-second-text d-flex align-items-center" style={{ display: "flex" }}>
                <span class="p-confirm-dialog-icon pi pi-exclamation-triangle" style={{ fontSize: "2rem" }}></span>
                <span className="ml-3">Send wire invites when the total funds reach or exceed the PAR amount.</span>
              </div>
            )}
          </div>
        </div>
        <div class={totalSourceofFund >= parAmount ? "p-dialog-footer" : "p-dialog-footer p-dialog-footer-wireinvite-invalid"} style={{ padding: "0.5rem" }}>
          <div className="dialog-delete-wire-buttons">
            <Button
              className={totalSourceofFund >= parAmount ? "transaction-wire-cancel-invites flex-same-row" : "transaction-wire-send-invites flex-same-row wire-send-invalid"}
              label="Close"
              onClick={() => {
                setShowSendInvitesPopUp(false)
                setLoadingInvites(false)
              }}
            />
            {totalSourceofFund >= parAmount ? (
              <Button
                disabled={loadingInvites}
                label="Confirm"
                onClick={() => {
                  setLoadingInvites(true)
                  sendWireInvitesForThisTr()
                }}
                className="transaction-wire-send-invites flex-same-row"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    )
  }

  const handleCancelWireNo = () => {
    setDisplayConfirmation(false)
    reset({
      cancelationReason: "",
    })
    setSelectedWireId(null)
    setCancelingWire(false)
  }

  const confirmationDialogBody = (wireData) => (
    <div className="grid mx-4 px-4">
      <p className="col-12 pl-0 mb-0 font-bold">Are you sure you want to cancel this wire?</p>
      <ul className="col-12 list-none pl-1 my-0 flex flex-column gap-1">
        <li>
          <span className="font-bold">Id: </span>
          {wireData.id}
        </li>
        <li>
          <span className="font-bold">Amount: </span>
          {getCurrencyString(wireData.amount)}
        </li>
        <li>
          <span className="font-bold">Status: </span>
          {wireData.status}
        </li>
        <li>
          <span className="font-bold">Wire Receiver: </span>
          {wireData.wireReceiverEmail}
        </li>
        <li>
          <span className="font-bold">Purpose: </span>
          {wireData.purpose}
        </li>
        {wireData.purpose === "Other" && (
          <li>
            <span className="font-bold">Other purpose: </span>
            {wireData.otherPurpose}
          </li>
        )}
        <li>
          <span className="font-bold">Created on: </span>
          {formatDateString(wireData.createdOn)}
        </li>
      </ul>
      <div className="col-12 pl-0">
        <form className="p-fluid formgrid grid">
          <div className="field col-12">
            <label htmlFor="cancelationReason">Cancelation reason (optional)</label>
            <Controller
              name="cancelationReason"
              control={control}
              render={({ field, fieldState }) => (
                <div className="relative">
                  <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Cancelation reason" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                  <br />
                  {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                </div>
              )}
            />
          </div>
        </form>
      </div>
    </div>
  )
  const headerSummary = (
    <>
      <l style={{ color: "#667085" }}>Wire </l>
      <i class="p-row-toggler-icon pi pi-chevron-right" style={{ color: "#667085" }}></i>
      <span style={{ color: "#101828" }}> Summary</span>
    </>
  )

  const loadWireHistory = (wireData, transactionId) => {
    setLoadingData(true)
    wireService.getWireHistory(headers, wireData.id, transactionId).then((response) => {
      setWireHistory([{ ...wireData, ...wireData.accountDetails, updatedFields: [] }, ...response.data])
      setLoadingData(false)
      setOpenWireSummary(true)
    })
  }

  return (
    <div className="col-12 pt-0">
      <Dialog visible={openNewWireForm} style={{ width: "70vw" }} header="New Wire" onHide={() => setOpenNewWireForm(false)}>
        <WireNew transactionId={transactionId} onWireCreated={() => onWireCreated()} />
      </Dialog>

      <Dialog visible={openEditWireForm} style={{ width: "45vw" }} header="Edit Wire" onHide={() => setOpenEditWireForm(false)}>
        <WireEdit wireData={selectedWireData} wireSenderEmail={wireSenderEmail} onWireEdited={() => onWireEdited()} roleInTR={roleInTR} />
      </Dialog>

      <Dialog visible={openWireSummary} style={{ maxWidth: "500px" }} header={headerSummary} onHide={() => setOpenWireSummary(false)}>
        {selectedWireData && selectedWireData.status === "Approved" ? (
          <ApprovedWireView wireHistory={wireHistory} wire={selectedWireData} transactionId={transactionId} setVisible={setOpenWireSummary} setIsLoading={setLoadingWires} />
        ) : (
          <WireOpen
            wireHistory={wireHistory}
            wire={selectedWireData}
            transactionId={transactionId}
            setVisible={setOpenWireSummary}
            setIsLoading={setLoadingWires}
            loadWires={loadWires}
            setEditWireOpenForm={setOpenEditWireForm}
            setSelectedWireData={setSelectedWireData}
            loadParticipants={loadParticipants}
          />
        )}
      </Dialog>

      <Dialog visible={openWireChangesHistory} style={{ width: "70vw" }} header="Wire changes" onHide={() => setOpenWireChangesHistory(false)}>
        <HistoryChangesTable loading={selectedWireHistoryChanges === null} historyChangesData={selectedWireHistoryChanges ?? []} />
      </Dialog>

      <Dialog className="dialoge-send-invites" visible={showSendInvitesPopUp} style={{ width: "400px" }} header={totalSourceofFund >= parAmount ? "Irreversiable Action" : "Add More Wires"} draggable={false} onHide={() => setShowSendInvitesPopUp(false)}>
        <ConfirmSendInvitations />
      </Dialog>
      {selectedWireData && (
        <ConfirmDialog
          className=""
          style={{ width: "60vw" }}
          header="Cancel wire"
          message={confirmationDialogBody(selectedWireData)}
          icon="pi pi-exclamation-triangle"
          accept={handleCancelWireYes}
          acceptLabel="Cancel wire"
          reject={handleCancelWireNo}
          rejectLabel="Go back"
          onHide={handleCancelWireNo}
          visible={displayConfirmation}
        />
      )}

      {wires.length > 0 && (
        <DataTable
          loading={loading}
          showGridlines
          rows={10}
          dataKey="id"
          emptyMessage="No wires found."
          value={[
            ...wires,
            // , { amount: wires.reduce((acc, wire) => acc + wire.amount, 0) }
          ]}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          className="transaction-table"
        >
          <Column expander style={{ padding: "0", textAlign: "right", width: "1rem" }} body={expanderTemplate} />
          <Column field="wireReceiverEmail" header="RECIPIENT NAME" body={recipientEmailbodyCantWires} className="col-2" style={{ width: "15%" }} />
          {/* <Column field="id" header="WIRE ID" /> */}
          <Column field="organization" header="ORGANIZATION" body={organizationBodyTemplate} style={{ width: "15%" }} />
          <Column field="role" header="ROLE" style={{ width: "10%" }} />
          <Column field="purpose" header="PURPOSE" body={purposeBodyTemplate} className="col-2" style={{ width: "15%" }} />
          <Column
            field="amount"
            header="WIRE AMOUNT"
            body={amountBodyTemplate}
            style={{ backgroundColor: "#F9FAFB", minWidth: "380px", textAlign: "right", paddingRight: "20px" }}
            headerStyle={{ backgroundColor: "#F2F4F7", textAlign: "right", paddingRight: "20px" }}
            footerStyle={{ textAlign: "left" }}
            footer={() => {
              const amount = wires.reduce((acc, wire) => acc + wire.amount, 0)
              const totalSourcesOfFunds = amount + transaction.underwriterDiscount
              setTotalSourceofFund(totalSourcesOfFunds)
              return (
                <div>
                  <div style={{ marginBottom: "5px", padding: "8px 20px", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ fontSize: "14px", fontWeight: "500" }}>Sum of Wires:</div>
                    <div style={{ textAlign: "right", fontSize: "14px", fontWeight: "500" }}>{getCurrencyString(amount)}</div>
                  </div>

                    <div style={{ marginBottom: "5px", padding: "8px 20px", display: "flex", justifyContent: "space-between" }}>
                      <div style={{ fontSize: "14px", fontWeight: "500" }}> Underwriter's Discount: </div>
                      <div style={{ textAlign: "right", fontSize: "14px", fontWeight: "500" }}>{getCurrencyString(transaction.underwriterDiscount)}</div>
                    </div>
                    <div style={{ background: "#EEF3FF", padding: "14px 20px", display: "flex", justifyContent: "space-between" }}>
                      <div style={{ fontSize: "14px", fontWeight: "700" }}> Total Sources of Funds: </div>
                      <div style={{ textAlign: "right", fontSize: "14px", fontWeight: "700" }}> {getCurrencyString(totalSourcesOfFunds)} </div>
                    </div>
                  </div>
                )
              }}
              // footerStyle={{ fontWeight: 800 }}
              // bodyStyle={{ fontWeight: 800 }}
            />
            <Column field="status" header="STATUS" style={{ textAlign: "left", width: "15%", paddingLeft: "4%" }} headerStyle={{ textAlign: "left", paddingLeft: "4.5%" }} body={statusBodyTemplate} />
            <Column body={actionsBodyTemplate} header="ACTION" style={{ width: "15%", paddingRight: "20px" }} />
          </DataTable>)}
          {loadingData && (
            <div>
              <ProgressSpinner />
            </div>
          )}
      
  

      {wires.length > 0 && roleInTR === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER && wires[0].status == "Created" && (
        <div style={{ marginTop: "7rem" }}>
          <Button
            className="border-round-sm"
            loading={loadingInvites}
            disabled={loadingInvites}
            id="sendWireInvitesBtn"
            label="SEND WIRE INVITES"
            icon="pi pi-send"
            onClick={() => {
              setShowSendInvitesPopUp(true)
            }}
          ></Button>
        </div>
      )}
    </div>
  )
}

function RowExpansionTemplate({ data, extra }) {
  const { width } = useWindowSize()
  const ref = useRef()
  const [marginRight, setMarginRight] = useState(0)
  useEffect(() => {
    const tbody = ref.current.closest(".p-datatable-tbody")
    const tr = tbody.querySelector("tr")
    const tds = Array.from(tr.querySelectorAll("td")).slice(-2)
    let lastTdsWidth = 0
    tds.forEach((el) => {
      lastTdsWidth += el.offsetWidth
    })
    setMarginRight(lastTdsWidth)
  }, [width])

  return (
    <div className="expanded-row-content" ref={ref} style={{ marginRight }}>
      {data.wirePurposes.map((detail, index) => (
        <div className={`expanded-row-content-custom-row grid detail-item justify-content-end ${index < data.wirePurposes.length - 1 ? "detail-border" : ""}`} key={index}>
          <div className="col-3 expanded-td">
            <span className="text-gray-500 monofont">{index + 1}. </span>
            {detail.purpose}
          </div>
          <div className="col-3 expanded-td text-right" style={{ paddingRight: "20px" }}>
            {getCurrencyString(detail.amount)}
          </div>
        </div>
      ))}
    </div>
  )
}

export default TransactionWiresGrid
