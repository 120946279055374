import { Controller, useForm } from "react-hook-form"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { Calendar } from "primereact/calendar"
import { InputNumber } from "primereact/inputnumber"
import TransactionService from "service/TransactionService"
import { useUser } from "hooks/useUser"
import { useState } from "react"
import { useToast } from "hooks/useToast"
import { trimObjectValues } from "utilities/format"
import constants from "constants/constants"

const TransactionSummary = ({ transaction, onTransactionUpdated, setOpenTransactionSummary, setSelectedTrId, setDisplayDeleteTransactionModal }) => {
  console.log(transaction)
  const { control, handleSubmit, setFocus, trigger, watch } = useForm({
    defaultValues: {
      issuer: transaction.issuer,
      transactionName: transaction.transactionName,
      closingDate: new Date(transaction.closingDate),
      parAmount: transaction.parAmount,
      type: transaction.type,
      isWireSender: false,
      wireSenderEmail: "",
      underwriterDiscount: transaction.underwriterDiscount,
    },
    reValidateMode: "onChange",
  })
  const isWireSender = watch("isWireSender")
  const wireSenderEmail = watch("wireSenderEmail")

  const { token, userData } = useUser()
  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const transactionService = new TransactionService()

  const typeOptions = [
    { name: "Negotiated", value: constants.TRANSACTION_TYPES.NEGOTIATED },
    { name: "Competitive", value: constants.TRANSACTION_TYPES.COMPETITIVE },
    { name: "Private Placement", value: constants.TRANSACTION_TYPES.PRIVATE_PLACEMENT },
  ]

  const isWireSenderOptions = [
    { name: "Yes", value: "Yes" },
    { name: "No", value: "No" },
  ]

  const onSubmit = (event) => {
    const isWireSender = event.isWireSender === "Yes"

    if (event.parAmount <= 0) {
      return toast.current.show({ severity: "error", summary: "Invalid PAR Amount", life: 8000 })
    }
    if (event.underwriterDiscount <= 0) {
      return toast.current.show({ severity: "error", summary: "Invalid Underwriter Discount Amount", life: 8000 })
    }
    setIsLoading(true)

    const closingDateMilliseconds = event.closingDate.setUTCHours(23, 59, 59, 999)
    const closingDate = new Date(closingDateMilliseconds)

    const body = trimObjectValues({
      issuer: event.issuer,
      transactionName: event.transactionName,
      closingDate: closingDate.toISOString(),
      parAmount: event.parAmount,
      underwriterDiscount: event.underwriterDiscount,
      type: event.type,
      isWireSender,
      ...(!isWireSender && { wireSenderEmail }),
    })

    const header = { Authorization: `bearer ${token}` }
    transactionService
      .editTransaction(header, transaction.id, body)
      .then((response) => {
        onTransactionUpdated()
        toast.current.show({ severity: "success", summary: "Transaction updated", life: 5000 })
      })
      .catch((error) => toast.current.show({ severity: "error", summary: "Can't update the transaction", detail: error.response.data, sticky: true }))
      .finally(() => setIsLoading(false))
  }

  const onError = (errors) => {
    const firstErrorName = Object.keys(errors)[0]
    setFocus(firstErrorName)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit, onError)} onError={(e) => console.error(e)} className="p-fluid formgrid grid row-gap-3" style={{ paddingTop: 25 }}>
        <>
          <div className="col-12 p-fluid formgrid grid form-content-block">
            <div className="field col-12 md:col-6">
              <label htmlFor="transactionName">Transaction Name</label>
              <Controller
                name="transactionName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <InputText
                      id={field.name}
                      ref={field.ref}
                      className={fieldState.error ? "input-error" : "input-correct"}
                      type="text"
                      placeholder="Type Transaction name here"
                      defaultValue={transaction.transactionName}
                      onChange={(e) => field.onChange(e.target.value.trimStart())}
                      disabled={isDisabled}
                    />
                    <br />
                    {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="issuer">Issuer Name</label>
              <Controller
                name="issuer"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <InputText disabled={isDisabled} id={field.name} ref={field.ref} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Type issuer name here" defaultValue={transaction.issuer} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                    <br />
                    {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="parAmount">PAR Amount</label>
              <Controller
                name="parAmount"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <InputNumber id={field.name} inputRef={field.ref} value={transaction.parAmount} onChange={(e) => field.onChange(e.value)} mode="currency" currency="USD" locale="en-US" inputClassName={fieldState.error ? "input-error" : "input-correct"} placeholder="$" disabled={isDisabled} />
                    <br />
                    {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="underwriterDiscount">Underwriter's Discount</label>
              <Controller
                name="underwriterDiscount"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <InputNumber id={field.name} inputRef={field.ref} value={field.value} onChange={(e) => field.onChange(e.value)} mode="currency" currency="USD" locale="en-US" inputClassName={fieldState.error ? "input-error" : "input-correct"} placeholder="$" disabled={isDisabled} />
                    <br />
                    {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="type">Method of Sale</label>
              <Controller
                name="type"
                control={control}
                rules={{ required: "Select a type" }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <Dropdown id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)} className={fieldState.error ? "input-error" : "input-correct"} placeholder="Select from options" options={typeOptions} optionLabel="name" optionValue="name" disabled={isDisabled} />
                    {/* Hidden input for getting focus on submit when there's an error with the field.
                    The inputRef does not work on Dropdown component with the ref from the field argument from React Hook Form
                    (it works with the InputNumber though) */}
                    <div style={{ position: "absolute", top: 0, width: 0, overflow: "hidden" }}>
                      <input style={{ maxHeight: 0, width: 0, position: "absolute", top: 0, opacity: 0 }} ref={field.ref} />
                    </div>
                    {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="closingDate">Closing Date</label>
              <Controller
                name="closingDate"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <Calendar
                      id={field.name}
                      value={field.value}
                      minDate={new Date()}
                      placeholder={"DD/MM/YYYY"}
                      onChange={(e) => field.onChange(e.value)}
                      disabled={isDisabled}
                      inputClassName={fieldState.error ? "input-error border-right-none" : "input-correct border-right-none"}
                      showIcon
                      showButtonBar
                    />
                    {/* Hidden input for getting focus on submit when there's an error with the field.
                    The inputRef does not work on Dropdown component with the ref from the field argument from React Hook Form
                    (it works with the InputNumber though) */}
                    <div style={{ position: "absolute", top: 0, width: 0, overflow: "hidden" }}>
                      <input style={{ maxHeight: 0, width: 0, position: "absolute", top: 0, opacity: 0 }} ref={field.ref} />
                    </div>
                    {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>
          </div>

          {/* <div className="field col-12 md:col-6">
            <Tooltip target=".trManagerInfoLabel" position="top" hideDelay={100} style={{ maxWidth: "40ch" }} />
            <label htmlFor="isTransactionManager">
              Transaction Manager <i className="pi pi-fw pi-info-circle trManagerInfoLabel" data-pr-tooltip="The Transaction Manager is the firm responsible for coordinating the closing details of the transaction - typically, the municipal advisor, underwriter or placement agent." />
            </label>
            <div className="relative">
              <InputText value={`${userData.firstName} ${userData.lastName}`} disabled />
            </div>
          </div> */}

          {isDisabled ? (
            <div className="field col-12 md:col-12 form-bottom-section">
              <Button
                disabled={isLoading}
                label="Delete"
                id="register-button"
                type="button"
                onClick={() => {
                  setOpenTransactionSummary(false)
                  setSelectedTrId(transaction.id)
                  setDisplayDeleteTransactionModal(true)
                }}
                className=" dialog-delete-transaction-button-delete"
              />
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  setIsDisabled(false)
                }}
                label="Edit"
                type="button"
                id="register-button"
                className="border-round-sm"
              />
            </div>
          ) : (
            <div className="field col-12 md:col-12 form-bottom-section">
              <Button
                type="button"
                disabled={isLoading}
                label="Cancel"
                id="register-button"
                onClick={() => {
                  setOpenTransactionSummary(false)
                }}
                className=" p-button-secondary p-button-text"
              />
              <Button
                disabled={isLoading}
                onClick={() => {
                  setIsDisabled(false)
                }}
                o
                isLoading={isLoading}
                label="Update"
                id="register-button"
                className="border-round-sm"
              />
            </div>
          )}
        </>
      </form>
    </div>
  )
}

export default TransactionSummary
