import axios from "providers/ConfigureAxios"
import { getUrl } from "./config/serviceUrls"

export default class InvoiceService {
  getAll(headers) {
    return axios({
      method: "get",
      url: `${getUrl()}/invoices`,
      headers
    })
  }

  getById(headers, invoiceId) {
    return axios({
      method: "get",
      url: `${getUrl()}/invoices/${invoiceId}`,
      headers
    })
  }
}
