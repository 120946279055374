import { Button } from "primereact/button"
import { getCurrencyString } from "utilities/format"
import { useUser } from "hooks/useUser"
import AccountDetailsService from "service/AccountDetailsService"
import { useToast } from "hooks/useToast"
import { Dialog } from "primereact/dialog"
import WireDeletePopUp from "./WireDelete"
import { useState } from "react"
import { TabView, TabPanel } from "primereact/tabview"
import iconRejected from "./../../../../assets/rejected.svg"
import WiresService from "service/WiresService"

const WireOpen = ({ wire, wireHistory, setVisible, setIsLoading, setEditWireOpenForm, setSelectedWireData, loadWires, transactionId, loadParticipants }) => {
  const { userData: loggedUserData, token } = useUser()
  const wireService = new WiresService()
  const [displayConfirmation, setDisplayConfirmation] = useState(false)
  const header = (
    <>
      <l style={{ color: "#667085" }}>New Wire </l>
      <i class="p-row-toggler-icon pi pi-chevron-right" style={{ color: "#667085" }}></i>
      <span style={{ color: "#101828" }}> Summary</span>
    </>
  )
  const maskedString = "XXXXXXXXXX"
  const accountDetailsService = new AccountDetailsService()
  const toast = useToast()
  const headers = {
    Authorization: `bearer ${token}`,
  }

  const onActionAccountDetails = (status) => {
    setIsLoading(true)
    accountDetailsService
      .approveAccountDetails(headers, wire.accountDetails.id, {
        approveStatus: status,
        //...(disapprovalReason !== "" && { disapprovalReason }),
      })
      .then(() =>
        toast.current.show({
          severity: "success",
          summary: `You successfully ${status.toLowerCase()} the Account Details`,
          detail: "The Account Details are considered approved once the TR Manager, the Wire Sender and the Wire Receiver have approved them",
          sticky: true,
        })
      )
      .catch((error) => {
        console.error(error)
        toast.current.show({ severity: "error", summary: `Can't ${status.toLowerCase()} the account details`, detail: error.response.data, sticky: true })
      })
      .finally(() => {
        setVisible(false)
        setIsLoading(false)
        loadWires()
      })
  }
  const createdOnDate = new Date(wire.createdOn)
  const createdOnDateFormated = `${(createdOnDate.getMonth() + 1).toString().padStart(2, "0")}/${createdOnDate.getDate().toString().padStart(2, "0")}/${createdOnDate.getFullYear()}`

  const onDeleteWire = () => {
    setIsLoading(true)
    wireService
      .deleteWire(headers, wire.id)
      .then((response) => {
        if (response && response.data) {
          toast.current.show({
            severity: "success",
            summary: `You successfully deleted the Wire`,
            sticky: true,
          })
          setVisible(false)
          loadWires()
        }
      })
      .catch((error) => {
        console.error(error)
        toast.current.show({ severity: "error", summary: `Can't delete the wire`, detail: error.response.data, sticky: true })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const wasChanged = (wire, field) => {
    if (wire.updatedFields.includes(field)) {
      return "rgb-color"
    }
    return ""
  }

  if (wireHistory.length > 1)
    return (
      <div class="wire-modal">
        <table class="table m-0" id="modaltabledata">
          <tbody>
            <tr>
              <td class="fw-500">
                <p>ABA Number</p>
                <p>Account Name</p>
                <p>Account Number</p>
                <p>Wire Amount</p>
              </td>
              {wireHistory.map((wh, index) => {
                return (
                  <td class={`fw-600 ${index == 0 ? "colorblack" : ""}`} key={`wh_acc_${index}`}>
                    <p class={wasChanged(wh, "FBO")}>{wh.aba}</p>
                    <p class={wasChanged(wh, "FBO")}>{wh.fbo}</p>
                    <p class={wasChanged(wh, "AccountNumber")}>{wh.accountNumber}</p>
                    <p class={wasChanged(wh, "Amount")}>{getCurrencyString(wire.amount)}</p>
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>Purpose</td>
              {wireHistory.map((wh, index) => {
                return (
                  <td class={wasChanged(wh, "WirePurposes")} key={`wh_wp_${index}`}>
                    {wh.wirePurposes.map((wp) => (
                      <p>
                        {wp.purpose} - {getCurrencyString(wp.amount)}
                      </p>
                    ))}
                  </td>
                )
              })}
            </tr>
            <tr>
              <td>Organisation</td>
              {wireHistory.map((wh, index) => (
                <td key={`wh_org_${index}`}>{wire.organization}</td>
              ))}
            </tr>
            <tr>
              <td>Wire Receiver</td>
              {wireHistory.map((_, index) => (
                <td key={`wh_email_${index}`}>{wire.wireReceiverEmail}</td>
              ))}
            </tr>
            <tr>
              <td>Recipient Role</td>
              {wireHistory.map((wh, index) => (
                <td key={`wh_role_${index}`} className={wasChanged(wh, "Role")}>
                  {wh.role}
                </td>
              ))}
            </tr>
            <tr>
              <td>Bank Name</td>
              {wireHistory.map((wh, index) => (
                <td key={`wh_fbo_${index}`} className={wasChanged(wh, "FBO")}>
                  {wh.fbo}
                </td>
              ))}
            </tr>

            <tr>
              <td>Remarks</td>
              {wireHistory.map((wh, index) => (
                <td key={`wh_remarks_${index}`} className={wasChanged(wh, "FundApplicationInstructions")}>
                  {wh.fundApplicationInstructions ? wh.fundApplicationInstructions : "NA"}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div style={{ height: "30px" }}>
          {wire.status == "Rejected" ? (
            <div className="row-status-wire-rejected">
              <img src={iconRejected} />
              <span>Rejected</span>
              <span className="row-status-date">{createdOnDateFormated}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div class="wire-footer d-flex justify-content-end p-3">
          {loggedUserData.email === wire.wireReceiverEmail && wire.status === "Invited" ? (
            <>
              <Button
                className="btn-outline-custom"
                style={{ marginRight: 10 }}
                onClick={() => {
                  onActionAccountDetails("Rejected")
                }}
              >
                Modifications needed
              </Button>
              <Button
                className="btn-approve ms-2"
                onClick={() => {
                  onActionAccountDetails("Approved")
                }}
              >
                Approve Details
              </Button>
            </>
          ) : (
            <>
              {loggedUserData.email === wire.transactionManagerEmail && ["Created", "Rejected"].includes(wire.status) && (
                <>
                  <Button
                    className="btn-outline-custom"
                    onClick={() => {
                      setEditWireOpenForm(true)
                      setVisible(false)
                      setSelectedWireData(wire)
                    }}
                  >
                    Edit Wire
                  </Button>
                  <Button
                    onClick={() => {
                      setDisplayConfirmation(true)
                    }}
                    className="btn-outline-custom"
                  >
                    Delete Wire
                  </Button>
                </>
              )}
            </>
          )}
        </div>
        <Dialog header="Are you sure?" className="dialog-delete-wire" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} headerStyle={{ borderRadius: 5 }} style={{ borderRadius: 5 }}>
          <WireDeletePopUp wire={wire} setIsLoading={setIsLoading} loadParticipants={loadParticipants} loadWires={loadWires} setVisible={setVisible} headers={headers} />
        </Dialog>
      </div>
    )
  if (wireHistory.length === 1)
    return (
      <div style={{ paddingLeft: "28px", paddingRight: "28px", width: "500px" }} className="wire-open">
        {wireHistory.map((wh, index) => (
          <TabView>
            <TabPanel key={`wh_${index}`}>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 10, marginBottom: "15px", borderBottom: "1px solid #e0e0e0" }}>
                <div className="row-2 mb-2" id="row-principal-confirm-wire">
                  <label className="new-confirm-wire-label">ABA Number</label>
                  <span className="new-confirm-wire-value-1">{wh.aba || maskedString}</span>
                </div>

                <div className="row-2 mb-2" id="row-principal-confirm-wire">
                  <label className="new-confirm-wire-label">Account Name</label>
                  <span className="new-confirm-wire-value-1">{wh.fbo}</span>
                </div>
                <div className="row-2 mb-2" id="row-principal-confirm-wire">
                  <label className="new-confirm-wire-label">Account Number</label>
                  <span className="new-confirm-wire-value-1">{wire.accountDetails ? wire.accountDetails.accountNumber || maskedString : maskedString}</span>
                </div>
                <div className="row-2 mb-2" id="row-principal-confirm-wire">
                  <label className="new-confirm-wire-label">Wire Amount</label>
                  <span className="new-confirm-wire-value-1">{getCurrencyString(wire.amount)}</span>
                </div>
                <div className="row-confirm-wire-purpose-content pt-3 pb-3" style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 10, marginBottom: 10, gap: 36 }}>
                  <span className="new-confirm-wire-label-purpose">Purpose</span>
                  <div className="flex flex-column gap-2">
                    {wh.wirePurposes.map((p) => (
                      <div>
                        <div className="row-confirm-wire-purpose">
                          {getCurrencyString(p.amount)} - {p.purpose}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row-2 pt-3 pb-3" id="row-confirm-wire">
                  <span className="new-confirm-wire-label">Wire Receiver</span>
                  <span className="new-confirm-wire-value">{wire.wireReceiverEmail}</span>
                </div>
                <div className="row-2 pt-3 pb-3" id="row-confirm-wire">
                  <span className="new-confirm-wire-label">Recipient Role</span>
                  <span className="new-confirm-wire-value">{wh.role}</span>
                </div>
                <div className="row-2 pt-3 pb-3" id="row-confirm-wire">
                  <span className="new-confirm-wire-label">Bank Name</span>
                  <span className="new-confirm-wire-value">{wh.receiver || maskedString}</span>
                </div>
                {/* <div className="row-2" id="row-confirm-wire">
                <span className="new-confirm-wire-label">ABA Number</span>
                <span className="new-confirm-wire-value">{wh.aba || maskedString}</span>
              </div> */}
                <div className="row-2 pt-3 pb-3" id="row-confirm-wire">
                  <span className="new-confirm-wire-label">Instructions</span>
                  <span className="new-confirm-wire-value">{wh.fundApplicationInstructions ? wh.fundApplicationInstructions : "NA"}</span>
                </div>
              </div>
              <div class="wire-footer d-flex justify-content-between">
                {loggedUserData.email === wire.wireReceiverEmail && wire.status === "Invited" ? (
                  <>
                    <Button
                      className="p-button p-component new-wire-confirm-edit border-round-sm"
                      style={{ marginRight: 10, width: "48%" }}
                      onClick={() => {
                        onActionAccountDetails("Rejected")
                      }}
                    >
                      Modifications needed
                    </Button>
                    <Button
                      className="p-button p-component new-wire-confirm-submit border-round-sm"
                      onClick={() => {
                        onActionAccountDetails("Approved")
                      }}
                      style={{ width: "48%" }}
                    >
                      Approve Details
                    </Button>
                  </>
                ) : (
                  <>
                    {loggedUserData.email === wire.transactionManagerEmail && ["Created", "Rejected"].includes(wire.status) && (
                      <>
                        <Button
                          onClick={() => {
                            setDisplayConfirmation(true)
                          }}
                          style={{ background: "#F04438", borderColor: "#F04438", color: "#fff" }}
                          className="p-button p-component new-wire-confirm-submit border-round-sm"
                        >
                          Delete Wire
                        </Button>
                        <Button
                          style={{ marginLeft: "12px" }}
                          className="p-button p-component new-wire-confirm-edit border-round-sm"
                          onClick={() => {
                            setEditWireOpenForm(true)
                            setVisible(false)
                            setSelectedWireData(wire)
                          }}
                        >
                          Edit Wire
                        </Button>
                      </>
                    )}
                  </>
                )}
              </div>
              <Dialog header="Are you sure?" className="dialog-delete-wire" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} headerStyle={{ borderRadius: 5 }} style={{ borderRadius: 5 }}>
                <WireDeletePopUp wire={wire} setIsLoading={setIsLoading} loadWires={loadWires} setVisible={setVisible} headers={headers} loadParticipants={loadParticipants} />
              </Dialog>
            </TabPanel>
          </TabView>
        ))}
      </div>
    )
}

export default WireOpen
