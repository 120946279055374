import InputPhone from "components/Inputs/InputPhone"
import { useUser } from "hooks/useUser"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import UserService from "service/UserService"
import { formatPhoneNumber, trimObjectValues } from "utilities/format"

export default function ProfileEdit({ onProfileEdited }) {
  const [isLoading, setIsLoading] = useState(false)

  const { token, userData, setUserData, role } = useUser()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      firstName: userData.firstName,
      lastName: userData.lastName,
      title: userData.title,
      phone: formatPhoneNumber(userData.phone),
    },
    reValidateMode: "onChange",
  })

  const userService = new UserService()

  const onSubmit = (values) => {
    setIsLoading(true)
    const headers = { Authorization: `bearer ${token}` }

    // trim white spaces
    values = trimObjectValues(values)
    values.phone = values.phone.replace(/[^\d]/g, "")

    userService
      .editProfile(headers, values)
      .then(({ data: user }) => {
        // Modifying the logged in user data. Update the User Provider.
        setUserData(user)
        onProfileEdited()
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <div className="card">
      <div className="card-subtitle grid">
        <div className="col-12 md:col-12">{`Organization / Company: ${userData.organization ?? "N/A"}`}</div>
      </div>

      <form onSubmit={handleSubmit(onSubmit, (e) => console.log("error on submit"))} className="p-fluid formgrid grid row-gap-3">
        <div className="field col-12 md:col-6">
          <label htmlFor="firstName">First Name</label>
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required field",
              },
            }}
            render={({ field, fieldState }) => (
              <div className="relative">
                <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="First Name" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                <br />
                {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
              </div>
            )}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="lastName">Last Name</label>
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required field",
              },
            }}
            render={({ field, fieldState }) => (
              <div className="relative">
                <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Last Name" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                <br />
                {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
              </div>
            )}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="title">Title</label>
          <Controller
            name="title"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required field",
              },
            }}
            render={({ field, fieldState }) => (
              <div className="relative">
                <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Title" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                <br />
                {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
              </div>
            )}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="phone">Phone Number</label>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required field",
              },
              minLength: {
                value: 14,
                message: "Invalid phone number",
              },
            }}
            render={({ field, fieldState }) => (
              <div className="relative">
                <InputPhone id={field.name} value={field.value} error={fieldState.error} onChange={field.onChange} />
                <br />
                {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
              </div>
            )}
          />
        </div>

        <div className="field col-12 md:col-6" style={{ cursor: "not-allowed" }}>
          <label htmlFor="role" className="text-400">
            Role
          </label>
          <InputText disabled id="role" className="input-correct" type="text" value={role} />
        </div>

        <div className="spacer" />

        <div className="field col-12 md:col-12 registerBottom">
          <div className="field col-12 md:col-5 col-offset-6">
            <Button loading={isLoading} label="Submit" id="register-button" />
          </div>
        </div>
      </form>
    </div>
  )
}
