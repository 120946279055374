import { useEffect, useMemo } from "react"
import * as SignalR from "@microsoft/signalr"
import { getUrl } from "service/config/serviceUrls"
import { useUser } from "./useUser"
// import { useToast } from "hooks/useToast"

export const useTransactionInvitationUpdatesSocket = (onUpdated, transactionId) => {
  const { token } = useUser()

  const connection = useMemo(
    () =>
      token &&
      new SignalR.HubConnectionBuilder()
        .withUrl(`${getUrl()}/transaction-invitation-updates`, {
          accessTokenFactory: () => token,
          skipNegotiation: true,
          transport: SignalR.HttpTransportType.WebSockets,
        })
        .configureLogging(SignalR.LogLevel.Information)
        .withAutomaticReconnect()
        .build(),
    [token]
  )

  // const toast = useToast()

  useEffect(() => {
    if (connection && connection?.state === SignalR.HubConnectionState.Disconnected) {
      connection
        .start()
        .then(() => {
          connection.invoke("AddToGroup", transactionId)

          connection.on("WiresUpdated", (message) => {
            onUpdated("WiresUpdated")
            // toast.current?.show({ severity: "success", summary: message, sticky: true })
          })

          connection.on("ParticipantsUpdated", (message) => {
            onUpdated("ParticipantsUpdated")
            // toast.current?.show({ severity: "success", summary: message, sticky: true })
          })
        })
        .catch((err) => console.error("Connection error: ", err))
    }

    return () => {
      connection?.state === SignalR.HubConnectionState.Connected && connection.invoke("RemoveFromGroup", transactionId).finally(() => connection.stop())
    }
  }, [connection, onUpdated, transactionId])
}
