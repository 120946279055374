import React from 'react';
import { Tag } from "primereact/tag"


const CustomTag = (rowData) => {
    return (

        <Tag className={"p-tag-rounded " + "status-" + rowData.status}
            style={{
                height: 22,
                flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}>
            <i className="pi pi-circle-fill" style={{ fontSize: 8, color: '#344054', marginRight: 8 }}></i>
            <span className="text-base">{rowData.status}</span>

        </Tag>)
}

export default CustomTag;