import { Button } from "primereact/button"
import WiresService from "service/WiresService"
import { useToast } from "hooks/useToast"

const WireDeletePopUp = ({ wire, setIsLoading, loadWires, setVisible, headers, loadParticipants }) => {
  const wireService = new WiresService()
  const toast = useToast()

  const onDeleteWire = () => {
    setIsLoading(true)
    wireService
      .deleteWire(headers, wire.id)
      .then((response) => {
        if (response && response.data) {
          toast.current.show({
            severity: "success",
            summary: `You successfully deleted the Wire`,
            sticky: true,
          })
          setVisible(false)
          loadWires()
          loadParticipants()
        }
      })
      .catch((error) => {
        console.error(error)
        toast.current.show({ severity: "error", summary: `Can't delete the wire`, detail: error.response.data, sticky: true })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  //   const footerContent = (
  //     <div>
  //         <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
  //         <Button label="Yes" icon="pi pi-check" onClick={() => setVisible(false)} autoFocus />
  //     </div>
  // );

  return (
    <>
      <div className="dialog-delete-wire-pop-up">
        <div style={{ height: "50%" }} style={{ display: "flex", alignItems: "center" }}>
          <div className="dialog-delete-wire-first-text" style={{ marginRight: "10px", marginBottom: "0" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            {/* <img src={alertCircle} style={{ width: 24, height: 24, backgroundColor: "white", marginRight: 4 }} /> */}
          </div>
          {/* <div className="dialog-delete-wire-first-text">Delete Wire</div> */}
          <div className="dialog-delete-wire-second-text">Are you sure you want to delete this wire? This action cannot be undone.</div>
        </div>
      </div>
      <div class="p-dialog-footer" style={{ display: "flex", padding: ".5rem" }}>
        <Button label="Cancel" onClick={() => setVisible(false)} className="dialog-delete-wire-button-cancel" style={{ flex: "1 1" }} />
        <Button label="Delete" onClick={() => onDeleteWire()} className="dialog-delete-wire-button-delete" style={{ flex: "1 1" }} />
      </div>
    </>
  )
}

export default WireDeletePopUp
