import { Controller, useForm } from "react-hook-form"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useEffect, useRef } from "react"

import AccountService from "service/AccountService"
import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { InputText } from "primereact/inputtext"
import PageWrapper from "pages/PageWrapper"
import PasswordResetForm from "components/PasswordReset/PasswordResetForm"
import { emailRegex } from "utilities/format"
import { useToast } from "hooks/useToast"
import munisafeLogo from "assets/login-logo.svg"

const PasswordResetIndex = () => {
  const timeoutRef = useRef(null)

  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")

  const {
    clearErrors,
    control,
    formState: { isSubmitSuccessful, isSubmitting },
    handleSubmit,
    setError,
    watch,
  } = useForm({
    defaultValues: {
      email: "",
    },
    reValidateMode: "onChange",
  })
  const email = watch("email")

  const toast = useToast()

  const navigate = useNavigate()

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const onSubmit = async ({ email }) => {
    const accountService = new AccountService()

    try {
      await accountService.sendPasswordResetLink(email)
      timeoutRef.current = setTimeout(() => {
        navigate("/login")
      }, 12000)
    } catch (error) {
      setError("server", { type: "custom", message: error.response.data })
      toast.current.show({ severity: "error", summary: "Can't send the reset email", detail: error.response.data, sticky: true })
    }
  }

  const formFooter = (
    <p>
      Remember your password? Go to the <Link to="/login">login page</Link>.
    </p>
  )

  const successFooter = (
    <p>
      You are now being automatically redirected to the login page. If your are not redirected automatically, <Link to="/login">click here</Link>.
    </p>
  )

  return (
    <div className="login-page">
      <div className="login-header">
        {/* <img src={munisafeLogo} alt="munisafe-logo" className="login-logo" /> */}
        {/* <p className="login-header-text inria-serif-regular">Secure the details of your municipal bond closing.</p> */}
      </div>
      <div className="login-body">
        <div className="login-wrapper">
          <div className="login-panel">
            <div className="col-12 md:col-12">
              <form onSubmit={handleSubmit(onSubmit)} className="card p-fluid">
                <h2 className="heading">Forgot Password?</h2>
                <p className="login-text">Enter the Email ID associated with your account. We will send a link to reset password</p>

                <div className="field">
                  <label htmlFor="email" className="login-label">
                    Email ID
                  </label>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Required field",
                      },
                      pattern: {
                        value: emailRegex,
                        message: "Invalid email address",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <div className="relative">
                        <InputText id={field.name} value={field.value} onChange={(e) => field.onChange(e.target.value.trim())} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Email" />
                        <br></br>
                        {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                      </div>
                    )}
                  />
                </div>

                <div className="field">
                  <Button loading={isSubmitting} label="Continue" onClick={() => clearErrors()} />
                </div>
              </form>
            </div>
          </div>
        </div>
        <p id="already-login">
          New to MuniSafe? <a href="/register">Join now.</a>
        </p>
      </div>
      <div class="login-footer">
        <ul class="footer-nav">
          <li>
            <Link to="#">Contact Us</Link>
          </li>
          <li>
            <Link to="#">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="#">Privacy policy</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default PasswordResetIndex
