import InputPhone from "components/Inputs/InputPhone"
import { useUser } from "hooks/useUser"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { Controller, useForm } from "react-hook-form"
import UserService from "service/UserService"
import { formatPhoneNumber, trimObjectValues } from "utilities/format"

export default function UserEdit({ userData, onUserEdited }) {
  const { token, userData: loggedUserData, setUserData } = useUser()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      firstName: userData.firstName,
      lastName: userData.lastName,
      title: userData.title,
      phone: formatPhoneNumber(userData.phone),
      status: userData.status,
    },
    reValidateMode: "onChange",
  })

  const userService = new UserService()

  const onSubmit = (values) => {
    const headers = { Authorization: `bearer ${token}` }

    values = { ...trimObjectValues(values), email: userData.email }
    values.phone = values.phone.replace(/[^\d]/g, "")

    userService.editUser(headers, values).then(({ data: user }) => {
      if (userData.email === loggedUserData.email) {
        // Modifying the logged in user data. Update the User Provider.
        setUserData({ ...user })
      }
      onUserEdited()
    })
  }

  const userStatusItems = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
    { label: "Blocked", value: "Blocked" },
  ]

  return (
    <div className="card">
      <form onSubmit={handleSubmit(onSubmit, (e) => console.log("error on submit"))} className="p-fluid formgrid grid row-gap-3">
        <div className="field col-12 md:col-6">
          <label htmlFor="firstName">First Name</label>
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required field",
              },
            }}
            render={({ field, fieldState }) => (
              <div className="relative">
                <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="First Name" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                <br />
                {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
              </div>
            )}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="lastName">Last Name</label>
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required field",
              },
            }}
            render={({ field, fieldState }) => (
              <div className="relative">
                <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Last Name" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                <br />
                {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
              </div>
            )}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="title">Title</label>
          <Controller
            name="title"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required field",
              },
            }}
            render={({ field, fieldState }) => (
              <div className="relative">
                <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Title" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                <br />
                {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
              </div>
            )}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="phone">Phone Number</label>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required field",
              },
              minLength: {
                value: 14,
                message: "Invalid phone number",
              },
            }}
            render={({ field, fieldState }) => (
              <div className="relative">
                <InputPhone id={field.name} value={field.value} error={fieldState.error} onChange={field.onChange} />
                <br />
                {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
              </div>
            )}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="status">Status</label>
          <Controller
            name="status"
            control={control}
            rules={{ required: "Required field" }}
            render={({ field, fieldState }) => (
              <>
                <Dropdown id={field.name} value={field.value} options={userStatusItems} className={fieldState.error ? "input-error" : "input-correct"} onChange={(e) => field.onChange(e.value)} placeholder="Select a Status" />
                <br />
                {fieldState.error && <span className="p-error positioned-error-label">{fieldState.error.message}</span>}
              </>
            )}
          />
        </div>

        <div className="spacer" />

        <div className="field col-12 md:col-12 registerBottom">
          <div className="field col-12 md:col-5 col-offset-6">
            <Button label="Submit" id="register-button" />
          </div>
        </div>
      </form>
    </div>
  )
}
