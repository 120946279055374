import { useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom"
import { Dialog } from "primereact/dialog"
import { classNames } from "primereact/utils"
import AppBreadcrumb from "../AppBreadcrumb"
import { useUser } from "hooks/useUser"
import ProfileEdit from "components/Profile/ProfileEdit"
import ChangePassword from "components/Profile/ChangePassword"
import NotificationsBadge from "App/Topbar/NotificationsBadge"
import { Button } from "primereact/button"
import { Avatar } from "primereact/avatar"
import { Menu } from "primereact/menu"
import { Toast } from "primereact/toast"
import { FileUpload } from "primereact/fileupload"
import UserService from "service/UserService"

const AppTopbar = (props) => {
  const [displayProfileEdit, setDisplayProfileEdit] = useState(false)
  const [displayPasswordReset, setDisplayPasswordReset] = useState(false)
  const toast = useRef(null)
  const fileUploadRef = useRef(null)
  const { userData, clear, token, setUserData } = useUser()
  const rightMenu = useRef(null)
  const [profilePicDialogVisible, setProfilePicDialogVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const itemRenderer = (item) => (
    <div onClick={item.command} className="p-menuitem-content">
      <a className="flex align-items-center p-menuitem-link">
        <span className={item.icon} />
        <span className="mx-2">{item.label}</span>
        {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
      </a>
    </div>
  )
  const getLabel = userData.name ? userData.name.charAt(0) + userData.name?.split(" ")[1]?.charAt(0) : ""
  const userService = new UserService()
  const headers = {
    Authorization: `bearer ${token}`,
  }

  const getAvatarImage = () => (userData.profilePicture ? `data:image/png;base64,${userData.profilePicture}` : null)

  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)

  const items = [
    {
      template: (item, options) => (
        <div className={classNames(options.className, "w-full flex align-items-center p-2 pl-4 text-color  border-noround")} style={{ cursor: "default" }}>
          <Avatar
            image={getAvatarImage()}
            label={getLabel}
            style={{ backgroundColor: "#000F66 !important" }}
            size="large"
            onClick={() => {
              setProfilePicDialogVisible(true)
            }}
            className="mr-2"
            shape="circle"
          ></Avatar>
          <div className="flex flex-column align">
            <span className="font-bold">{userData.name}</span>
            <span className="text-sm">{userData.email}</span>
          </div>
        </div>
      ),
    },
    {
      label: "Logout",
      icon: "pi pi-sign-out",
      template: itemRenderer,
      command: () => clear(),
    },
  ]

  const onUpload = () => {
    const file = fileUploadRef?.current?.getFiles().length > 0 ? fileUploadRef.current.getFiles()[0] : null
    if (file) {
      setLoading(true)
      userService.updateProfilePhoto(headers, file).then((res) => {
        setProfilePicDialogVisible(false)
        setUserData({ ...userData, profilePicture: res.data.profilePicture })
        toast.current.show({ severity: "success", summary: "Success", detail: "Profile picture uploaded successfully" })
        setLoading(false)
        setShowRemoveConfirmation(false)
      })
    }
  }

  const removeImage = () => {
    setLoading(true)
    userService.updateProfilePhoto(headers, null).then((res) => {
      setProfilePicDialogVisible(false)
      setUserData({ ...userData, profilePicture: null })
      toast.current.show({ severity: "success", summary: "Success", detail: "Profile picture removed successfully" })
      setLoading(false)
    })
  }

  return (
    <>
      <Dialog visible={displayProfileEdit} style={{ width: "60vw" }} header="Edit Profile" onHide={() => setDisplayProfileEdit(false)}>
        <ProfileEdit onProfileEdited={() => setDisplayProfileEdit(false)} />
      </Dialog>

      <Dialog visible={displayPasswordReset} style={{ width: "60vw" }} header="Change password" onHide={() => setDisplayPasswordReset(false)}>
        <ChangePassword onPasswordChanged={() => setDisplayPasswordReset(false)} />
      </Dialog>
      <Dialog
        closable={true}
        visible={profilePicDialogVisible}
        style={{ width: "20vw" }}
        onHide={() => {
          setProfilePicDialogVisible(false)
          setShowRemoveConfirmation(false)
        }}
      >
        {!userData.profilePicture ? (
          <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 10 }}>Browse to upload a new profile picture</div>
            <div className="field col-12 md:col-12 " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div>
                <FileUpload ref={fileUploadRef} chooseLabel="Browse" chooseOptions={{ iconOnly: false }} mode="basic" name="profilePic" accept="image/*" maxFileSize={5242880} />
              </div>
            </div>
            <div className="field col-12 md:col-12 form-bottom-section">
              <Button
                label="Cancel"
                id="register-button"
                onClick={() => {
                  setProfilePicDialogVisible(false)
                }}
                className="p-button-secondary p-button-text"
              />
              <Button label="Upload" id="register-button" className="border-round-sm" onClick={onUpload} loading={loading} />
            </div>
          </>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 10 }}>
              <b>Update Profile Photo</b>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 10 }}>
              {!showRemoveConfirmation ? <Avatar image={getAvatarImage()} label={getLabel} style={{ width: "7rem", height: "7rem" }} className="mr-2" shape="circle" /> : <Avatar label={getLabel} style={{ width: "7rem", height: "7rem" }} className="mr-2" shape="circle" />}
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 10, paddingBottom: 10 }}>
              {showRemoveConfirmation ? (
                <span className="p-5">If you remove your profile image, it will be replaced with a default image. Would you like to proceed?</span>
              ) : (
                <>
                  <FileUpload ref={fileUploadRef} chooseLabel="Update" className=" p-button-text pr-5" chooseOptions={{ iconOnly: false, icon: "pi pi-cloud-upload" }} mode="basic" name="profilePic" accept="image/*" maxFileSize={5242880} />
                  <Button label="Remove" icon="pi pi-trash" className="p-button-secondary p-button-text" severity="danger" onClick={() => setShowRemoveConfirmation(true)} />
                </>
              )}
            </div>

            <div className="field col-12 md:col-12 form-bottom-section">
              <Button
                label="Close"
                id="register-button"
                onClick={() => {
                  setProfilePicDialogVisible(false)
                }}
                className="p-button-secondary p-button-text"
              />
              {showRemoveConfirmation ? <Button label="Remove" id="register-button" className="border-round-sm" onClick={removeImage} loading={loading} /> : <Button label="Save" id="register-button" className="border-round-sm" onClick={onUpload} loading={loading} />}
            </div>
          </>
        )}
      </Dialog>

      <div className="layout-topbar">
        <div className="topbar-left">
          <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
            <i className="pi pi-chevron-left"></i>
          </button>

          <Link to="/"></Link>

          <span className="topbar-separator"></span>

          <div className="layout-breadcrumb viewname" style={{ textTransform: "uppercase" }}>
            <AppBreadcrumb meta={props.meta} />
          </div>

          <img id="logo-mobile" className="mobile-logo" src="assets/layout/images/logo-dark.svg" alt="diamond-layout" />
        </div>

        <div className="layout-mask modal-in"></div>

        <div className="topbar-right">
          <ul className="topbar-menu">
            <li>
              <p className="layout-menu">Hi, {userData.name}</p>
            </li>
            <span className="topbar-separator" style={{ justifyContent: "center", display: "flex" }} />
            <NotificationsBadge onTopbarNotification={props.onTopbarNotification} topbarNotificationMenuActive={props.topbarNotificationMenuActive} />
            <Toast ref={toast}></Toast>
            <Menu model={items} popup ref={rightMenu} style={{ width: "auto" }} />
            <Avatar className="topbar-button-logout " image={getAvatarImage()} onClick={(event) => rightMenu.current.toggle(event)} style={{ justifyContent: "center", display: "flex", fontSize: "1rem" }} size="xlarge" shape="circle">
              {getAvatarImage() === null && (
                <>
                  <span className=" pl-2">{getLabel}</span>
                  <span className="pi pi-angle-down pt-1"></span>
                </>
              )}
            </Avatar>
          </ul>
        </div>
      </div>
    </>
  )
}

export default AppTopbar
