import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import WiresService from "service/WiresService"
import { useUser } from "hooks/useUser"
import { useToast } from "hooks/useToast"
import { emailRegex, getCurrencyString, trimObjectValues } from "utilities/format"
import { Panel } from "primereact/panel"
import { Ripple } from "primereact/ripple"
import WireSummary from "./WireSummary.js"
import buttonPlusImage from "./../../../../assets/Buttonplusbutton.png"
import UserService from "service/UserService.js"
import plusicon from "assets/images/Vector.svg"
import erroricon from "assets/images/Subtract.svg"
import loaderc from "assets/images/loaderc.svg"

const fieldLabels = {
  amount: "Wire Amount",
  role: "Role",
  purpose: "Purpose",
  otherPurpose: "Other Purpose",
  wireReceiverEmail: "Wire Receiver",
  receiver: "Fund wires to (Bank Name)",
  aba: "ABA Number",
  fbo: "FBO / account name",
  accountNumber: "Account Number",
  references: "References",
  fundApplicationInstructions: "Instructions regarding application of funds received",
  otherComments: "Other Comments",
}

export const remarksHeaderTemplate = (options) => {
  const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up"
  const className = `${options.className} justify-content-start form-panel-header`
  const titleClassName = `${options.titleClassName} ml-2 text-primary`
  const style = { fontSize: "14px", fontWeight: "600" }
  return (
    <div className={className}>
      <button className={options.togglerClassName} onClick={options.onTogglerClick}>
        <span className={toggleIcon}></span>
        <Ripple />
      </button>
      <h5 className={titleClassName} style={style}>
        Remarks
      </h5>
    </div>
  )
}

export const recipientDetailssHeaderTemplate = (options) => {
  const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up"
  const className = `${options.className} justify-content-start form-panel-header`
  const titleClassName = `${options.titleClassName} ml-2 text-primary`
  const style = { fontSize: "14px", fontWeight: "600" }

  return (
    <div className={className}>
      <button className={options.togglerClassName} onClick={options.onTogglerClick}>
        <span className={toggleIcon}></span>
        <Ripple />
      </button>
      <h5 className={titleClassName} style={style}>
        Recipient Details
      </h5>
    </div>
  )
}

export const wiretDetailssHeaderTemplate = (options) => {
  const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up"
  const className = `${options.className} justify-content-start form-panel-header`
  const titleClassName = `${options.titleClassName}  text-primary`
  const style = { fontSize: "14px", fontWeight: "600" }

  return (
    <div className={className}>
      <h5 className={`${titleClassName} flex align-items-center h-full pl-2`} style={style}>
        Wire Details
      </h5>
    </div>
  )
}

const WireNew = ({ transactionId, onWireCreated }) => {
  const [wirePurposes, setWirePurposes] = useState([{ amount: null, purpose: null }])
  const userService = new UserService()
  const { control, handleSubmit, resetField, setFocus, trigger, watch } = useForm({
    defaultValues: {
      // Wire
      role: null,
      otherPurpose: "",
      wireReceiverEmail: "",
      // Account details
      receiver: "",
      aba: null,
      fbo: "",
      accountNumber: null,
      references: "",
      otherComments: "",
      fundApplicationInstructions: "",
      wires: wirePurposes,
    },
    reValidateMode: "onChange",
  })

  const [isLoading, setIsLoading] = useState(false)
  const [newWire, setNewWire] = useState(null)

  const wiresService = new WiresService()

  const { token, userData } = useUser()
  const toast = useToast()

  const roleOptions = [
    { name: "County", value: "County" },
    { name: "Escrow Agent", value: "Escrow Agent" },
    { name: "Issuer", value: "Issuer" },
    { name: "Trustee", value: "Trustee" },
    { name: "Bond Insurer", value: "Bond Insurer" },
  ]

  const purposeOptions = [
    { name: "Project Fund", value: "Project Fund" },
    { name: "Costs of Issuance", value: "Costs of Issuance" },
    { name: "Insurance", value: "Insurance" },
    { name: "Other", value: "Other" },
  ]

  const bankNameOptions = [
    { name: "Bank Option #1", value: "Bank Option #1" },
    { name: "Bank Option #2", value: "Bank Option #2" },
    { name: "Bank Option #3", value: "Bank Option #3" },
    { name: "Bank Option #4", value: "Bank Option #4" },
  ]

  const onSubmit = (values) => {
    // if (wireReceiverEmailState.state !== "ok") {
    //   return toast.current.show({ severity: "error", summary: "Wire receiver email not found", life: 8000 })
    // }
    if (values.wireReceiverEmail === userData.email) {
      return toast.current.show({ severity: "error", summary: "Can't assign yourself as the Wire Receiver", life: 8000 })
    }
    let invalidAmount = false
    wirePurposes.map((purp) => {
      if (purp.amount && purp.amount < 0) {
        invalidAmount = true
      }
    })
    if (invalidAmount) {
      return toast.current.show({ severity: "error", summary: "Invalid amount", life: 8000 })
    }
    if (!emailRegex.test(values.wireReceiverEmail)) {
      return toast.current.show({ severity: "error", summary: "Invalid email Address", life: 8000 })
    }

    const wire = trimObjectValues({
      //amount: values.amount,
      role: values.role,
      wireReceiverEmail: values.wireReceiverEmail,
      transactionId,
      //purpose: values.purpose,
      ...(values.purpose === "Other" ? { otherPurpose: values.otherPurpose ?? null } : { otherPurpose: null }),
      receiver: values.receiver || null,
      aba: values.aba || null,
      fbo: values.fbo || null,
      accountNumber: values.accountNumber || null,
      contact: values.contact,
      contactName: values.contactName,
      contactPhone: values.contactPhone,
      references: values.references || null,
      otherComments: values.otherComments || null,
      wirePurposes: wirePurposes.filter((wire) => wire.amount > 0),
      fundApplicationInstructions: values.fundApplicationInstructions || null,
    })

    setNewWire(wire)
  }
  const header = { Authorization: `bearer ${token}` }

  const createWire = () => {
    setIsLoading(true)
    wiresService
      .createWire(header, newWire)
      .then(() => {
        onWireCreated()
        toast.current.show({
          severity: "success",
          summary: "Wire created",
          detail: `Wire created for recipient: ${newWire.wireReceiverEmail}`,
          sticky: true,
        })
      })
      .catch((error) => {
        console.error(error)
        toast.current.show({ severity: "error", summary: "Can't create the wire", detail: error.response.data, sticky: true })
      })
      .finally(() => setIsLoading(false))
  }

  const onError = (errors) => {
    const firstErrorName = Object.keys(errors)[0]
    setFocus(firstErrorName)
  }

  const addAnother = () => {
    setWirePurposes([...wirePurposes, { amount: null, purpose: "" }])
  }
  const removePurpose = (i) => {
    var wrs = wirePurposes.filter((_, index) => index !== i)
    setWirePurposes(wrs)
  }

  const handleWireAmountChange = (amount, index) => {
    var wrs = wirePurposes
    wrs[index].amount = amount
    setWirePurposes(wrs)
  }

  const handleWirePurposeChange = (purpose, index) => {
    var wrs = wirePurposes
    wrs[index].purpose = purpose
    setWirePurposes(wrs)
  }
  const [wireReceiverEmailState, setWireReceiverEmailState] = useState({ state: "", icon: "", class: "", parentClass: "" })
  const validateEmail = async (email) => {
    try {
      if (emailRegex.test(email)) {
        setWireReceiverEmailState({ state: "loading", icon: loaderc, class: "plus-icon", parentClass: "success success-absolute" })
        const user = await userService.search(header, email)
        if (user) {
          setWireReceiverEmailState({ state: "ok", icon: plusicon, class: "plus-icon", parentClass: "success success-absolute" })
        }
      } else {
        setWireReceiverEmailState({ state: "", icon: "", class: "", parentClass: "" })
      }
    } catch (error) {
      setWireReceiverEmailState({ state: "error", icon: erroricon, class: "error-icon", parentClass: "error error-absolute" })
    }
  }
  const custm_grid = wirePurposes.length == 1 ? "field col-12 md:col-6" : "field col-12 md:col-5"
  return (
    <div>
      {newWire && <WireSummary newWire={newWire} createWire={createWire} setIsLoading={setIsLoading} setNewWire={setNewWire} isLoading={isLoading} />}

      <form onSubmit={handleSubmit(onSubmit, onError)} className="p-fluid formgrid grid">
        <Panel headerTemplate={recipientDetailssHeaderTemplate} toggleable className="col-12">
          <div className="col-12 p-fluid formgrid grid form-content-block">
            <div className="field col-12 md:col-6">
              <label htmlFor="wireReceiverEmail" style={{ fontSize: "14px", fontWeight: "500" }}>
                Recipient Email ID
              </label>
              <Controller
                name="wireReceiverEmail"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                  pattern: {
                    value: emailRegex,
                    message: "Invalid Email format",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <InputText
                      id={field.name}
                      className={fieldState.error ? "input-error" : "input-correct"}
                      type="text"
                      placeholder="Wire Recipient Official Email ID"
                      value={field.value}
                      onChange={async (e) => {
                        field.onChange(e.target.value.trimStart())
                        await validateEmail(e.target.value.trimStart())
                      }}
                    />
                    <span className={wireReceiverEmailState.parentClass}>
                      <img src={wireReceiverEmailState.icon} style={{ maxWidth: "20px" }} className={wireReceiverEmailState.class} />
                    </span>
                    <br />
                    {fieldState.error && <span className="p-error absolute">{fieldState?.error?.message}</span>}
                  </div>
                )}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="role" style={{ fontSize: "14px", fontWeight: "500" }}>
                Recipient Role
              </label>
              <Controller
                name="role"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <Dropdown id={field.name} className={fieldState.error ? "input-error" : "input-correct"} value={field.value} onChange={(e) => field.onChange(e.value)} options={roleOptions} optionLabel="name" optionValue="name" placeholder="Select Recipient Role" />
                    <br />
                    {/* Hidden input for getting focus on submit when there's an error with the field.
          The inputRef does not work on Dropdown component with the ref from the field argument from React Hook Form
          (it works with the InputNumber though) */}
                    <div style={{ position: "absolute", top: 0, width: 0, overflow: "hidden" }}>
                      <input style={{ maxHeight: 0, width: 0, position: "absolute", top: 0, opacity: 0 }} ref={field.ref} tabIndex="-1" />
                    </div>
                    {fieldState.error && <span className="p-error absolute bottom-0">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="receiver" style={{ fontSize: "14px", fontWeight: "500" }}>
                Recipient Bank Name
              </label>
              <Controller
                name="receiver"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
                render={({ field, fieldState }) => (
                  <>
                    <div className="relative">
                      <InputText id={field.name} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} className={fieldState.error ? "input-error" : "input-correct"} placeholder="Recipient Bank Name" />
                      <br />
                    </div>
                    {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                  </>
                )}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="accountNumber" style={{ fontSize: "14px", fontWeight: "500" }}>
                Recipient Account Number
              </label>
              <Controller
                name="accountNumber"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                  minLength: {
                    value: 8,
                    message: "Account number should have 8 to 12 digits",
                  },
                  maxLength: {
                    value: 12,
                    message: "Account number should have 8 to 12 digits",
                  },
                }}
                render={({ field, fieldState }) => (
                  <>
                    <div className="relative">
                      <InputText id={field.name} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.slice(0, 12))} className={fieldState.error ? "input-error" : "input-correct"} placeholder="XXXX XXXX XXXX XXXX" keyfilter="num" />
                      <br />
                    </div>
                    {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                  </>
                )}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="aba" style={{ fontSize: "14px", fontWeight: "500" }}>
                ABA Number
              </label>
              <Controller
                name="aba"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                  minLength: {
                    value: 9,
                    message: "ABA number should have 9 digits",
                  },
                  maxLength: {
                    value: 9,
                    message: "ABA number should have 9 digits",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <InputText id={field.name} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.slice(0, 9))} className={fieldState.error ? "input-error" : "input-correct"} placeholder="Bank ABA Number" keyfilter="num" />
                    <br />
                    {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="fbo" style={{ fontSize: "14px", fontWeight: "500" }}>
                Account Name
              </label>
              <Controller
                name="fbo"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="relative">
                    <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Account Name of the Recipient" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                    <br />
                    {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                  </div>
                )}
              />
            </div>
          </div>
        </Panel>

        <Panel headerTemplate={wiretDetailssHeaderTemplate} className="col-12 amount-panel">
          <div>
            {wirePurposes &&
              wirePurposes.map((wire, i) => (
                <div className="col-12 p-fluid formgrid grid form-content-block " style={{ width: "100%", alignItems: "center" }}>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="amount" style={{ fontSize: "14px", fontWeight: "500" }}>
                      Wire Amount
                    </label>
                    <Controller
                      name="amount"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Required field",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div className="relative">
                          <InputNumber
                            inputRef={field.ref}
                            id={field.name}
                            value={wire.amount}
                            onChange={(e) => {
                              field.onChange(e.value)
                              handleWireAmountChange(e.value, i)
                            }}
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            inputClassName={fieldState.error ? "input-error" : "input-correct"}
                            placeholder="$"
                          />
                          <br />
                          {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                        </div>
                      )}
                    />
                  </div>

                  <div className={custm_grid}>
                    <label htmlFor="amount" style={{ fontSize: "14px", fontWeight: "500" }}>
                      Purpose
                    </label>
                    <Controller
                      name="purpose"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Required field",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div className="relative">
                          <InputText
                            inputRef={field.ref}
                            id={field.name}
                            value={wire.purpose}
                            onChange={(e) => {
                              field.onChange(e.target.value)
                              handleWirePurposeChange(e.target.value, i)
                            }}
                            className={fieldState.error ? "input-error" : "input-correct"}
                            placeholder="Enter Purpose"
                          />
                          <br />
                          {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                        </div>
                      )}
                    />
                  </div>
                  {/* <div className="remove-wire-button-container"> */}
                  {wirePurposes.length > 1 && (
                    <Button
                      style={{ borderRadius: 100, width: 24, height: 24, paddingLeft: 8, backgroundColor: "#D92D20", borderWidth: 0, justifyContent: "center" }}
                      className="remove-wire-button"
                      icon="pi pi-minus"
                      type="button"
                      disabled={wirePurposes.length < 2}
                      onClick={() => {
                        removePurpose(i)
                      }}
                    ></Button>
                  )}
                  {/* </div> */}
                </div>
              ))}
            <div className="field col-12" style={{ marginBottom: "0" }}>
              <Button
                type="button"
                style={{ float: "right", marginTop: "10px", width: "auto", backgroundColor: "white", color: "#000F66", borderWidth: 0, fontSize: 14, fontWeight: "600px" }}
                onClick={(e) => {
                  e.stopPropagation()
                  addAnother()
                }}
              >
                <img src={buttonPlusImage} style={{ height: 23, width: 23, marginRight: 2 }}></img>
                Add Another
              </Button>
            </div>
          </div>
        </Panel>

        {/* <div className="field col-12 md:col-6">
        
        </div> */}
        {/* 
        <div className="field col-12 md:col-6" style={{ cursor: purpose === "Other" ? "auto" : "not-allowed" }}>
           <label htmlFor="otherPurpose" className={purpose === "Other" ? "text-color" : "text-400"}>
            Other Purpose
          </label>
          <Controller
            name="otherPurpose"
            control={control}
            rules={{
              required: {
                value: purpose === "Other",
                message: "Required field",
              },
            }}
            render={({ field, fieldState }) => (
              <div className="relative">
                <InputText disabled={purpose !== "Other"} id={field.name} ref={field.ref} className={fieldState.error ? "input-error" : "input-correct"} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                <br />
                {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
              </div>
            )}
          /> 
        </div> */}

        <Panel headerTemplate={remarksHeaderTemplate} toggleable className="col-12" collapsed={false}>
          <div className="col-12 p-fluid formgrid grid form-content-block">
            {/* <div className="field col-12">
              <label htmlFor="references">References (optional)</label>
              <Controller
                name="references"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <InputTextarea id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                    <br />
                    {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                  </>
                )}
              />
            </div> */}

            <div className="field col-12">
              <label htmlFor="fundApplicationInstructions" style={{ fontSize: "14px", fontWeight: "500" }}>
                Instructions regarding application of funds received (optional)
              </label>
              <Controller
                name="fundApplicationInstructions"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <InputTextarea id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" rows={5} style={{ resize: "none" }} value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                    <br />
                    {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                  </>
                )}
              />
            </div>

            {/* <div className="field col-12">
              <label htmlFor="otherComments">Other Comments (optional)</label>
              <Controller
                name="otherComments"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <InputTextarea id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                    <br />
                    {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                  </>
                )}
              />
            </div> */}
          </div>
        </Panel>

        <div className="field col-12 md:col-12 form-bottom-section" style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <a style={{color: "#000F66", fontWeight:"bold"}}>Save as Draft</a> */}
          <span style={{ paddingLeft: 2, fontWeight: "bold", marginTop: 13 }}>
            Total:{" "}
            <span style={{ color: "#2563EB", fontSize: "16px" }}>
              {getCurrencyString(
                wirePurposes.reduce((accumulator, currWire) => {
                  return accumulator + currWire.amount
                }, 0)
              )}
            </span>
          </span>
          <div>
            <Button label="Save as Draft" id="save-draft" style={{ width: "130px", border: "none", background: "transparent", textDecoration: "underline", color: "#000F66" }} />
            <Button loading={isLoading} label="Next: Summary" id="register-button" style={{ width: "153px", borderRadius: 4 }} />
          </div>
        </div>
      </form>
    </div>
  )
}

export default WireNew
