import axios from "providers/ConfigureAxios"
import { getUrl } from "service/config/serviceUrls"

export default class StatisticsService {
  getActiveUsers(headers) {
    return axios({
      method: "get",
      url: `${getUrl()}/statistics/active-users`,
      headers
    })
  }
}