import { useEffect, useState } from "react"
import { useToast } from "./useToast"

export const useInvitation = (handleAccept, handleReject, fetchInvitation) => {
  const [isFetchingInvitation, setIsFetchingInvitation] = useState(true)
  const [invitationData, setInvitationData] = useState(null)
  const [acceptingInvitation, setAcceptingInvitation] = useState(false)
  const [rejectingInvitation, setRejectingInvitation] = useState(false)
  const [invitationStatus, setInvitationStatus] = useState(null)

  const toast = useToast()

  useEffect(() => {
    const handleFetchInvitation = async () => {
      try {        
        if (isFetchingInvitation && !invitationData) {
          setIsFetchingInvitation(true)
          const { data } = await fetchInvitation()
          setInvitationData(data)
          setInvitationStatus(data.status ?? "Invited")
        }
      } catch (error) {
        console.error(error)
        setInvitationStatus("Error")
        if (error.response.status === 403) {
          toast.current.replace({ severity: "error", summary: "Forbidden", detail: error.response.data, sticky: true })
        }
        if (error.response.status === 404) {
          toast.current.replace({ severity: "error", summary: "Invitation not found", sticky: true })
        }
      } finally {
        setIsFetchingInvitation(false)
      }
    }

    handleFetchInvitation()
  }, [fetchInvitation, invitationData, isFetchingInvitation, toast])

  const acceptInvitation = () => {
    setAcceptingInvitation(true)
    handleAccept()
    .then(() => setInvitationStatus("Accepted"))
    .catch((error) => {
      setInvitationStatus("Error")
      if (error.response.status === 400) {
        // In the development environment, the application is mounted twice on every render (see https://legacy.reactjs.org/docs/strict-mode.html)
        // This causes a duplicated network request for fetching the invitation.
        // But this doesn't happen on production, because Strict Mode is disabled in the build code.
        // We use .replace method to avoid displaying a duplicated toast in development.
        toast.current.replace({ severity: "error", summary: "Can't accept invitation", detail: error.response.data, sticky: true })
      }
      if (error.response.status === 403) {
        toast.current.replace({ severity: "error", summary: "Forbidden", detail: error.response.data, sticky: true })
      }
    })
    .finally(() => setAcceptingInvitation(false))
  }

  const rejectInvitation = () => {
    setRejectingInvitation(true)
    handleReject()
    .then(() => setInvitationStatus("Rejected"))
    .catch((error) => {
      console.error(error)
      setInvitationStatus("Error")
    })
    .finally(() => setRejectingInvitation(false))
  }

  return {
    isFetchingInvitation,
    invitationData,
    acceptingInvitation,
    rejectingInvitation,
    invitationStatus,
    acceptInvitation,
    rejectInvitation
  }
}