import { useCallback, useEffect, useMemo, useState } from "react"

import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import UserStorage from "storage/userStorage"
import axios from "axios"
import { getUrl } from "service/config/serviceUrls"
import jwt_decode from "jwt-decode"
import { useNavigate } from "react-router-dom"

const axiosInstance = axios.create({
  baseURL: `${getUrl()}`,
})

const ConfigureAxios = ({ children }) => {
  const [dialogMessage, setDialogMessage] = useState(null)
  const [displayDialog, setDisplayDialog] = useState(false)

  const navigate = useNavigate()

  const userStorage = useMemo(() => new UserStorage(), [])

  const responseInterceptor = (response) => response

  const errorInterceptor = useCallback(
    (error) => {
      if (error.response?.status === 401) {
        const token = userStorage.getToken()

        if (token) {
          const decodedToken = jwt_decode(token)
          // Convert token expiration date in seconds to milliseconds
          const tokenExpireDate = decodedToken.exp * 1000
          // Number of milliseconds in one minute
          const ONE_MINUTE = 1000 * 60

          // If token expired at least one minute ago, display a dialog for
          // clearing the user storage and refreshing the page.
          // This takes the user to the login page (see AppWrapper)
          if (tokenExpireDate <= Date.now() - ONE_MINUTE) {
            setDialogMessage("Your session expired")
            setDisplayDialog(true)
          }
        }
      }

      if (error.response?.status === 403 && error.response?.data === "User inactive or blocked") {
        setDialogMessage("Your account has been marked as Inactive or Blocked.")
        setDisplayDialog(true)
      }

      return Promise.reject(error)
    },
    [userStorage]
  )

  useEffect(() => {
    const interceptorsId = axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor)

    return () => axiosInstance.interceptors.response.eject(interceptorsId)
  }, [errorInterceptor])

  const confirmationDialogFooter = (
    <div>
      <Button
        label="Go to login"
        icon="pi pi-check"
        onClick={() => {
          userStorage.clear()
          navigate(0)
          setDisplayDialog(false)
        }}
      />
    </div>
  )

  return (
    <>
      <Dialog visible={displayDialog} closable={false} modal footer={confirmationDialogFooter}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          <span>{dialogMessage}</span>
        </div>
      </Dialog>
      {children}
    </>
  )
}

export default axiosInstance
export { ConfigureAxios }
