import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { useState } from "react"
import { getCurrencyString } from "utilities/format"

const WireSummary = ({ newWire: newWireParam, createWire, setIsLoading, setNewWire, isLoading }) => {
  const newWire = newWireParam
  newWire.wirePurposes = newWire.wirePurposes.filter((wire) => wire.amount > 0)

  const getTotalWireAmout = () => {
    let total = 0
    newWire.wirePurposes.forEach((element) => {
      total += element.amount
    })
    return getCurrencyString(total)
  }
  const getPurposes = () => {
    return (
      <>
        {newWire.wirePurposes.map((wire) => {
          return (
            <span className="row-confirm-wire-purpose">
              {getCurrencyString(wire.amount)} - {wire.purpose}
            </span>
          )
        })}
      </>
    )
  }
  const header = (
    <>
      <l style={{ color: "#667085" }}>New Wire </l>
      <i class="p-row-toggler-icon pi pi-chevron-right" style={{ color: "#667085" }}></i>
      <span style={{ color: "#101828" }}> Summary</span>
    </>
  )

  return (
    <Dialog
      // className="confirmNewWire"
      visible={!!newWire}
      style={{ width: 550 }}
      headerStyle={{ height: 56, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}
      footerStyle={{ paddingBottom: "0", background: "#FCFCFD" }}
      header={header}
      footer={() => {
        return (
          <div className="flex justify-content-between new-wire-confirm-buttons">
            <Button
              onClick={(e) => {
                setNewWire(null)
                setIsLoading(false)
              }}
              style={{ width: "50%" }}
              className="new-wire-confirm-edit border-round-sm"
              disabled={isLoading}
            >
              Back to Edit
            </Button>
            <Button
              onClick={(e) => {
                createWire()
              }}
              style={{ width: "50%" }}
              disabled={isLoading}
              loading={isLoading}
              className="new-wire-confirm-submit border-round-sm"
            >
              Submit
            </Button>
          </div>
        )
      }}
      onHide={() => {
        setNewWire(null)
        setIsLoading(false)
      }}
    >
      <div style={{ paddingLeft: "28px", paddingRight: "28px" }}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 10 }}>
          <div className="row-2 mb-2" id="row-principal-confirm-wire">
            <label className="new-confirm-wire-label">ABA Number</label>
            <span className="new-confirm-wire-value-1">{newWire.aba}</span>
          </div>
          <div className="row-2 mb-2" id="row-principal-confirm-wire">
            <label className="new-confirm-wire-label">Account Name</label>
            <span className="new-confirm-wire-value-1">{newWire.fbo}</span>
          </div>
          <div className="row-2 mb-2" id="row-principal-confirm-wire">
            <label className="new-confirm-wire-label">Account Number</label>
            <span className="new-confirm-wire-value-1">{newWire.accountNumber}</span>
          </div>
          <div className="row-2 mb-2" id="row-principal-confirm-wire">
            <label className="new-confirm-wire-label">Wire Amount</label>
            <span className="new-confirm-wire-value-1">{getTotalWireAmout()}</span>
          </div>
          <div className="row-confirm-wire-purpose-content pt-3 pb-3" style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 10, marginBottom: 10, gap: 36 }}>
            <span className="new-confirm-wire-label-purpose">Purpose</span>
            <span className="row-confirm-wire-purpose">{getPurposes()}</span>
          </div>
          <div className="row-2  pt-3 pb-3" id="row-confirm-wire">
            <span className="new-confirm-wire-label">Wire Receiver</span>
            <span className="new-confirm-wire-value">{newWire.wireReceiverEmail}</span>
          </div>
          <div className="row-2 pt-3 pb-3" id="row-confirm-wire">
            <span className="new-confirm-wire-label">Recipient Role</span>
            <span className="new-confirm-wire-value">{newWire.role}</span>
          </div>
          <div className="row-2 pt-3 pb-3" id="row-confirm-wire">
            <span className="new-confirm-wire-label">Bank Name</span>
            <span className="new-confirm-wire-value">{newWire.receiver}</span>
          </div>

          <div className="row-2 pt-3 pb-3" id="row-confirm-wire">
            <span className="new-confirm-wire-label">Instructions</span>
            <span className="new-confirm-wire-value">{newWire.fundApplicationInstructions ? newWire.fundApplicationInstructions : "NA"}</span>
          </div>
          {/* <div className="row-2" id='row-confirm-wire'>
                <span className="new-confirm-wire-label">
                Reference
                </span>
                <span className="new-confirm-wire-value">
                {newWire.references}
                </span>
            </div> */}
          {/* <div className="row-2" id='row-confirm-wire'>
                <span className="new-confirm-wire-label">
                Remarks
                </span>
                <span className="new-confirm-wire-value">
                {newWire.otherComments}
                </span>
            </div> */}
        </div>
      </div>
    </Dialog>
  )
}

export default WireSummary
