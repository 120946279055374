import { useCallback, useEffect, useMemo } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Button } from "primereact/button"
import { ProgressSpinner } from "primereact/progressspinner"
import InvitationsService from "service/InvitationsService"
import { useUser } from "hooks/useUser"
import { useInvitation } from "hooks/useInvitation"
import { Card } from "primereact/card"
import { formatDateString, getCurrencyString } from "utilities/format"
import constants from "constants/constants"

const ParticipantInvitation = () => {
  const { token } = useUser()
  const headers = useMemo(() => ({ Authorization: "bearer " + token }), [token])

  const { participantId } = useParams()

  const invitationsService = useMemo(() => new InvitationsService(), [])

  const navigate = useNavigate()

  const fetchInvitation = useCallback(() => invitationsService.getParticipantInvite(headers, participantId), [headers, invitationsService, participantId])

  const handleAccept = () => invitationsService.resolveParticipantInvite(headers, participantId, "Accepted")

  const handleReject = () => invitationsService.resolveParticipantInvite(headers, participantId, "Rejected")

  const { isFetchingInvitation, invitationData, acceptingInvitation, rejectingInvitation, invitationStatus, acceptInvitation, rejectInvitation } = useInvitation(handleAccept, handleReject, fetchInvitation)

  useEffect(() => {
    if (invitationStatus === "Error") {
      navigate("/")
    }

    let timeout = null
    if (invitationStatus === constants.PARTICIPANT_STATUS.ACCEPTED || invitationStatus === constants.PARTICIPANT_STATUS.REJECTED || invitationStatus === constants.PARTICIPANT_STATUS.CANCELED) {
      timeout = setTimeout(() => {
        navigate("/")
      }, 10000)
    }

    return () => clearTimeout(timeout)
  }, [invitationStatus, navigate])

  const invitationFooter = (
    <>
      <Button loading={acceptingInvitation} disabled={rejectingInvitation} label="Accept" className="mr-2" onClick={acceptInvitation} />
      <Button loading={rejectingInvitation} disabled={acceptingInvitation} label="Reject" onClick={rejectInvitation} />
    </>
  )

  const resolvedInvitationFooter = (
    <p>
      If you are not redirected automatically, <Link to="/">click here</Link>.
    </p>
  )

  return (
    <>
      {isFetchingInvitation ? (
        <ProgressSpinner />
      ) : (
        <div className="flex justify-content-center">
          {invitationStatus === "Accepted" && (
            <Card className="text-xl p-4" style={{ width: "780px" }} title="Invitation" subTitle={`The invitation was accepted`} footer={resolvedInvitationFooter}>
              You are now being redirected to the home page.
            </Card>
          )}
          {invitationStatus === "Rejected" && (
            <Card className="text-xl p-4" style={{ width: "780px" }} title="Invitation" subTitle={`The invitation was rejected`} footer={resolvedInvitationFooter}>
              You are now being redirected to the home page.
            </Card>
          )}
          {invitationStatus === "Canceled" && (
            <Card className="text-xl p-4" style={{ width: "780px" }} title="Invitation" subTitle={`The invitation was canceled`} footer={resolvedInvitationFooter}>
              You are now being redirected to the home page.
            </Card>
          )}
          {invitationStatus === "Invited" && invitationData && (
            <Card
              className="text-xl p-4"
              style={{ width: "780px" }}
              title="Invitation"
              subTitle={`You have been invited by ${invitationData.invitedByUserName}, from the Organization / Company ${invitationData.invitedByOrgName}, to take the role of ${invitationData.role} in the following transaction:`}
              footer={invitationFooter}
            >
              <ul className="list-none p-0 mt-0">
                <li>
                  <span className="font-bold">Name: </span>
                  {invitationData.transactionName}
                </li>
                <li>
                  <span className="font-bold">Amount: </span>
                  {getCurrencyString(invitationData.transactionAmount)}
                </li>
                <li>
                  <span className="font-bold">Issuer: </span>
                  {invitationData.transactionIssuer}
                </li>
                <li>
                  <span className="font-bold">Method of Sale: </span>
                  {invitationData.transactionType}
                </li>
                <li>
                  <span className="font-bold">Closing date: </span>
                  {formatDateString(invitationData.transactionClosingDate)}
                </li>
              </ul>
            </Card>
          )}
        </div>
      )}
    </>
  )
}

export default ParticipantInvitation
