import { useState, useEffect, useCallback, useMemo } from "react"
import { useParams } from "react-router-dom"
import { Dialog } from "primereact/dialog"
import { Panel } from "primereact/panel"
import { ProgressSpinner } from "primereact/progressspinner"
import { Tag } from "primereact/tag"
import TransactionWiresGrid from "./TransactionWiresGrid"
import TransactionParticipantsGrid from "./TransactionParticipantsGrid"
import TransactionWireSenderInfo from "components/Transactions/TransactionView/TransactionWireSenderInfo"
import TransactionManagerInfo from "components/Transactions/TransactionView/TransactionManagerInfo"
import TransactionEdit from "components/Transactions/TransactionView/TransactionEdit"
import TransactionViewHeader from "components/Transactions/TransactionView/TransactionViewHeader"
import TransactionGeneralInfo from "components/Transactions/TransactionView/TransactionViewGeneralInfo"
import ParticipantService from "service/ParticipantsService"
import TransactionService from "service/TransactionService"
import WiresService from "service/WiresService"
import { useUser } from "hooks/useUser"
import { useTransactionInvitationUpdatesSocket } from "hooks/useTransactionInvitationUpdatesSocket"
import { useAccountDetailsUpdatesSocket } from "hooks/useAccountDetailsUpdatesSocket"
import "styles/transactionView.scss"
import { TabView, TabPanel } from "primereact/tabview"
import { getCurrencyString } from "utilities/format"
import constants from "constants/constants"
import pencilIcon from "components/Icons/Pencil"
import { useNavigate } from "react-router-dom"
import { Button } from "primereact/button"
import WireNew from "components/Transactions/TransactionView/WireActions/WireNew"
// import noFilesImage from "../../../assets/images/Filesempty.png"
import noFilesImage from "assets/images/No-wire.svg"
import ParticipantNew from "./ParticipantNew"
import wireDeialsPng from "./../../../assets/WireDetails.png"
// import iconParticipants from "./../../../assets/IconparticipantsIcon.png"

import iconParticipants from "assets/images/participant_icon.svg"
import ShareInternally from "./ShareInternally"
import { Password } from "primereact/password"

const TransactionView = () => {
  const [loadingTransactionDetails, setLoadingTransactionDetails] = useState(true)
  const [loadingTRManager, setLoadingTRManager] = useState(true)
  const [loadingWireSender, setLoadingWireSender] = useState(true)
  const [loadingWires, setLoadingWires] = useState(true)
  const [loadingParticipants, setLoadingParticipants] = useState(true)
  const [transaction, setTransaction] = useState(null)
  const [wireSender, setWireSender] = useState(null)
  const [wires, setWires] = useState([])
  const [participants, setParticipants] = useState([])
  const [cantParticipants, setCantParticipants] = useState(0)
  const [displayeditForm, setDisplayEditForm] = useState(false)
  const [trManager, setTRManager] = useState(null)
  const [activeIndex, setActiveIndex] = useState(1)
  const [openNewWireForm, setOpenNewWireForm] = useState(false)
  const [openNewParticipantForm, setOpenNewParticipantForm] = useState(false)
  const [openShareInternallyForm, setOpenShareInternallyForm] = useState(false)
  const [internalOnly, setInternalOnly] = useState(false)
  const { transactionId } = useParams()
  const transactionService = useMemo(() => new TransactionService(), [])
  const participantService = useMemo(() => new ParticipantService(), [])
  const wireService = useMemo(() => new WiresService(), [])
  const { token } = useUser()
  const navigate = useNavigate()
  const handleBack = () => {
    navigate(-1) // Retrocede una página en el historial
  }

  const loadTransaction = useCallback(
    (fromReload) => {
      if (!fromReload) {
        setLoadingTransactionDetails(true)
      }
      const headers = { Authorization: `bearer ${token}` }
      transactionService.getTransaction(headers, transactionId).then(({ data }) => {
        setTransaction(data)
        setLoadingTransactionDetails(false)
      })
    },
    [token, transactionId, transactionService]
  )
  const loadParticipants = useCallback(() => {
    setLoadingParticipants(true)
    setLoadingTRManager(true)
    setLoadingWireSender(true)
    const header = { Authorization: `bearer ${token}` }
    participantService
      .getParticipantsInTransaction(header, transactionId, 1)
      .then(({ data }) => {
        const otherParticipants = Array.from(data).filter((s) => s.role !== constants.TRANSACTION_ROLES.WIRE_SENDER)
        const transactionManager = Array.from(data).find((s) => s.role === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER)
        const wireSender = Array.from(data).find((s) => s.role === constants.TRANSACTION_ROLES.WIRE_SENDER)
        setParticipants(otherParticipants)
        setCantParticipants(otherParticipants.length)
        setTRManager(transactionManager)
        setWireSender(wireSender)
      })
      .finally(() => {
        setLoadingParticipants(false)
        setLoadingTRManager(false)
        setLoadingWireSender(false)
      })
  }, [participantService, token, transactionId])
  const loadWires = useCallback(() => {
    setLoadingWires(true)
    const header = { Authorization: `bearer ${token}` }
    wireService
      .getWiresInTransaction(header, transactionId)
      .then(({ data: wires }) => {
        const orderedWires = Array.from(wires).sort(({ createdOn: d1 }, { createdOn: d2 }) => new Date(d2).getTime() - new Date(d1).getTime())
        setWires(orderedWires)
      })
      .finally(() => setLoadingWires(false))
  }, [token, transactionId, wireService])
  const loadTransactionTables = () => Promise.all([loadParticipants(), loadWires()])
  useEffect(() => {
    loadTransaction()
    loadWires()
    loadParticipants()
  }, [loadParticipants, loadTransaction, loadWires])
  const onTransactionInvitationsUpdated = useCallback(
    (updateType) => {
      if (updateType === "WiresUpdated") {
        loadWires()
      } else if (updateType === "ParticipantsUpdated") {
        loadParticipants()
      }
    },
    [loadParticipants, loadWires]
  )
  const onAccountDetailsUpdated = useCallback(
    (updateType) => {
      if (updateType === "AccountDetailsUpdated") {
        loadWires()
      }
    },
    [loadWires]
  )
  const getWairesAmountTotal = () => {
    return wires.reduce((acc, wire) => acc + wire.amount, 0)
  }
  const getTotalCantWires = () => {
    return wires.length
  }
  useTransactionInvitationUpdatesSocket(onTransactionInvitationsUpdated, transactionId)
  useAccountDetailsUpdatesSocket(onAccountDetailsUpdated, transactionId)
  const onTransactionEdited = () => {
    loadTransaction()
    setDisplayEditForm(false)
  }
  const customHeader = () => {
    return (
      <div className="p-panel-header flex align-items-center h-5rem">
        <span className="p-panel-title">
          <h3 className="m-0">General Information</h3>
        </span>
        {transaction && transaction.roleInTransaction === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER && transaction.status === "Open" && (
          <Button
            icon={<i className="flex mr-1">{pencilIcon}</i>}
            label="Edit"
            title="Edit the transaction information"
            tooltip="Edit the transaction information"
            tooltipOptions={{ position: "left" }}
            className="p-button-action p-button-raised"
            style={{ marginRight: ".5em" }}
            onClick={() => setDisplayEditForm(true)}
          />
        )}
      </div>
    )
  }

  const statusTemplate = (status) => {
    const statusColor = status === constants.TRANSACTION_STATUS.CLOSED ? "success" : "info"
    const icon = `pi pi-fw ${status === constants.TRANSACTION_STATUS.CLOSED ? "pi-check" : "pi-sort-alt"}`
    return <Tag severity={statusColor} icon={icon} value={status} />
  }
  const AddWire = () => {
    return wires.length == 0 ? (
      <div className="transaction-view-container-image-empty">
        <img className="transaction-view-image-empty" src={noFilesImage} alt="desc" />
        <p style={{ marginBottom: "5px" }}>No wire added yet.</p>
        <Button onClick={() => setOpenNewWireForm(true)} label="Add Wire" className="transaction-view-button-add" icon="pi pi-plus" style={{ padding: "0.8rem 2rem", borderRadius: "8px" }} />
      </div>
    ) : (
      <></>
    )
  }
  const onWireCreated = () => {
    setOpenNewWireForm(false)
    loadTransactionTables()
  }
  const onParticipantAdded = (rowData) => {
    setOpenNewParticipantForm(false)
    loadParticipants()
  }
  console.log(transaction)
  if (loadingTransactionDetails || loadingWires) return <ProgressSpinner />
  const options = { day: "numeric", month: "short", year: "numeric" }
  return (
    <div style={{ width: "100%", justifyContent: "center" }} className="transaction-view-content">
      <div className="transaction-header-section">
        <div className="transaction-view-tab-container padded-content" style={{ flexDirection: "row", width: "100%", height: "69px", flexShrink: 0, justifyContent: "space-between", borderBottom: "0" }}>
          <Button onClick={handleBack} style={{ backgroundColor: "transparent", color: "#000F66", borderWidth: 0, fontWeight: "600", fontSize: "14px" }} icon="pi pi-angle-left" iconPos="left">
            <div className="hover:underline">Back to All Transactions</div>
          </Button>
          <div style={{ display: "flex", backgroundColor: "#F3F4F6", padding: 6, borderRadius: 8, alignItems: "center", justifyContent: "center" }}>
            <Button
              onClick={() => setActiveIndex(1)}
              style={{ opacity: activeIndex == 2 && 0.5, boxShadow: activeIndex == 1 && "0px 2px 3px 0px rgba(16, 24, 40, 0.05)", backgroundColor: activeIndex == 1 ? "white" : "transparent", color: "#000F66", borderWidth: 0, borderRadius: 4, fontSize: "16px", fontWeight: "500" }}
              iconPos="left"
            >
              <img src={wireDeialsPng} style={{ width: 15, height: 15, backgroundColor: "white", marginRight: 4 }} />
              Wire Details
            </Button>
            <Button onClick={() => setActiveIndex(2)} style={{ opacity: activeIndex == 1 && 0.5, backgroundColor: activeIndex == 2 ? "white" : "transparent", color: "#000F66", borderWidth: 0, borderRadius: 4, fontSize: "16px", fontWeight: "500" }}>
              <img src={iconParticipants} style={{ height: 15, width: 15, marginRight: 4 }}></img>
              Participants
              {/* <span style={{ padding: '1px 6px',backgroundColor:'#D92D20',borderRadius:50,opacity:0.7,color:'white',marginLeft:7}}>
                  {cantParticipants}
                  </span> */}
            </Button>
          </div>

          <div>
            {transaction.roleInTransaction !== constants.TRANSACTION_ROLES.TRANSACTION_MANAGER ? (
              <Button
                onClick={() => {
                  setOpenShareInternallyForm(true)
                  setInternalOnly(true)
                }}
                icon="pi pi-share-alt"
                style={{ backgroundColor: "#2563EB", marginRight: 8, borderRadius: 4, fontWeight: "bold", borderWidth: 0 }}
              >
                Share Internally
              </Button>
            ) : null}

            {transaction.roleInTransaction === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER ? (
              <Button
                onClick={() => {
                  setOpenNewParticipantForm(true)
                  setInternalOnly(false)
                }}
                icon="pi pi-share-alt"
                disabled={transaction.status == "Deleted" ? true : false}
                style={{ border: "1px solid #2563EB", backgroundColor: "transparent", color: "#013BBC", marginRight: 8, borderRadius: 4, fontWeight: "600", fontSize: "14px" }}
              >
                Add Participants
              </Button>
            ) : null}

            {transaction.roleInTransaction === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER ? (
              <Button onClick={() => setOpenNewWireForm(true)} disabled={transaction.status == "Deleted" ? true : false} icon="pi pi-plus-circle" style={{ backgroundColor: "#000F66", color: "344054", borderRadius: 4, fontWeight: "600", fontSize: "14px", borderWidth: 0 }}>
                New Wire
              </Button>
            ) : null}
          </div>
        </div>
        {transaction && wireSender && (
          <div>
            <div className="grid transaction-table-header" style={{ background: "#F9FAFB", border: "2px solid #eceaf0" }}>
              <div className="col" style={transaction.transactionName.split(" ").length >= 5 ? { minWidth: "17.5%" } : { minWidth: "13.5%" }}>
                <h3>Transaction Name</h3>
                <p>{transaction.transactionName}</p>
              </div>
              <div className="col" style={transaction.issuer.split(" ").length >= 5 ? { minWidth: "15.5%" } : { minWidth: "13.5%" }}>
                <h3>Issuer</h3>
                <p>{transaction.issuer ?? "--"}</p>
              </div>
              <div className="col" style={{ minWidth: "12.5%" }}>
                <h3>Wire Sender</h3>
                <p>{transaction.organizationName ?? "--"}</p>
              </div>
              <div className="col" style={{ minWidth: "12.5%" }}>
                <h3>Transaction Manager</h3>
                <p>{trManager.name}</p>
              </div>
              <div className="col" style={{ minWidth: "9%" }}>
                <h3>Closing Date</h3>
                <p>{new Date(`${transaction.closingDate}Z`).toLocaleDateString(undefined, options)}</p>
              </div>
              <div className="col" style={{ minWidth: "11%" }}>
                <h3>PAR Amount</h3>
                <p>{getCurrencyString(transaction.parAmount)}</p>
              </div>
              <div className="col" style={{ minWidth: "10.5%" }}>
                <h3>Sum of Wires</h3>
                <p>{getCurrencyString(getWairesAmountTotal())}</p>
              </div>
              <div className="col" style={{ minWidth: "6.5%" }}>
                <h3>Total Wires</h3>
                <p>{getTotalCantWires()}</p>
              </div>
            </div>

            {wires.length === 0 && activeIndex === 1 && transaction && (
              <table className="p-datatable-table" style={{ background: "#F9FAFB", width: "100%" }}>
                <thead className="p-datatable-thead">
                  <tr role="row">
                    <th className="" role="columnheader" style={{ padding: "0px", textAlign: "right", width: "1rem" }}>
                      <div className="p-column-header-content">
                        <span className="p-column-title"></span>
                      </div>
                    </th>
                    <th className="col-2" role="columnheader" style={{ width: "15%" }}>
                      <div className="p-column-header-content">
                        <span className="p-column-title">RECIPIENT NAME</span>
                      </div>
                    </th>
                    <th className="" role="columnheader" style={{ width: "15%" }}>
                      <div className="p-column-header-content">
                        <span className="p-column-title">ORGANIZATION</span>
                      </div>
                    </th>
                    <th className="" role="columnheader" style={{ width: "10%" }}>
                      <div className="p-column-header-content">
                        <span className="p-column-title">ROLE</span>
                      </div>
                    </th>
                    <th className="col-2" role="columnheader" style={{ width: "15%" }}>
                      <div className="p-column-header-content">
                        <span className="p-column-title">PURPOSE</span>
                      </div>
                    </th>
                    <th className="" role="columnheader" style={{ backgroundColor: "rgb(242, 244, 247)", minWidth: "380px", textAlign: "right", paddingRight: "20px" }}>
                      <div className="p-column-header-content">
                        <span className="p-column-title">WIRE AMOUNT</span>
                      </div>
                    </th>
                    <th className="" role="columnheader" style={{ textAlign: "left", width: "15%", paddingLeft: "4.5%" }}>
                      <div className="p-column-header-content">
                        <span className="p-column-title">STATUS</span>
                      </div>
                    </th>
                    <th className="" role="columnheader" style={{ width: "15%", paddingRight: "20px" }}>
                      <div className="p-column-header-content">
                        <span className="p-column-title">ACTION</span>
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
            )}
          </div>
        )}
        {/* {transaction && wireSender && activeIndex == 2 &&
          <div className="row transaction-view-header-container padded-content">
            <div className="transaction-view-header">
              <span className="transaction-view-column-name">Transaction Name</span>
              <span className="transaction-view-column-value">{transaction.transactionName}</span>
            </div>
            <div className="transaction-view-header">
              <span className="transaction-view-column-name">Created By</span>
              <span className="transaction-view-column-value">{transaction.createdBy ?? "--"}</span>
            </div>
            <div className="transaction-view-header">
              <span className="transaction-view-column-name">PAR Amount</span>
              <span className="transaction-view-column-value">{getCurrencyString(transaction.parAmount)}</span>
            </div>
            <div className="transaction-view-header">
              <span className="transaction-view-column-name">Closing Date</span>
              <span className="transaction-view-column-value">{new Date(`${transaction.closingDate}Z`).toLocaleDateString()}</span>
            </div>
            <div className="transaction-view-header">
              <span className="transaction-view-column-name">Total Wires</span>
              <span className="transaction-view-column-value">{getTotalCantWires()}</span>
            </div>
          </div>
        } */}
      </div>

      {/* <div style={{backgroundColor:'white'}}> */}
      {activeIndex == 1 && transaction ? (
        <TransactionWiresGrid
          reloadTr={loadTransaction}
          loading={loadingWires}
          setLoadingWires={setLoadingWires}
          transactionId={transactionId}
          transaction={transaction}
          wires={wires}
          loadWires={loadWires}
          roleInTR={transaction.roleInTransaction}
          wireSenderEmail={wireSender?.email}
          loadTransactionTables={loadTransactionTables}
          loadParticipants={loadParticipants}
          parAmount={transaction.parAmount}
        />
      ) : (
        <TransactionParticipantsGrid loading={loadingParticipants} transactionId={transactionId} transactionStatus={transaction.status} roleInTR={transaction.roleInTransaction} participants={participants} loadParticipants={loadParticipants} loadTransactionTables={loadTransactionTables} />
      )}
      {activeIndex == 1 && wires.length == 0 ? <AddWire /> : <></>}

      <div className="col-12">
        <Dialog visible={openNewWireForm} style={{ width: "45vw" }} header="New Wire" headerStyle={{ fontWeight: "bold", height: 56 }} onHide={() => setOpenNewWireForm(false)}>
          <WireNew transactionId={transactionId} onWireCreated={() => onWireCreated()} />
        </Dialog>
        <Dialog visible={openNewParticipantForm} style={{ width: "50vw", maxWidth: 520 }} header="New Participant" onHide={() => setOpenNewParticipantForm(false)}>
          <ParticipantNew transactionId={transactionId} onParticipantAdded={() => onParticipantAdded()} internalOnly={internalOnly} />
        </Dialog>
        <Dialog visible={openShareInternallyForm} style={{ width: "45vw", maxWidth: 520 }} header="Share Internally" onHide={() => setOpenShareInternallyForm(false)}>
          <ShareInternally transactionId={transactionId} onParticipantAdded={() => onParticipantAdded()} internalOnly={internalOnly} setOpenShareInternallyForm={setOpenShareInternallyForm} />
        </Dialog>
      </div>
    </div>
  )
}

export default TransactionView
