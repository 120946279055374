import InputPhone from "components/Inputs/InputPhone"
import constants from "constants/constants"
import { useToast } from "hooks/useToast"
import { useUser } from "hooks/useUser"
import { Button } from "primereact/button"
import { Checkbox } from "primereact/checkbox"
import { ConfirmDialog } from "primereact/confirmdialog"
import { Divider } from "primereact/divider"
import { Dropdown } from "primereact/dropdown"
import { Fieldset } from "primereact/fieldset"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { Tag } from "primereact/tag"
import { Tooltip } from "primereact/tooltip"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import AccountDetailsService from "service/AccountDetailsService"
import { formatPhoneNumber, getCurrencyString, trimObjectValues } from "utilities/format"

const AccountDetailsForm = ({ roleInTR, transactionStatus, wireInfo, wireSenderEmail, onAccountDetailsUpdated, roleCategoryInTR }) => {
  const { control, resetField, handleSubmit, watch, setFocus, setValue } = useForm({
    defaultValues: {
      trManagerApproval: null,
      wireSenderApproval: null,
      wireReceiverApproval: null,
      receiver: "",
      aba: "",
      fbo: "",
      accountNumber: "",
      contact: false,
      contactName: "",
      references: "",
      otherComments: "",
      fundApplicationInstructions: "",
      ...wireInfo.accountDetails,
      contactPhone: formatPhoneNumber(wireInfo?.accountDetails?.contactPhone) || "",
    },
    reValidateMode: "onChange",
  })
  const sameContactChecked = watch("contact")

  const [displayApproveConfirmation, setDisplayApproveConfirmation] = useState(false)
  const [approvingAccountDetailsStatus, setApprovingAccountDetailsStatus] = useState("")
  const [disapprovalReason, setDisapprovalReason] = useState("")
  const [displayEditConfirmation, setDisplayEditConfirmation] = useState(false)
  const [editingAccountDetails, setEditingAccountDetails] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { token, userData } = useUser()
  const toast = useToast()

  const userIsTRManager = wireInfo.transactionManagerEmail === userData.email

  const accountDetailsService = new AccountDetailsService()

  const onConfirmAccountDetailsEditing = () => {
    setDisplayEditConfirmation(false)

    // Set my approval to true and the other ones to false
    // Since I'm editing the Account Details, I obviously approve them
    setValue("trManagerApproval", roleInTR === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER ? "Approved" : null)
    setValue("wireReceiverApproval", roleInTR === constants.TRANSACTION_ROLES.WIRE_RECEIVER ? "Approved" : null)
    // Note that the Wire Sender can't edit the Account Details
    setValue("wireSenderApproval", null)

    setEditingAccountDetails(true)
  }

  const handleApprovalAccept = (status) => {
    setIsLoading(true)

    const headers = {
      Authorization: `bearer ${token}`,
    }

    accountDetailsService
      .approveAccountDetails(headers, wireInfo.accountDetails.id, {
        approveStatus: approvingAccountDetailsStatus || status,
        ...(disapprovalReason !== "" && { disapprovalReason }),
      })
      .then(() =>
        toast.current.show({
          severity: "success",
          summary: `You successfully ${approvingAccountDetailsStatus.toLowerCase()} the Account Details`,
          detail: "The Account Details are considered approved once the TR Manager, the Wire Sender and the Wire Receiver have approved them",
          sticky: true,
        })
      )
      .catch((error) => {
        console.error(error)
        toast.current.show({ severity: "error", summary: `Can't ${approvingAccountDetailsStatus.toLowerCase()} the account details`, detail: error.response.data, sticky: true })
      })
      .finally(() => {
        onAccountDetailsUpdated()
        setIsLoading(false)
      })
  }

  const resetApprovalValues = () => {
    // Toggle back the approval values
    const approvalFieldName = roleInTR === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER ? "trManagerApproval" : roleInTR === constants.TRANSACTION_ROLES.WIRE_SENDER ? "wireSenderApproval" : "wireReceiverApproval"
    const approvalFieldValue = roleInTR === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER ? wireInfo?.accountDetails?.trManagerApproval : roleInTR === constants.TRANSACTION_ROLES.WIRE_SENDER ? wireInfo?.accountDetails?.wireSenderApproval : wireInfo?.accountDetails?.wireReceiverApproval

    setValue(approvalFieldName, approvalFieldValue)
  }

  const handleApprovalReject = () => {
    resetApprovalValues()
    setDisplayApproveConfirmation(false)
    setDisapprovalReason("")
  }

  const onSubmit = (data) => {
    setIsLoading(true)

    const headers = {
      Authorization: `bearer ${token}`,
    }

    const accountDetails = trimObjectValues({
      wireId: wireInfo.id,
      receiver: data.receiver || null,
      aba: data.aba || null,
      fbo: data.fbo || null,
      accountNumber: data.accountNumber || null,
      contact: data?.contact ?? false,
      contactName: data?.contactName || null,
      contactPhone: data?.contactPhone?.replace(/[^\d]/g, "") || null,
      references: data.references || null,
      otherComments: data.otherComments || null,
      fundApplicationInstructions: data.fundApplicationInstructions || null,
    })

    accountDetailsService
      .editAccountDetails(headers, wireInfo.accountDetails.id, accountDetails)
      .then(() => toast.current.show({ severity: "success", summary: "Account details edited successfully", detail: "The other participants will be notified by email", sticky: true }))
      .catch((error) => {
        console.error(error)
        toast.current.show({ severity: "error", summary: "Can't edit the account details", detail: error.response.data, sticky: true })
      })
      .finally(() => {
        setIsLoading(false)
        onAccountDetailsUpdated()
      })
  }

  const onError = (errors) => {
    const firstErrorName = Object.keys(errors)[0]
    setFocus(firstErrorName)
  }

  const accountDetailsApproved = wireInfo.accountDetails?.trManagerApproval === "Approved" && wireInfo.accountDetails?.wireSenderApproval === "Approved" && wireInfo.accountDetails?.wireReceiverApproval === "Approved"
  const formEnabled = transactionStatus === constants.TRANSACTION_STATUS.OPEN && (!wireInfo.accountDetails || (wireInfo.accountDetails && !accountDetailsApproved && editingAccountDetails))

  const showEditButton = [constants.TRANSACTION_ROLES.TRANSACTION_MANAGER, constants.TRANSACTION_ROLES.WIRE_RECEIVER].includes(roleInTR) && transactionStatus != 'Accepted'
  const editButtonDisabled = accountDetailsApproved || transactionStatus === constants.TRANSACTION_STATUS.CLOSED

  const approveConfirmationMessage = () =>
    approvingAccountDetailsStatus === "Approved" ? (
      "You are about to approve the Account Details, are you sure you want to continue?"
    ) : (
      <div className="flex-column">
        <p>You are about to disapprove the Account Details, are you sure you want to continue?</p>
        <div className="field">
          <label htmlFor="disapprovalReason">Disapproval reason</label>
          <InputTextarea id="disapprovalReason" className="w-full" value={disapprovalReason} onChange={(e) => setDisapprovalReason(e.target.value)} />
        </div>
      </div>
    )

  return (
    <>
      <ConfirmDialog
        visible={displayEditConfirmation}
        onHide={() => {
          setDisplayEditConfirmation(false)
        }}
        message="Editing this form will reset approvals, are you sure you want to continue?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={onConfirmAccountDetailsEditing}
        reject={() => {
          resetApprovalValues()
          setDisplayEditConfirmation(false)
        }}
      />

      <ConfirmDialog visible={displayApproveConfirmation} onHide={handleApprovalReject} message={approveConfirmationMessage} header="Confirmation" icon="pi pi-exclamation-triangle" accept={handleApprovalAccept} acceptIcon={isLoading ? "pi pi-spin pi-spinner" : ""} reject={handleApprovalReject} />

      <div className="card">
        <form onSubmit={handleSubmit(onSubmit, onError)} className="p-fluid formgrid grid row-gap-3">
          <>
            {/* {!accountDetailsApproved && (
              <div className="field col-12">
                <Fieldset legend="Approvals" className="pt-4 col-12">
                  <div className="grid">
                    <div className="col-4">
                      <Controller
                        name="trManagerApproval"
                        control={control}
                        render={({ field }) => {
                          const disabled = transactionStatus === constants.TRANSACTION_STATUS.CLOSED || editingAccountDetails || roleInTR !== constants.TRANSACTION_ROLES.TRANSACTION_MANAGER
                          const processingApproval = roleInTR === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER && isLoading

                          return (
                            <>
                             <label htmlFor={field.name} className={"text-color"} style={{ cursor: disabled ? "not-allowed" : "pointer" }}>
                                TR Manager {!processingApproval && field.value === "Approved" && <span className="pi pi-check text-green-400" />}
                                {!processingApproval && field.value === "Disapproved" && <span className="pi pi-times text-red-400" />}
                                {processingApproval && <span className="pi pi-spin pi-spinner" />}
                              </label>
                              <div className="field-checkbox">
                                <Dropdown
                                  id={field.name}
                                  value={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.value)
                                    setApprovingAccountDetailsStatus(e.value)
                                    setDisplayApproveConfirmation(true)
                                  }}
                                  disabled={disabled}
                                  options={[
                                    { name: "Approved", value: "Approved" },
                                    { name: "Disapproved", value: "Disapproved" },
                                  ]}
                                  optionLabel="name"
                                  optionValue="value"
                                  placeholder="Select One"
                                />
                              </div>
                            </>
                          )
                        }}
                      />
                    </div>
                    {!(roleInTR === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER && userData.email === wireSenderEmail) && (
                      <div className="col-4">
                        <Controller
                          name="wireSenderApproval"
                          control={control}
                          render={({ field }) => {
                            const disabled = transactionStatus === constants.TRANSACTION_STATUS.CLOSED || editingAccountDetails || roleInTR !== constants.TRANSACTION_ROLES.WIRE_SENDER
                            const processingApproval = roleInTR === constants.TRANSACTION_ROLES.WIRE_SENDER && isLoading

                            return (
                              <>
                                <label htmlFor={field.name} className={"text-color"} style={{ cursor: disabled ? "not-allowed" : "pointer" }}>
                                  Wire Sender {!processingApproval && field.value === "Approved" && <span className="pi pi-check text-green-400" />}
                                  {!processingApproval && field.value === "Disapproved" && <span className="pi pi-times text-red-400" />}
                                  {processingApproval && <span className="pi pi-spin pi-spinner" />}
                                </label>
                                <div className="field-checkbox">
                                  <Dropdown
                                    id={field.name}
                                    value={field.value}
                                    onChange={(e) => {
                                      field.onChange(e.value)
                                      setApprovingAccountDetailsStatus(e.value)
                                      setDisplayApproveConfirmation(true)
                                    }}
                                    disabled={disabled}
                                    options={[
                                      { name: "Approved", value: "Approved" },
                                      { name: "Disapproved", value: "Disapproved" },
                                    ]}
                                    optionLabel="name"
                                    optionValue="value"
                                    placeholder="Select One"
                                  />
                                </div>
                              </>
                            )
                          }}
                        />
                      </div>
                    )}
                    <div className="col-4">
                      <Controller
                        name="wireReceiverApproval"
                        control={control}
                        render={({ field }) => {
                          const disabled = transactionStatus === constants.TRANSACTION_STATUS.CLOSED || editingAccountDetails || roleInTR !== constants.TRANSACTION_ROLES.WIRE_RECEIVER
                          const processingApproval = roleInTR === constants.TRANSACTION_ROLES.WIRE_RECEIVER && isLoading

                          return (
                            <>
                              <label htmlFor={field.name} className={"text-color"} style={{ cursor: disabled ? "not-allowed" : "pointer" }}>
                                Wire Receiver {!processingApproval && field.value === "Approved" && <span className="pi pi-check text-green-400" />}
                                {!processingApproval && field.value === "Disapproved" && <span className="pi pi-times text-red-400" />}
                                {processingApproval && <span className="pi pi-spin pi-spinner" />}
                              </label>
                              <div className="field-checkbox">
                                <Dropdown
                                  id={field.name}
                                  value={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.value)
                                    setApprovingAccountDetailsStatus(e.value)
                                    setDisplayApproveConfirmation(true)
                                  }}
                                  disabled={disabled}
                                  options={[
                                    { name: "Approved", value: "Approved" },
                                    { name: "Disapproved", value: "Disapproved" },
                                  ]}
                                  optionLabel="name"
                                  optionValue="value"
                                  placeholder="Select One"
                                />
                              </div>
                            </>
                          )
                        }}
                      />
                    </div>
                  </div>
                </Fieldset>
              </div>
            )} */}

            {accountDetailsApproved && (
              <div className="col-6">
                <strong>Status: </strong>
                <Tag className="bg-green-400" severity="success" icon="pi pi-check" value="Approved" />
              </div>
            )}

            {/* Only the TR Manager or the Wire Receiver can modify the Account Details */}
            {/* Also, the Account Details are only editable up until they are approved by the TR Manager, Wire Sender and Wire Receiver */}
            {showEditButton && (
              <div className="ml-auto col-6">
                <Tooltip target=".editButtonDisabledTooltip" position="left" />
                <div className={`ml-auto w-fit${editButtonDisabled ? " editButtonDisabledTooltip" : ""}`} data-pr-tooltip={editButtonDisabled ? "Can't edit the account details after they have been approved" : null}>
                  <Button icon="pi pi-pencil" label="Edit" disabled={editingAccountDetails || editButtonDisabled} type="button" onClick={() => setDisplayEditConfirmation(true)} />
                </div>
              </div>
            )}
          </>

          <div className="field col-12 grid">
            <div className="col-12 mb-4">
              <strong>Main Contact: </strong>
              {wireInfo?.accountDetails?.contactName}
            </div>
            <div className="col-6">
              <strong>Wire ID: </strong>
              {wireInfo?.id}
            </div>
            <div className="col-6">
              <strong>Wire Amount: </strong>
              {getCurrencyString(wireInfo?.amount)}
            </div>
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="receiver" className={"text-color"}>
              Fund wires to (Bank Name){userIsTRManager ? "(optional)" : ""}
            </label>
            <Controller
              name="receiver"
              control={control}
              rules={{
                required: {
                  value: formEnabled && !userIsTRManager,
                  message: "Required field",
                },
              }}
              render={({ field, fieldState }) => (
                <div className="relative">
                  <InputText id={field.name} ref={field.ref} disabled={!formEnabled} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Bank Name" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                  <br />
                  {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                </div>
              )}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="aba" className={"text-color"}>
              ABA Number {userIsTRManager ? "(optional)" : ""}
            </label>
            <Controller
              name="aba"
              control={control}
              rules={{
                required: {
                  value: formEnabled && !userIsTRManager,
                  message: "Required field",
                },
                minLength: {
                  value: 9,
                  message: "ABA number should have 9 digits",
                },
                maxLength: {
                  value: 9,
                  message: "ABA number should have 9 digits",
                },
              }}
              render={({ field, fieldState }) => (
                <div className="relative">
                  <InputText id={field.name} ref={field.ref} disabled={!formEnabled} keyfilter="num" type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.slice(0, 9))} className={fieldState.error ? "input-error" : "input-correct"} placeholder="122105278" />
                  <br />
                  {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                </div>
              )}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="fbo" className={"text-color"}>
              FBO / account name {userIsTRManager ? "(optional)" : ""}
            </label>
            <Controller
              name="fbo"
              control={control}
              rules={{
                required: {
                  value: formEnabled && !userIsTRManager,
                  message: "Required field",
                },
              }}
              render={({ field, fieldState }) => (
                <div className="relative">
                  <InputText id={field.name} ref={field.ref} disabled={!formEnabled} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="FBO / account name" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                  <br />
                  {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                </div>
              )}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="accountNumber" className={"text-color"}>
              Account Number {userIsTRManager ? "(optional)" : ""}
            </label>
            <Controller
              name="accountNumber"
              control={control}
              rules={{
                required: {
                  value: formEnabled && !userIsTRManager,
                  message: "Required field",
                },
                minLength: {
                  value: 8,
                  message: "Account number should have 8 to 12 digits",
                },
                maxLength: {
                  value: 12,
                  message: "Account number should have 8 to 12 digits",
                },
              }}
              render={({ field, fieldState }) => (
                <div className="relative">
                  <InputText id={field.name} ref={field.ref} disabled={!formEnabled} value={field.value} type="text" keyfilter="num" onChange={(e) => field.onChange(e.target.value.slice(0, 12))} className={fieldState.error ? "input-error" : "input-correct"} placeholder="6724301068" />
                  <br />
                  {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                </div>
              )}
            />
          </div>

          <Divider />

          <div className="field col-12">
            <span>Contact for Wire Receipt</span>
            <Controller
              name="contact"
              control={control}
              render={({ field }) => (
                <div className="field-checkbox">
                  <Checkbox
                    inputId={field.name}
                    name="contact"
                    disabled={!formEnabled}
                    style={{ cursor: formEnabled ? "pointer" : "not-allowed" }}
                    checked={field.value}
                    onChange={(e) => {
                      resetField("contactName")
                      resetField("contactPhone")

                      setValue("contactName", e.checked ? wireInfo?.accountDetails?.contactName : "")
                      setValue("contactPhone", e.checked ? formatPhoneNumber(wireInfo?.accountDetails?.contactPhone) : "")

                      field.onChange(e.checked ?? false)
                    }}
                  />
                  <label htmlFor="contact" className={"text-color"} style={{ cursor: formEnabled ? "pointer" : "not-allowed" }}>
                    Same as main contact
                  </label>
                </div>
              )}
            />
          </div>

          <div className="field col-12 md:col-6" style={{ cursor: !formEnabled || !sameContactChecked ? "auto" : "not-allowed" }}>
            <label htmlFor="contactName" className={formEnabled && !sameContactChecked ? "text-color" : "text-400"}>
              Contact Name {userIsTRManager ? "(optional)" : ""}
            </label>
            <Controller
              name="contactName"
              control={control}
              rules={{
                required: {
                  value: formEnabled && !sameContactChecked && !userIsTRManager,
                  message: "Required field",
                },
              }}
              render={({ field, fieldState }) => (
                <div className="relative">
                  <InputText
                    disabled={!formEnabled || sameContactChecked}
                    id={field.name}
                    ref={field.ref}
                    className={fieldState.error && !sameContactChecked ? "input-error" : "input-correct"}
                    type="text"
                    placeholder="Contact Name"
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value.trimStart())}
                  />
                  <br />
                  {fieldState.error && !sameContactChecked && <span className="p-error absolute">{fieldState.error.message}</span>}
                </div>
              )}
            />
            <br />
          </div>

          <div className="field col-12 md:col-6" style={{ cursor: !formEnabled || !sameContactChecked ? "auto" : "not-allowed" }}>
            <label htmlFor="contactPhone" className={formEnabled && !sameContactChecked ? "text-color" : "text-400"}>
              Contact Phone {userIsTRManager ? "(optional)" : ""}
            </label>
            <Controller
              name="contactPhone"
              control={control}
              rules={{
                required: {
                  value: formEnabled && !sameContactChecked && !userIsTRManager,
                  message: "Required field",
                },
                minLength: {
                  value: 14,
                  message: "Invalid phone number",
                },
              }}
              render={({ field, fieldState }) => (
                <div className="relative">
                  <InputPhone id={field.name} inputRef={field.ref} disabled={!formEnabled || sameContactChecked} value={field.value} error={fieldState.error} onChange={field.onChange} />
                  <br />
                  {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                </div>
              )}
            />
          </div>

          <div className="field col-12">
            <label htmlFor="references" className={"text-color"}>
              References (optional)
            </label>
            <Controller
              name="references"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "Required field",
                },
              }}
              render={({ field, fieldState }) => (
                <>
                  <InputTextarea id={field.name} disabled={!formEnabled} className={fieldState.error ? "input-error" : "input-correct"} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                  <br />
                  {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                </>
              )}
            />
          </div>

          <div className="field col-12">
            <label htmlFor="fundApplicationInstructions" className={"text-color"}>
              Instructions regarding application of funds received (optional)
            </label>
            <Controller
              name="fundApplicationInstructions"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "Required field",
                },
              }}
              render={({ field, fieldState }) => (
                <>
                  <InputTextarea id={field.name} disabled={!formEnabled} className={fieldState.error ? "input-error" : "input-correct"} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                  <br />
                  {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                </>
              )}
            />
          </div>

          <div className="field col-12">
            <label htmlFor="otherComments" className={"text-color"}>
              Other Comments (optional)
            </label>
            <Controller
              name="otherComments"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "Required field",
                },
              }}
              render={({ field, fieldState }) => (
                <>
                  <InputTextarea id={field.name} disabled={!formEnabled} className={fieldState.error ? "input-error" : "input-correct"} type="text" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                  <br />
                  {fieldState.error && <span className="p-error">{fieldState.error.message}</span>}
                </>
              )}
            />
          </div>

          {formEnabled && (
            <>
              <div className="spacer" />

              <div className="field col-12 md:col-12 registerBottom">
                <div className="field col-12 md:col-5 col-offset-6">
                  <Button loading={isLoading} label="Submit" type="submit" id="register-button" />
                </div>
              </div>
            </>
          )}
             {roleCategoryInTR == "Secondary" && !accountDetailsApproved && (
            <>
              <div className="spacer" />

              <div className="field col-12 md:col-12 registerBottom">
                <div className="field col-12 md:col-5 col-offset-6">
                  <Button loading={isLoading} onClick={() =>handleApprovalAccept('Approved')} label="Approve" type="submit" id="register-button" />
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </>
  )
}

export default AccountDetailsForm
