import { Link } from "react-router-dom"
import munisafeLogo from "assets/munisafe_logo_white_bg.png"

const PageWrapper = ({ children }) => (
  <>
    <div className="topContainer">
      <Link to="/">
        <img id="logo-horizontal" className="horizontal-logo" src={munisafeLogo} alt="MuniSafe logo" />
      </Link>
    </div>

    <div className="grid">
      <div className="col-6 col-offset-3" style={{ minHeight: "220px" }}>
        {children}
      </div>
    </div>
  </>
)

export default PageWrapper
