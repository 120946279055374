import axios from "providers/ConfigureAxios"
import { getUrl } from "service/config/serviceUrls"

export default class NotificationsService {
  getLast(headers, notificationsNumber = 10) {
    return axios({
      method: "get",
      url: `${getUrl()}/Notifications?notificationsNumber=${notificationsNumber}`,
      headers,
    })
  }

	markAsRead(headers, notificationId) {
    return axios({
      method: "put",
      url: `${getUrl()}/Notifications/${notificationId}/is-read`,
      headers,
      data: {
        isRead: true
      }
    })
	}
}