import { useState } from "react"
import { Chart } from "primereact/chart"
import { Slider } from "primereact/slider"
import constants from "constants/constants"

const getLastMonday = () => {
  const date = new Date()
  const day = date.getDay()
  const diff = date.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
  return new Date(new Date(date.setDate(diff)).setHours(0, 0, 0, 0))
}

const getLastMondayDates = (weeksNumber) => {
  const monday = getLastMonday()
  const ONE_WEEK = 7 * 24 * 60 * 60 * 1000
  const mondayDates = []

  for (let index = 0; index < weeksNumber; index++) {
    mondayDates.unshift(new Date(monday.getTime() - ONE_WEEK * index))
  }

  return mondayDates.map((date) => date.toLocaleDateString())
}

const countCumulativeTransactionsPerWeek = (transactions, weeksNumber, openTransactions = true) => {
  const filteredTransactions = transactions.filter((transaction) => (openTransactions ? transaction.status === constants.TRANSACTION_STATUS.OPEN : transaction.status === constants.TRANSACTION_STATUS.CLOSED))
  const mondayDates = getLastMondayDates(weeksNumber)
  const transactionsCount = new Array(mondayDates.length).fill(0)

  filteredTransactions.forEach((transaction) => {
    const nextMondayIndex = openTransactions ? mondayDates.findIndex((monday) => new Date(monday).getTime() >= new Date(`${transaction.createdOn}Z`).getTime()) : mondayDates.findIndex((monday) => new Date(monday).getTime() >= new Date(`${transaction.lastUpdatedOn}Z`).getTime())

    if (nextMondayIndex === -1) {
      transactionsCount[mondayDates.length - 1] += 1
    } else {
      transactionsCount[nextMondayIndex - 1] += 1
    }
  })

  return transactionsCount.reduce((acc, count, index) => ({ ...acc, [mondayDates[index]]: count }), {})
}

const countTransactionsPerType = (transactions, type = constants.TRANSACTION_TYPES.COMPETITIVE) => transactions.filter((transaction) => transaction.type === type).length

export default function TransactionCharts({ transactions }) {
  const [weeksNumber, setWeeksNumber] = useState(7)

  const cumulativeOpenTransactionsPerWeek = countCumulativeTransactionsPerWeek(transactions, weeksNumber, true)
  const cumulativeClosedTransactionsPerWeek = countCumulativeTransactionsPerWeek(transactions, weeksNumber, false)

  const basicData = {
    labels: getLastMondayDates(weeksNumber),
    datasets: [
      {
        label: "Open",
        backgroundColor: "#1aa260",
        data: cumulativeOpenTransactionsPerWeek,
      },
      {
        label: "Closed",
        backgroundColor: "#4E86E3",
        data: cumulativeClosedTransactionsPerWeek,
      },
    ],
  }

  const basicOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  }

  const chartData = {
    labels: [constants.TRANSACTION_TYPES.COMPETITIVE, constants.TRANSACTION_TYPES.NEGOTIATED, constants.TRANSACTION_TYPES.PRIVATE_PLACEMENT],
    datasets: [
      {
        data: [countTransactionsPerType(transactions, constants.TRANSACTION_TYPES.COMPETITIVE), countTransactionsPerType(transactions, constants.TRANSACTION_TYPES.NEGOTIATED), countTransactionsPerType(transactions, constants.TRANSACTION_TYPES.PRIVATE_PLACEMENT)],
        backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
        hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"],
      },
    ],
  }

  const pieOptions = {
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
  }

  return (
    <>
      <div className="card p-5">
        <div className="card-header flex mb-2">
          <h3>Cumulative transactions per week</h3>
          <div className="w-2 flex-column gap-4 align-items-center">
            <h6>Number of weeks</h6>
            <div className="flex gap-4 align-items-center">
              <Slider className="flex-grow-1" min={1} max={52} value={weeksNumber} onChange={(e) => setWeeksNumber(e.value)} />
              <span>{weeksNumber}</span>
            </div>
          </div>
        </div>
        <Chart type="bar" data={basicData} options={basicOptions} />
        <div className="p-card-footer mt-4">
          <p>
            <em>Note: </em>the dates correspond to the monday of each week. Each bar counts the amount of transactions that were open / closed during the week of the corresponding monday.
          </p>
        </div>
      </div>

      <div className="card p-5">
        <div className="card-header flex mb-2">
          <h3>Transactions per method of sale</h3>
        </div>
        <Chart className="ml-auto mr-auto" type="pie" data={chartData} options={pieOptions} style={{ width: "40%" }} />
      </div>
    </>
  )
}
