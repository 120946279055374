import axios from "providers/ConfigureAxios"
import { getUrl } from "service/config/serviceUrls"

export default class HistoryChangesService {
	getAll(headers) {
    return axios({
      method: "get",
      url: `${getUrl()}/HistoryChanges`,
      headers
    })
	}

  getAllByEntityId(headers, entityId) {
    return axios({
      method: "get",
      url: `${getUrl()}/HistoryChanges?entityId=${entityId}`,
      headers
    })
	}
}