import { ToastContext } from "Context/ToastContext"
import { useContext } from "react"

export const useToast = () => {
  const toastContext = useContext(ToastContext)

  if (!toastContext) {
    throw new Error("useToast should be used inside a ToastContext provider")
  }

  return toastContext
}