import { useState, useEffect, useMemo } from "react"
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { Tag } from "primereact/tag"
import UserService from "service/UserService"
import WiresService from "service/WiresService"
import { useUser } from "hooks/useUser"
import AccountDetailsForm from "components/Accounts/AccountDetailsForm"
import constants from "constants/constants"
import { getCurrencyString } from "utilities/format"

const WiresIndex = () => {
  const [wires, setWires] = useState([])
  const [displayAccountDetailsForm, setDisplayAccountDetailsForm] = useState(false)
  const [selectedWire, setSelectedWire] = useState(null)
  const [selectedWRInfo, setSelectedWRInfo] = useState(null)

  const { token, userData } = useUser()
  const headers = useMemo(() => ({ Authorization: "bearer " + token }), [token])

  const userService = useMemo(() => new UserService(), [])
  const wiresService = new WiresService()

  useEffect(() => {
    loadWires()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const amountBodyTemplate = (rowData) => {
    return getCurrencyString(rowData.amount)
  }

  const statusBodyTemplate = (rowData) => {
    const status = rowData.status === constants.WIRE_STATUS.RECEIVED ? null : rowData.status === constants.WIRE_STATUS.ACCEPTED ? "success" : rowData.status === constants.WIRE_STATUS.INVITED ? "info" : "danger"
    const icon = "pi pi-fw " + (rowData.status === constants.WIRE_STATUS.RECEIVED || rowData.status === constants.WIRE_STATUS.ACCEPTED ? "pi-check" : rowData.status === constants.WIRE_STATUS.INVITED ? "pi-envelope" : "pi-times")
    return <Tag className="w-max" severity={status} icon={icon} value={rowData.status} />
  }

  const organizationBodyTemplate = (rowData) => rowData.organization ?? "-"

  const actionsBodyTemplate = (rowData) => {
    const { accountDetails, transactionManagerEmail, wireReceiverEmail, status } = rowData

    // Only show the Account Details Form button if:
    // 1. The Wire Receiver accepted the wire
    // 2. One of the following holds:
    // 2.a. The user is the TR Manager and there are no Account Details Created yet
    // 2.b. The user is the Wire Receiver and the Account Details were already created but not modified
    const showAccountDetailsFormButton = status === constants.WIRE_STATUS.ACCEPTED && ((userData.email === transactionManagerEmail && !accountDetails) || (userData.email === wireReceiverEmail && accountDetails !== null && !accountDetails.lastUpdatedOn))

    return (
      <div className="flex flex-wrap gap-2">
        {showAccountDetailsFormButton && (
          <Button
            icon="pi pi-credit-card"
            label="Account"
            className="p-button-action"
            style={{ marginRight: ".5em" }}
            onClick={() => {
              userService.search(headers, rowData.wireReceiverEmail).then(({ data: wireReceiverData }) => {
                setSelectedWRInfo(wireReceiverData)
                setDisplayAccountDetailsForm(true)
                setSelectedWire(rowData)
              })
            }}
          />
        )}
      </div>
    )
  }

  const onAccountDetailsAdded = () => {
    setDisplayAccountDetailsForm(false)
    loadWires()
  }

  const loadWires = () => {
    wiresService.getWires(headers).then((resp) => {
      if (resp.data) {
        setWires(resp.data)
      }
    })
  }

  return (
    <>
      <Dialog visible={displayAccountDetailsForm} style={{ width: "60vw" }} header="Account Details" onHide={() => setDisplayAccountDetailsForm(false)}>
        <AccountDetailsForm wireInfo={selectedWire} wireReceiverInfo={selectedWRInfo} onAccountDetailsAdded={onAccountDetailsAdded}></AccountDetailsForm>
      </Dialog>

      <div className="grid table-demo">
        <div className="col-12">
          <div className="card">
            {wires && (
              <DataTable value={wires} paginator className="p-datatable-gridlines" showGridlines rows={10} dataKey="id" responsiveLayout="scroll" emptyMessage="No wires found.">
                <Column field="id" header="Wire ID" style={{ minWidth: "12rem" }} />
                <Column field="transactionId" header="Transaction ID" style={{ minWidth: "12rem" }} />
                <Column field="wireReceiverEmail" header="Wire Recipient Email" style={{ minWidth: "12rem" }} />
                <Column field="organization" header="Organization / Company" body={organizationBodyTemplate} style={{ minWidth: "12rem" }} />
                <Column field="role" header="Role" style={{ minWidth: "12rem" }} />
                <Column field="amount" header="Amount" body={amountBodyTemplate} style={{ minWidth: "12rem" }} />
                <Column field="status" header="Status" body={statusBodyTemplate} style={{ minWidth: "12rem" }} />
                <Column header="Actions" style={{ minWidth: "12rem" }} body={actionsBodyTemplate} />
              </DataTable>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default WiresIndex
