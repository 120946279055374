import UsersTable from "components/Users/UsersTable"

const UsersIndex = () => {
  return (
    <div className='card'>
      <UsersTable />
    </div>
  )
}

export default UsersIndex
