const CLAIM_TYPE_NAMESPACE_2008 = "http://schemas.microsoft.com/ws/2008/06/identity/claims/"

const CLAIM_TYPE_NAMESPACE_2005 = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/"

const ROLES = {
  SUPER_ADMIN: "Super Admin",
  ORGANIZATION_ADMIN: "Organization Admin",
  USER: "User",
}

const USER_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  BLOCKED: "Blocked",
}

const WIRE_STATUS = {
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  CANCELED: "Canceled",
  INVITED: "Invited",
  RECEIVED: "Received",
}

const PARTICIPANT_STATUS = {
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  CANCELED: "Canceled",
  INVITED: "Invited",
}

const TRANSACTION_ROLES_OTHER_PARTICIPANTS = {
  ADVISOR: "Advisor",
  BANK: "Bank",
  BOND_COUNSEL: "Bond Counsel",
  COUNTY: "County",
  DISCLOSURE_COUNSEL: "Disclosure Counsel",
  ESCROW_AGENT: "Escrow Agent",
  ISSUER: "Issuer",
  PLACEMENT_AGENT: "Placement Agent",
  TRUSTEE: "Trustee",
  UNDERWRITER: "Underwriter",
  UNDERWRITERS_COUNSEL: "Underwriter's Counsel",
}

const TRANSACTION_ROLES = {
  TRANSACTION_MANAGER: "Transaction Manager",
  WIRE_SENDER: "Wire Sender",
  WIRE_RECEIVER: "Wire Receiver",
  ADVISOR: "Advisor",
  BANK: "Bank",
  BOND_COUNSEL: "Bond Counsel",
  COUNTY: "County",
  DISCLOSURE_COUNSEL: "Disclosure Counsel",
  ESCROW_AGENT: "Escrow Agent",
  ISSUER: "Issuer",
  PLACEMENT_AGENT: "Placement Agent",
  TRUSTEE: "Trustee",
  UNDERWRITER: "Underwriter",
  UNDERWRITERS_COUNSEL: "Underwriter's Counsel",
}

const TRANSACTION_TYPES = {
  COMPETITIVE: "Competitive",
  PRIVATE_PLACEMENT: "Private Placement",
  NEGOTIATED: "Negotiated",
}

const TRANSACTION_STATUS = {
  OPEN: "Open",
  CLOSED: "Closed",
}

const NOTIFICATION_TYPES = {
  WIRE_INVITATION: "Wire Receiver Invitation",
  PARTICIPANT_INVITATION: "Participant Invitation",
  WIRE_ACCEPTED: "Wire Accepted",
  WIRE_REJECTED: "Wire Rejected",
}

const constants = {
  CLAIM_TYPE_NAMESPACE_2008,
  CLAIM_TYPE_NAMESPACE_2005,
  ROLES,
  USER_STATUS,
  WIRE_STATUS,
  PARTICIPANT_STATUS,
  TRANSACTION_ROLES_OTHER_PARTICIPANTS,
  TRANSACTION_ROLES,
  TRANSACTION_TYPES,
  TRANSACTION_STATUS,
  NOTIFICATION_TYPES,
}

export default constants
