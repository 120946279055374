import { getCurrencyString } from "utilities/format"
import { TabView, TabPanel } from "primereact/tabview"
import CustomTag from "../../../../utilities/CustomTag"

const ApprovedWireView = ({ wire, setVisible, setIsLoading, transactionId, wireHistory }) => {
  const maskedString = "XXXXXXXXXX"

  return (
    <div style={{ paddingLeft: "28px", paddingRight: "28px", width: "500px" }}>
      <TabView>
        {wireHistory.map((wh, index) => (
          <TabPanel header={index == 0 ? "Approved" : `Rejected ${index}`} style={index === 0 && { paddingRight: "20px" }} key={`wh_${index}`}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 10 }}>
              <div className="row-2 mb-2" id="row-principal-confirm-wire">
                <label className="new-confirm-wire-label">ABA Number</label>
                <span className="new-confirm-wire-value-1">{wh.aba || maskedString}</span>
              </div>
              <div className="row-2 mb-2" id="row-principal-confirm-wire">
                <label className="new-confirm-wire-label">Account Name</label>
                <span className="new-confirm-wire-value-1">{wh.fbo || maskedString}</span>
              </div>
              <div className="row-2 mb-2" id="row-principal-confirm-wire">
                <label className="new-confirm-wire-label">Account Number</label>
                <span className="new-confirm-wire-value-1">{wire.accountNumber || maskedString}</span>
              </div>
              <div className="row-2" id="row-principal-confirm-wire">
                <label className="new-confirm-wire-label">Wire Amount</label>
                <span className="new-confirm-wire-value-1">{getCurrencyString(wire.amount)}</span>
              </div>
              <div className="row-confirm-wire-purpose-content pt-3 pb-3" style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 10, marginBottom: 10, gap: 36 }}>
                <span className="new-confirm-wire-label-purpose">Purpose</span>
                <div className="flex flex-column gap-2">
                  {wh.wirePurposes.map((p) => (
                    <div>
                      <div className="row-confirm-wire-purpose">
                        {getCurrencyString(p.amount)} - {p.purpose}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row-2 pt-3 pb-3" id="row-confirm-wire">
                <span className="new-confirm-wire-label">Wire Receiver</span>
                <span className="new-confirm-wire-value">{wire.wireReceiverEmail}</span>
              </div>
              <div className="row-2 pt-3 pb-3" id="row-confirm-wire">
                <span className="new-confirm-wire-label">Recipient Role</span>
                <span className="new-confirm-wire-value">{wh.role}</span>
              </div>
              <div className="row-2 pt-3 pb-3" id="row-confirm-wire">
                <span className="new-confirm-wire-label">Bank Name</span>
                <span className="new-confirm-wire-value">{wh.receiver || maskedString}</span>
              </div>
              {/* <div className="row-2" id="row-confirm-wire">
                <span className="new-confirm-wire-label">ABA Number</span>
                <span className="new-confirm-wire-value">{wh.aba || maskedString}</span>
              </div> */}
              <div className="row-2 pt-3 pb-3" id="row-confirm-wire">
                <span className="new-confirm-wire-label">Instructions</span>
                <span className="new-confirm-wire-value">{wh.fundApplicationInstructions}</span>
              </div>
            </div>
            <div class="wire-footer d-flex justify-content-between align-items-center p-3">
              {CustomTag(index === 0 ? { status: "Approved" } : { status: "Rejected" })}{" "}
              <span class="approved-by" style={{ fontWeight: "600" }}>
                {wh.wireReceiverName ? wh.wireReceiverName : wh.contactName}
              </span>
              <span style={{ fontWeight: "600" }}>{new Date(wh.updatedDate).toLocaleDateString()}</span>
            </div>
          </TabPanel>
        ))}
      </TabView>
    </div>
  )
}

export default ApprovedWireView
