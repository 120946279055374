import { Controller, useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { useEffect, useRef } from "react"

import AccountService from "service/AccountService"
import { Button } from "primereact/button"
import { Divider } from "primereact/divider"
import { Password } from "primereact/password"
import { useToast } from "hooks/useToast"
import { mediumPasswordRegex, strongPasswordRegex } from "utilities/format"

const passwordFooter = (
  <>
    <Divider />
    <p className="mt-2">Requirements</p>
    <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
      <li>At least one lowercase</li>
      <li>At least one uppercase</li>
      <li>At least one numeric</li>
      <li>At least one special character</li>
      <li>Minimum 10 characters</li>
    </ul>
  </>
)

const PasswordResetForm = ({ token }) => {
  const timeoutRef = useRef(null)

  const {
    clearErrors,
    control,
    formState: { isSubmitting, isSubmitSuccessful, errors },
    handleSubmit,
    setError,
    watch,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    reValidateMode: "onChange",
  })
  const password = watch("password")

  const toast = useToast()

  const navigate = useNavigate()

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const onSubmit = async ({ password }) => {
    const accountService = new AccountService()

    const headers = {
      Authorization: `bearer ${token}`,
    }

    try {
      await accountService.resetPassword(headers, password)

      timeoutRef.current = setTimeout(() => {
        navigate("/login")
      }, 12000)
    } catch (error) {
      setError("server", { type: "custom", message: error.response.data })

      if (error.response.data?.toLowerCase()?.includes("password")) {
        toast.current.show({ severity: "error", summary: "Can't reset the password", detail: error.response.data, sticky: true })
      }
    }
  }

  // Show an error message if the problem is not related to the password value.
  // If the problem is related to the password value, simply display a toast message
  if (errors.server && !errors.server.message.toLowerCase().includes("password")) {
    return (
      <div className="card">
        <div className="card-header flex-column m-4">
          <h2>Password reset</h2>
          <p className="card-subtitle mt-2">Could not reset the password</p>
        </div>
        <div className="p-card-content p-4 pt-0">
          <div className="p-card-body text-center">Maybe the password reset link is broken or has expired.</div>
          <div className="p-card-footer mb-4 text-center">
            You can get a new password reset link <Link to="/password-reset">here</Link> or you can login <Link to="/login">here</Link>.
          </div>
        </div>
      </div>
    )
  }

  if (isSubmitSuccessful) {
    return (
      <div className="card">
        <div className="card-header flex-column m-4">
          <h2>Password reset</h2>
          <p className="card-subtitle mt-2">Password reset successfully</p>
        </div>
        <div className="p-card-content p-4 pt-0 mb-4">
          <div className="p-card-footer text-center mb-4">
            You are now being automatically redirected to the login page. If your are not redirected automatically, <Link to="/login">click here</Link>.
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="card">
      <div className="card-header flex-column m-4">
        <h2>Password reset</h2>
        <p>Enter your new password</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid formgrid grid">
        <div className="m-4 p-fluid formgrid grid w-full">
          <div className="field col-12 md:col-6">
            <label htmlFor="password">Password</label>
            <Controller
              name="password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Required field",
                },
                minLength: {
                  value: 10,
                  message: "Minimum of 10 characters",
                },
                validate: {
                  containsLowerCase: (v) => /[a-z]/.test(v) || "Use at least one lower case",
                  containsUppercase: (v) => /[A-Z]/.test(v) || "Use at least one uppercase",
                  containsNumeric: (v) => /\d/.test(v) || "Use at least one numeric",
                  containsSpecialCharacter: (v) => /\W|_/.test(v) || "Use at least one special character",
                },
              }}
              render={({ field, fieldState }) => (
                <div className="relative">
                  <Password
                    id={field.name}
                    toggleMask
                    inputClassName={fieldState.error ? "input-error" : "input-correct"}
                    placeholder="Password"
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value.trim())}
                    footer={passwordFooter}
                    mediumRegex={mediumPasswordRegex}
                    strongRegex={strongPasswordRegex}
                  />
                  <br />
                  {fieldState.error && <span className="p-error absolute bottom-0">{fieldState.error.message}</span>}
                </div>
              )}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Required field",
                },
                validate: (value) => value === password || "Passwords do not match",
              }}
              render={({ field, fieldState }) => (
                <div className="relative">
                  <Password id={field.name} toggleMask feedback={false} inputClassName={fieldState.error ? "input-error" : "input-correct"} placeholder="Confirm Password" value={field.value} onChange={(e) => field.onChange(e.target.value.trim())} />
                  <br />
                  {fieldState.error && <span className="p-error absolute bottom-0">{fieldState.error.message}</span>}
                </div>
              )}
            />
          </div>
        </div>

        <div className="field col-12 md:col-5 registerBottom">
          <div className="field col-12 md:col-5 col-offset-6">
            <Button onClick={() => clearErrors()} style={{ float: "right" }} loading={isSubmitting} label="Submit" />
          </div>
        </div>
      </form>
    </div>
  )
}

export default PasswordResetForm
