import { useState, useMemo, useRef, useEffect } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Card } from "primereact/card"
import { Button } from "primereact/button"
import { ProgressSpinner } from "primereact/progressspinner"
import PageWrapper from "pages/PageWrapper"
import AccountService from "service/AccountService"
import UserService from "service/UserService"
import { useToast } from "hooks/useToast"

const EmailVerificationIndex = () => {
  const [isFetchingVerification, setIsFetchingVerification] = useState(true)
  const [isAlreadyVerified, setIsAlreadyVerified] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [emailVerified, setEmailVerified] = useState(false)
  const [email, setEmail] = useState("")
  const [verificationError, setVerificationError] = useState(false)

  const { userId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const timeoutRef = useRef(null)

  const toast = useToast()

  const accountService = useMemo(() => new AccountService(), [])
  const userService = useMemo(() => new UserService(), [])

  const handleClick = () => {
    setIsLoading(true)

    accountService.verifyEmail(userId)
    .then(({ data: email }) => {
      setEmailVerified(true)
      setEmail(email)
      timeoutRef.current = setTimeout(() => navigate("/"), 10000)
    })
    .catch((error) => {
      console.error(error)
      setEmailVerified(true)
      setVerificationError(true)
    })
    .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (userId && isFetchingVerification) {
      userService.isVerified(userId)
      .then(({ data: isVerified }) => {
        setIsAlreadyVerified(isVerified)
        if (isVerified) {
          timeoutRef.current = setTimeout(() => navigate("/"), 10000)
        }
      })
      .catch(() => {
        navigate("/")
        toast.current.replace({ severity: "error", summary: "Invalid verification link", life: 5000 })
      })
      .finally(() => setIsFetchingVerification(false))
    }

    return () => clearTimeout(timeoutRef.current)
  }, [isFetchingVerification, userId, userService])

  const verificationFooter = (
    <span>
      <Button loading={isLoading} onClick={handleClick} className="justify-content-center" style={{ minWidth: "117px" }}>
        Verify now
      </Button>
      <p className="mt-3">
        Are you already verified? Try <Link to="/login">logging in</Link>.
      </p>
    </span>
  )

  const verifiedFooter = (
    <span>
      <p>
        If your are not redirected automatically, click <Link to="/login">here</Link>
      </p>
    </span>
  )

  const errorFooter = (
    <span>
      <p>
        Let us know if you think this is an error by <Link to="/">clicking here</Link>.
      </p>
    </span>
  )

  if (isFetchingVerification) {
    return <PageWrapper>
      <ProgressSpinner />
    </PageWrapper>
  }

  if (isAlreadyVerified) {
    return <PageWrapper>
      <Card className="p-4" title="Email verification" subTitle={`This account is already verified`} footer={verifiedFooter}>
        You are now being automatically redirected to the login page.
      </Card> 
    </PageWrapper>
  }

  return (
    <PageWrapper>
      {!emailVerified ? (
        <Card className="p-4" title="Email verification" subTitle={`Welcome ${searchParams.get("name") ?? ""}`} footer={verificationFooter}>
          Before using MuniSafe, please confirm your email address. You will not be able to login until you do.
        </Card>
      ) : !verificationError ? (
        <Card className="p-4" title="Email verification" subTitle={`Email ${email} verified successfully`} footer={verifiedFooter}>
          You may now log into MuniSafe. You are now being automatically redirected to the login page.
        </Card>
      ) : (
        <Card className="p-4" title="Email verification" subTitle={`Error verifying email`} footer={errorFooter}>
          We could not verify your email. Have you <Link to="/register">registered</Link> already?
        </Card>
      )}
    </PageWrapper>
  )
}

export default EmailVerificationIndex
