import { UserContext } from "Context/UserContext"
import { useContext } from "react"

export const useUser = () => {
	const userContext = useContext(UserContext)

	if (!userContext) {
		throw new Error("userContext should be used inside a UserContext provider")
	}

	return userContext
}