import * as Sentry from "@sentry/react"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { useToast } from "hooks/useToast"
import jwt_decode from "jwt-decode"
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import App from "App/App"
import { Login } from "pages/Login"
import { Register } from "pages/Register"
import { useUser } from "hooks/useUser"
import AccountService from "service/AccountService"
import OrganizationsIndex from "components/Organizations/OrganizationsIndex"
import UsersIndex from "components/Users/UsersIndex"
import TransactionsIndex from "components/Transactions/TransactionsIndex"
import TransactionViewNew from "components/Transactions/TransactionView-new"
import TransactionView from "components/Transactions/TransactionView/TransactionView"
import WiresIndex from "components/Wires/WiresIndex"
import ParticipantInvitation from "components/Invitations/ParticipantInvitation"
import EmailVerificationIndex from "components/EmailVerification/EmailVerificationIndex"
import constants from "constants/constants"
import DashboardIndex from "components/Dashboard/DashboardIndex"
import WireInvitationIndex from "components/Invitations/WireInvitation"
import InvoicesIndex from "components/Invoices/InvoicesIndex"
import InvoicesView from "components/Invoices/InvoicesView"
import PasswordResetIndex from "components/PasswordReset/PasswordResetIndex"
import InvitationsIndex from "components/Invitations/InvitationsIndex"
import HistoryChangesIndex from "components/HistoryChanges/HistoryChangesIndex"
import InstructionsIndex from "components/Instructions/InstructionsIndex"
import { HomePage } from "pages/HomePage"
import { LoginCallback, useOktaAuth } from "@okta/okta-react"
import { ProgressSpinner } from "primereact/progressspinner"

const AppWrapper = () => {
  const location = useLocation()

  const [invitationParticipantId, setInvitationParticipantId] = useState(null)
  const [invitationWireId, setInvitationWireId] = useState(null)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const redirectToInvitationParticipantId = searchParams.get("redirectToInvitationParticipantId")
  const redirectToInvitationWireId = searchParams.get("redirectToInvitationWireId")
  const [loginProgress, setLoginProgress] = useState(location.search.includes("token"))
  const [isLoading, setIsLoading] = useState(false)
  const { authState, oktaAuth } = useOktaAuth()
  console.log("authstate in app", authState)
  const { setToken, setUserData, setRole } = useUser()

  const toast = useToast()
  useEffect(() => {
    const accountService = new AccountService()
    console.log("okta state in appwrapper", authState)
    if (authState && authState.isAuthenticated) {
      setLoginProgress(true)
      accountService
        .loginWithOktaToken(authState.idToken.idToken)
        .then(({ data }) => {
          setToken(data.token)
          setUserData({
            name: data.name,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            email: data.email,
            title: data.title,
            organization: data.organization,
            orgRole: data.orgRole,
            isUnderwriter: data.isUnderwriter,
            profilePicture: data.profilePicture,
          })
          setLoginProgress(false)
          const decoded = jwt_decode(data.token)
          setRole(decoded[`${constants.CLAIM_TYPE_NAMESPACE_2008}role`])
          if (redirectToInvitationParticipantId) {
            navigate({
              pathname: `/participant-invitation/${redirectToInvitationParticipantId}`,
            })
          } else if (redirectToInvitationWireId) {
            navigate({
              pathname: `/wire-invitation/${redirectToInvitationWireId}`,
            })
          } else {
            navigate("/")
          }
        })
        .catch((error) => {
          // Wrong password
          if (error.response.status === 400) {
            toast.current.show({ severity: "error", summary: error.response.data, life: 5000 })
          }
          // Unverified email
          if (error.response.status === 401) {
            toast.current.show({ severity: "error", summary: error.response.data, detail: "An email was sent to you to verify your account", sticky: true })
          }
          // Account inactive or blocked
          if (error.response.status === 403) {
            toast.current.show({ severity: "error", summary: "Can't login", detail: error.response.data, sticky: true })
          }
          if (error.response.status === 404) {
            toast.current.show({ severity: "error", summary: "User not found", life: 5000 })
          }
        })
        .finally(() => setIsLoading(false))
    }
  }, [oktaAuth, authState])
  // State update must be synchronous
  // Otherwise, a previous invitationParticipantId value may be used
  useLayoutEffect(() => {
    const invitationIdFromParticipantURL = location.pathname.startsWith("/participant-invitation/") ? location.pathname.substring(24) : null
    const invitationIdFromWireURL = location.pathname.startsWith("/wire-invitation/") ? location.pathname.substring(17) : null

    if (invitationIdFromParticipantURL) {
      setInvitationParticipantId(invitationIdFromParticipantURL)
    }

    if (invitationIdFromWireURL) {
      setInvitationWireId(invitationIdFromWireURL)
    }
  }, [location.pathname])

  const { token, role, userData } = useUser()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])
  const isLocal = process.env.REACT_APP_ENV === "dev"

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
  return (
    <>
      {loginProgress && <ProgressSpinner aria-label="Loading" />}
      <SentryRoutes>
        {(authState && authState.isAuthenticated && token && !loginProgress) || (token && isLocal && !loginProgress) ? (
          <Route path="/" element={<App />}>
            {/* Routes for all users */}
            <Route path="transactions" element={<TransactionsIndex />} />
            <Route path="transactions/:transactionId" element={<TransactionView />} />
            <Route path="new-transaction-view" element={<TransactionViewNew />} />
            <Route path="participant-invitation/:participantId" element={<ParticipantInvitation />} />
            <Route path="wire-invitation/:wireId" element={<WireInvitationIndex />} />
            {/* Routes for Super Admin only */}
            {role === constants.ROLES.SUPER_ADMIN && (
              <>
                <Route path="/" element={<DashboardIndex />} />
                <Route path="organizations" element={<OrganizationsIndex />} />
                <Route path="users" element={<UsersIndex />} />
                <Route path="wires" element={<WiresIndex />} />
                <Route path="invoices" element={<InvoicesIndex />} />
                <Route path="invoices/:invoiceId" element={<InvoicesView />} />
                <Route path="history-changes" element={<HistoryChangesIndex />} />
              </>
            )}
            {/* Routes for Organization Admin only */}
            {role === constants.ROLES.ORGANIZATION_ADMIN && (
              <>
                <Route path="/" element={<TransactionsIndex />} />
                <Route path="users" element={<UsersIndex />} />
                <Route path="invoices" element={<InvoicesIndex />} />
                <Route path="invoices/:invoiceId" element={<InvoicesView />} />
                <Route path="invitations" element={<InvitationsIndex />} />
                <Route path="history-changes" element={<HistoryChangesIndex />} />
                <Route path="instructions" element={<InstructionsIndex />} />
              </>
            )}
            {/* Routes for User only */}
            {role === constants.ROLES.USER && (
              <>
                {userData.organization && <Route path="/" element={<TransactionsIndex />} />}
                {!userData.organization && <Route path="/" element={<DashboardIndex />} />}
                <Route path="invoices" element={<InvoicesIndex />} />
                <Route path="invoices/:invoiceId" element={<InvoicesView />} />
                <Route path="invitations" element={<InvitationsIndex />} />
                <Route path="instructions" element={<InstructionsIndex />} />
              </>
            )}
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        ) : (
          <>
            {!loginProgress && (!(authState && authState.isAuthenticated) || (isLocal && !token)) ? (
              <>
                <Route path="/" element={<login />} />
                {/* <Route path="/homepage" element={<HomePage />} /> */}
                <Route path="/login" element={<Login />} />
                <Route path="/login/callback" element={<LoginCallback />} />
                <Route path="/register" element={<Register />} />
                <Route path="/participant-invitation/:participantId" element={<Navigate to={`/login?redirectToInvitationParticipantId=${invitationParticipantId}`} />} />
                <Route path="/wire-invitation/:wireId" element={<Navigate to={`/login?redirectToInvitationWireId=${invitationWireId}`} />} />
                <Route path="/account/:userId/email-verification" element={<EmailVerificationIndex />} />
                <Route path="/password-reset" element={<PasswordResetIndex />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </>
            ) : (
              <> </>
            )}
          </>
        )}
      </SentryRoutes>
    </>
  )
}

export default AppWrapper
