import axios from "providers/ConfigureAxios"
import { getUrl } from "service/config/serviceUrls"

export default class TransactionService {
  createTransaction(headers, wire) {
    return axios({
      method: "post",
      url: `${getUrl()}/transactions/create`,
      headers,
      data: wire,
    })
  }

  getTransactions(headers) {
    return axios({
      method: "get",
      url: `${getUrl()}/transactions/list`,
      headers,
    })
  }

  getInvitedTransactionsCount(headers) {
    return axios({
      method: "get",
      url: `${getUrl()}/transactions/getInvitedCount`,
      headers,
    })
  }

  getTransaction(headers, transactionId) {
    return axios({
      method: "get",
      url: `${getUrl()}/transactions/${transactionId}`,
      headers,
    })
  }

  editTransaction(headers, transactionId, transactionFields) {
    return axios({
      method: "put",
      url: `${getUrl()}/transactions/${transactionId}`,
      headers,
      data: transactionFields,
    })
  }

  closeTransaction(headers, transactionId) {
    return axios({
      method: "put",
      url: `${getUrl()}/transactions/${transactionId}/close`,
      headers,
    })
  }

  deleteWireSender(headers, transactionId) {
    return axios({
      method: "delete",
      url: `${getUrl()}/transactions/${transactionId}/wire-sender`,
      headers,
    })
  }

  deleteTransaction(headers, transactionId) {
    return axios({
      method: "post",
      url: `${getUrl()}/transactions/delete/${transactionId}`,
      headers,
    })
  }

  sendWireInvites(headers, transactionId) {
    return axios({
      method: "post",
      url: `${getUrl()}/transactions/sendWireInvites/${transactionId}`,
      headers,
    })
  }

  sendParticipantsInvitations(headers, txnId) {
    return axios({
      method: "post",
      url: `${getUrl()}/wires/sendInvitations?transactionId=${txnId}`,
      headers,
    })
  }
}
