import axios from "providers/ConfigureAxios"
import { getUrl } from "service/config/serviceUrls"

export default class OrganizationService {
  getOrganizations(headers) {
    return axios({
      method: "get",
      url: `${getUrl()}/organizations`,
      headers: headers,
    })
  }

  createOrganization(headers, data) {
    return axios({
      method: "post",
      url: `${getUrl()}/organizations`,
      headers,
      data
    })
  }
}
