import { useCallback, useEffect, useMemo, useState } from "react"
import { Accordion, AccordionTab } from "primereact/accordion"
import TransactionCharts from "components/Dashboard/DashboardCharts/TransactionCharts"
import UserCharts from "components/Dashboard/DashboardCharts/UserCharts"
import TransactionService from "service/TransactionService"
import UserService from "service/UserService"
import { useUser } from "hooks/useUser"
import StatisticsService from "service/StatisticsService"


export default function DashboardCharts() {
  const [transactions, setTransactions] = useState([])
  const [users, setUsers] = useState([])
  const [activeUsersStats, setActiveUsersStats] = useState([])

  const transactionService = useMemo(() => new TransactionService(), [])
  const userService = useMemo(() => new UserService(), [])
  const statisticsService = useMemo(() => new StatisticsService(), [])

  const { token } = useUser()

  const loadTransactions = useCallback(() => {
    const header = { Authorization: "bearer " + token }
    transactionService.getTransactions(header).then((resp) => setTransactions(resp.data))
  }, [token, transactionService])


  const loadUsers = useCallback(() => {
    const header = { Authorization: "bearer " + token }
    userService.getUsers(header).then((resp) => setUsers(resp.data))
  }, [token, userService])

  const loadActiveUsersStats = useCallback(() => {
    const header = { Authorization: "bearer " + token }
    statisticsService.getActiveUsers(header).then((resp) => {
      const activeUsersObj = resp.data.sort(({ date: date1 }, { date: date2 }) => new Date(date1).getTime() - new Date(date2).getTime()).reduce((auStats, auEntry) => ({ ...auStats, [new Date(auEntry.date).toLocaleDateString()]: auEntry.activeUsers }), {})
      setActiveUsersStats(activeUsersObj)
    })
  }, [statisticsService, token])

  useEffect(() => {
    loadUsers()
    loadTransactions()
    loadActiveUsersStats()
  }, [loadActiveUsersStats, loadTransactions, loadUsers])

  return (
    <Accordion multiple>
      <AccordionTab header="Transactions">
        <TransactionCharts transactions={transactions} />
      </AccordionTab>
      <AccordionTab header="Users">
        <UserCharts users={users} activeUsersStats={activeUsersStats} />
      </AccordionTab>
    </Accordion>
  )
}
