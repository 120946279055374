import { InputText } from "primereact/inputtext"
import { formatPhoneNumber } from "utilities/format"

const InputPhone = ({ id, value, disabled, error, onChange, inputRef }) => {
  return (
		<InputText
      id={id}
			disabled={disabled}
      ref={inputRef}
      className={error ? "input-error" : "input-correct"}
      placeholder="(555) 555-1234"
      value={formatPhoneNumber(value)}
      onChange={(e) => onChange(formatPhoneNumber(e.target.value))}
    />
  )
}

export default InputPhone
