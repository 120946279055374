import { useNotificationsSocket } from "hooks/useNotificationsSocket"
import { useUser } from "hooks/useUser"
import { classNames } from "primereact/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import NotificationsService from "service/NotificationsService"
import NotificationListItem from "./NotificationListItem"

function NotificationsBadge({ topbarNotificationMenuActive, onTopbarNotification }) {
  const [notifications, setNotifications] = useState([])

  const handleTopbarNotification = useCallback(
    (event) => {
      if (notifications.length === 0) {
        return
      }

      onTopbarNotification(event)
    },
    [notifications.length, onTopbarNotification]
  )

  const handleNotification = useCallback((type, body) => {
    if (type === "UpdateNotifications") {
      setNotifications(body)
    } else {
      console.error(`Unhandled type: ${type}`)
    }
  }, [])

  useNotificationsSocket(handleNotification)

  const { token } = useUser()
  const headers = useMemo(
    () => ({
      Authorization: `bearer ${token}`,
    }),
    [token]
  )

  const notificationsItemClassName = classNames("notifications-item", { "active-menuitem": topbarNotificationMenuActive })

  const notificationsService = useMemo(() => new NotificationsService(), [])

  useEffect(() => {
    notificationsService.getLast(headers).then(({ data }) => {
      setNotifications(data)
    })
  }, [headers, notificationsService])

  const handleNotificationClick = useCallback(
    ({ id, payload }) =>
      () => {
        notificationsService
          .markAsRead(headers, id)
          .then(() => setNotifications((notifications) => notifications.filter((notification) => notification.id !== id)))
          .finally(() => {
            if (payload.Url) {
              window.open(payload.Url, "_blank", "popup=false,rel=noreferrer")
            }
          })
      },
    [headers, notificationsService]
  )

  return (
    <li className={notificationsItemClassName} style={{borderRadius:50,width:40,height:40,backgroundColor:'rgba(255, 255, 255, 0.10)',justifyContent:'center',display:'flex',alignItems:'center',marginLeft:8}}>
      <button type="button" className="p-link" onClick={handleTopbarNotification}>
        <i className="pi pi-bell" style={{color:'white'}}/>
        {notifications.length > 0 && <span className="topbar-badge">{notifications.length}</span>}
      </button>
      <ul className="notifications-menu fade-in-up">
        {notifications.map((notification) => (
          <NotificationListItem notification={notification} onNotificationClick={handleNotificationClick(notification)} key={notification.id} />
        ))}
      </ul>
    </li>
  )
}

export default NotificationsBadge
