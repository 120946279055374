import axios from "providers/ConfigureAxios"
import { getUrl } from "./config/serviceUrls"

export default class ParticipantService {
  addParticipant(headers, participant, internalOnly) {
    return axios({
      method: "post",
      url: `${getUrl()}/participants/create/?internalOnly=${internalOnly}`,
      headers,
      data: participant,
    })
  }

  updateParticipantRequest(headers, participantId, approve, txnId) {
    return axios({
      method: "put",
      url: `${getUrl()}/participants/?transactionId=${txnId}&participantId=${participantId}&approve=${approve == 1}`,
      headers,
    })
  }

  getParticipantsInTransaction(headers, transactionId, showAll = 0) {
    return axios({
      method: "get",
      url: `${getUrl()}/participants?transactionId=${transactionId}&showAll=${showAll}`,
      headers,
    })
  }

  delete(headers, id) {
    return axios({
      method: "delete",
      url: `${getUrl()}/participants/${id}`,
      headers,
    })
  }
}
