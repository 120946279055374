import React, { useState } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"
import { useUser } from "hooks/useUser"
import { useToast } from "hooks/useToast"

const TransactionViewNew = () => {
  const [loading, setLoading] = useState(true)
  const toast = useToast()
  const { token, role } = useUser()
  const [expandedRows, setExpandedRows] = useState(null)
  const navigate = useNavigate()

  // Example transaction details
  const transactionDetails = {
    transactionName: "NewYork State School Area Association LLC",
    wireSender: "Loop Capitals INC",
    transactionManager: "Darius S Scott",
    closingDate: "12/05/2024",
    parAmount: "$10,000,000.00",
    wiresTotal: "$10,900,000.00",
    totalWires: 5,
  }
  // Example data
  const transactions = [
    {
      id: 1,
      recipientEmail: "Frank.Abiganle@worldbank.com",
      wireId: "WD17S",
      organisation: "World Bank",
      role: "The Bank",
      purpose: "Multiple",
      wireAmount: "$1,20,00,000",
      status: "Active",
      details: [
        { description: "1. Building Found Deposit", wireAmount: "$6,792,526.00" },
        { description: "2. Costs of Insurance", wireAmount: "$8,355,206.00" },
      ],
    },
    {
      id: 2,
      recipientEmail: "Frank.Abiganle@worldbank.com",
      wireId: "WD17S",
      organisation: "World Bank",
      role: "The Bank",
      purpose: "Multiple",
      wireAmount: "$1,20,00,000",
      status: "Active",
      details: [
        { description: "1. Aasdadsasdasd  Asdad asfgrergregdg", wireAmount: "$60,792,526.00" },
        { description: "2. Casdasdasd asd dasdaweawe asdad awe ", wireAmount: "$355,206.00" },
      ],
    },
  ]

  const rowExpansionTemplate = (data) => {
    return (
      <div className="expanded-row-content">
        {data.details.map((detail, index) => (
          <div className={`grid detail-item ${index < data.details.length - 1 ? "detail-border" : ""}`} key={index}>
            <div className="col-2 col-offset-6 expanded-td">{detail.description}</div>
            <div className="col-2 expanded-td" style={{ paddingLeft: "1.2rem" }}>
              {detail.wireAmount}
            </div>
          </div>
        ))}
      </div>
    )
  }

  const tableHeader = (
    <div className="transaction-table-header grid">
      <div className="col">
        <h3>Transaction Name</h3>
        <p>{transactionDetails.transactionName}</p>
      </div>
      <div className="col">
        <h3>Wire Sender</h3>
        <p>{transactionDetails.organisationName}</p>
      </div>
      <div className="col">
        <h3>Transaction Manager</h3>
        <p>{transactionDetails.transactionManager}</p>
      </div>
      <div className="col">
        <h3>Closing Date</h3>
        <p>{transactionDetails.closingDate}</p>
      </div>
      <div className="col">
        <h3>PAR Amount</h3>
        <p>{transactionDetails.parAmount}</p>
      </div>
      <div className="col">
        <h3>Sum of Wires</h3>
        <p>{transactionDetails.wiresTotal}</p>
      </div>
      <div className="col">
        <h3>Total Wires</h3>
        <p>{transactionDetails.totalWires}</p>
      </div>
    </div>
  )

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="tb-actions">
        <Button className="link-button" onClick={() => viewTransaction(rowData)} label="View" />
        <Button className="link-button" style={{ color: "#667085" }} onClick={() => deleteTransaction(rowData)} label="Delete" />
      </div>
    )
  }

  const viewTransaction = (rowData) => {
    // Implement view functionality
  }

  const deleteTransaction = (rowData) => {
    // Implement delete functionality
  }

  return (
    <div style={{ padding: "20px" }}>
      <DataTable value={transactions} header={tableHeader} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate} className="transaction-table" dataKey="id">
        <Column expander style={{ padding: "0", textAlign: "right", width: "1rem" }} />
        <Column field="recipientEmail" header="RECIPIENT EMAIL" className="col-2" />
        <Column field="wireId" header="WIRE ID" />
        <Column field="organisation" header="ORGANISATION" />
        <Column field="role" header="ROLE" />
        <Column field="purpose" header="PURPOSE" className="col-2" />
        <Column field="wireAmount" header="WIRE AMOUNT" />
        <Column field="status" header="STATUS" />
        <Column body={actionBodyTemplate} header="ACTION" className="col-1" />
      </DataTable>
    </div>
  )
}

export default TransactionViewNew
