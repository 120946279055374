//TESTING Branch

import React, { useState, useEffect } from "react"
import { FilterMatchMode } from "primereact/api"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import TransactionService from "service/TransactionService"
import { Dialog } from "primereact/dialog"
import { useNavigate } from "react-router-dom"
import TransactionForm from "./TransactionForm"
import { useUser } from "hooks/useUser"
import { getCurrencyString } from "utilities/format"
import constants from "constants/constants"
import CustomTag from "utilities/CustomTag"
import { InputText } from "primereact/inputtext"
import { PanelMenu } from "primereact/panelmenu"
import { ConfirmDialog } from "primereact/confirmdialog"
import { useToast } from "hooks/useToast"
import TransactionSummary from "./TransactionSummary"

const TransactionsIndex = () => {
  const [transactions, setTransactions] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeIndex, setActiveIndex] = useState("created")

  const [displayDeleteTransactionModal, setDisplayDeleteTransactionModal] = useState(false)
  const [selectedTrId, setSelectedTrId] = useState(null)
  const toast = useToast()
  const [globalFilterValue, setGlobalFilterValue] = useState("")
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [invitedCount, setInvitedCount] = useState(null)
  const [selectedTransactionId, setSelectedTransactionId] = useState(null)
  const [selectedTransactionData, setSelectedTransactionData] = useState(null)
  const [openTransactionSummary, setOpenTransactionSummary] = useState(false)

  useEffect(() => {
    const tab = window.sessionStorage.getItem("all_transactions_tab")
    if (tab) {
      setActiveIndex(tab)
    }
  }, [])

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  const leftBarItems = [
    {
      label: "All Transactions",
      icon: "pi pi-fw pi-th-large",
      expanded: true,
      command: () => loadTransactionsByStatus("All"),
      items: [
        {
          label: "Draft",
          command: () => loadTransactionsByStatus("Draft"),
        },
        {
          label: "Active",
          command: () => loadTransactionsByStatus("Active"),
        },
        {
          label: "Approved",
          command: () => loadTransactionsByStatus("Approved"),
        },
        {
          label: "Closed",
          command: () => loadTransactionsByStatus("Closed"),
        },
        {
          label: "Deleted",
          command: () => loadTransactionsByStatus("Deleted"),
        },
      ],
    },
  ]

  useEffect(() => {
    getInvitedTxnCount()
  }, [])

  const loadTransactionsByStatus = (status) => {
    const header = { Authorization: `bearer ${token}` }
    if (status) {
      setSelectedStatus(status)
    } else {
      status = selectedStatus
    }
    setLoading(true)
    transactionService
      .getTransactions(header)
      .then((resp) => {
        if (status === "All") {
          setTransactions(resp.data)
        } else {
          setTransactions(resp.data.filter((s) => s.status === status))
        }
      })
      .finally(() => setLoading(false))
  }

  const getInvitedTxnCount = () => {
    const header = { Authorization: `bearer ${token}` }
    setLoading(true)
    transactionService
      .getInvitedTransactionsCount(header)
      .then((resp) => {
        setInvitedCount(resp.data)
      })
      .finally(() => setLoading(false))
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = { ...filters }

    _filters["global"].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const [openTransactionForm, setOpenTransactionForm] = useState(false)
  const { token, role, isUnderwriter } = useUser()
  const navigate = useNavigate()
  const transactionService = new TransactionService()

  useEffect(() => {
    loadTransactionsByStatus("All")

    // initFilters1()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const actionsBodyTemplate = (rowData) => {
    const { id, status } = rowData
    return (
      <div className="gridActionButtons">
        {/* <Button icon={<i className="flex mr-1">{eyeIcon}</i>} label="View" className="p-button-action p-button-raised" title="View this transaction" tooltip="View this transaction" tooltipOptions={{ position: "left" }} onClick={() => navigate(`/transactions/${rowData.id}`)} /> */}

        {rowData.status === "Draft" && (
          <>
            <Button
              label="Edit"
              style={{ width: "60px" }}
              text
              onClick={() => {
                setSelectedTransactionId(id)
                setSelectedTransactionData(transactions.find((transaction) => transaction.id === id))
                setOpenTransactionSummary(true)
              }}
            />
          </>
        )}
      </div>
    )
  }

  const amountBodyTemplate = (rowData) => {
    return getCurrencyString(rowData.parAmount)
  }

  const header =
    role === constants.ROLES.SUPER_ADMIN ? null : (
      <div className="flex flex-wrap align-items-center justify-content-between gap-2" style={{ padding: "10px 14px" }}>
        <div>
          <text style={{ marginRight: 20, color: "#101828", fontWeight: "bold", fontSize: 24 }}>{selectedStatus} Transactions</text>
          <div className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText className="p-inputtext-sm" type="text" placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} style={{ width: 320, maxHeight: 40 }} />
          </div>
        </div>
        {isUnderwriter && (
          <Button
            label="Create Transaction"
            onClick={() => setOpenTransactionForm(true)}
            icon="pi pi-plus-circle"
            iconPos="left"
            style={{
              // backgroundColor: "#000F66", color: "white",
              borderRadius: 4,
              height: 40,
            }}
          />
        )}
      </div>
    )

  const onTransactionCreated = (data) => {
    loadTransactionsByStatus()
    console.log(data)
    navigate(`/transactions/${data}`)
    setOpenTransactionForm(false)
  }

  const onTransactionUpdated = () => {
    loadTransactionsByStatus()
    setOpenTransactionSummary(false)
  }

  function convertUTCDateToLocalDate(row) {
    return <span>{new Date(`${row.createdOn}Z`).toLocaleString()}</span>
  }

  function closingDateTemplate(row) {
    return <span>{new Date(`${row.closingDate}Z`).toLocaleDateString()}</span>
  }

  const statusBodyTemplate = (rowData) => {
    return CustomTag(rowData)
  }

  const deleteTransaction = () => {
    const header = { Authorization: `bearer ${token}` }

    console.log("deleting tr: " + selectedTrId)
    transactionService.deleteTransaction(header, selectedTrId).then((response) => {
      setSelectedTrId(null) //clean up just in case.
      toast.current.show({ severity: "success", summary: "Transaction deleted", life: 5000 })
      loadTransactionsByStatus()
    })
  }

  // const roleInTRBodyTemplate = ({ roleInTR }) => (roleInTR === "" ? "-" : roleInTR)

  const transactionsFiltered = !isUnderwriter ? transactions : activeIndex == "created" ? transactions.filter((trans) => trans.roleInTR == constants.TRANSACTION_ROLES.TRANSACTION_MANAGER) : transactions.filter((trans) => trans.roleInTR != constants.TRANSACTION_ROLES.TRANSACTION_MANAGER)
  return (
    <>
      <ConfirmDialog
        className=""
        style={{ maxWidth: "480px" }}
        header="Delete Transaction"
        //message={confirmationDialogBody(selectedWireData)}
        message="Are you sure you want to delete this transaction?"
        icon="pi pi-exclamation-triangle"
        accept={() => deleteTransaction()}
        acceptLabel="Delete"
        reject={() => setDisplayDeleteTransactionModal(false)}
        rejectLabel="Cancel"
        onHide={() => setDisplayDeleteTransactionModal(false)}
        visible={displayDeleteTransactionModal}
      />

      <div className="" style={{ display: "flex", flexDirection: "row" }}>
        <div className="column-tabs">
          {isUnderwriter && (
            <div className="transaction-all-container-tabs p-3 w-full">
              <div className="transaction-all-container-buttons rounded">
                <Button
                  className="transaction-all-tab-buttons"
                  onClick={() => {
                    setActiveIndex("created")
                    window.sessionStorage.removeItem("all_transactions_tab")
                  }}
                  style={{
                    backgroundColor: activeIndex == "created" ? "white" : "transparent",
                    opacity: activeIndex == "created" ? 1 : 0.5,
                    color: "#000F66",
                    borderWidth: 0,
                    boxShadow: "none",
                  }}
                >
                  Created
                </Button>
                <Button
                  className="transaction-all-tab-buttons"
                  onClick={() => {
                    setActiveIndex("invited")
                    window.sessionStorage.setItem("all_transactions_tab", "invited")
                  }}
                  style={{
                    backgroundColor: activeIndex == "invited" ? "white" : "transparent",
                    opacity: activeIndex == "invited" ? 1 : 0.5,
                    color: "#000F66",
                    borderWidth: 0,
                    boxShadow: "none",
                  }}
                >
                  Invited{" "}
                  {invitedCount ? (
                    <span
                      style={{
                        padding: "1px 5px",
                        backgroundColor: "rgb(217, 45, 32)",
                        borderRadius: "50px",
                        opacity: "0.7",
                        color: "white",
                        marginLeft: "5px",
                        fontSize: "10px",
                      }}
                    >
                      {invitedCount}
                    </span>
                  ) : null}
                </Button>
              </div>
            </div>
          )}
          <div className="filter-transactions">
            {/* <PanelMenu model={leftBarItems} className="w-full trViewPanelMenu" /> */}
            {leftBarItems.map((leftBarItem) => {
              return (
                <div className="leftBarItems">
                  <ul className="leftBarItems_items list-group list-group-flush">
                    <li className={`flex align-items-center py-1 ${selectedStatus === "All" ? "active" : ""}`}>
                      <div class="w-3">
                        <span className="p-menuitem-icon pi pi-fw pi-th-large ml-4"></span>
                      </div>
                      <button className="py-2 px-2" onClick={leftBarItem.command}>
                        {leftBarItem.label}
                      </button>
                    </li>

                    {leftBarItem.items.map((item) => {
                      return (
                        <li className={`${activeIndex === "invited" && (item.label === "Draft" || item.label === "Deleted") ? "hidden" : "flex"} align-items-center py-1 ${selectedStatus === item.label ? "active" : ""}`}>
                          <div class="w-3">{/* <span className="p-menuitem-icon pi pi-fw pi-th-large ml-4"></span> */}</div>
                          <button className={`py-2 px-2`} onClick={item.command}>
                            {item.label}
                          </button>
                        </li>
                      )
                    })}
                  </ul>
                  {/* <div>{selectedStatus}</div> */}
                </div>
              )
            })}
          </div>
        </div>
        <div className="table-demo border-left-2" style={{ width: "100%", minHeight: "calc(100vh - 66px)" }}>
          {openTransactionForm === true && (
            <Dialog visible={true} style={{ width: "40vw" }} header="New Transaction" onHide={() => setOpenTransactionForm(false)}>
              <TransactionForm onTransactionCreated={(data) => onTransactionCreated(data)} setOpenTransactionForm={setOpenTransactionForm} />
            </Dialog>
          )}

          <Dialog visible={openTransactionSummary} style={{ maxWidth: "40vw" }} header="Transaction Details" onHide={() => setOpenTransactionSummary(false)}>
            <TransactionSummary
              transaction={selectedTransactionData}
              onTransactionUpdated={onTransactionUpdated}
              setOpenTransactionForm={setOpenTransactionSummary}
              setDisplayDeleteTransactionModal={setDisplayDeleteTransactionModal}
              setSelectedTrId={setSelectedTrId}
              setOpenTransactionSummary={setOpenTransactionSummary}
            />
          </Dialog>
          <div className="w-full" style={{ borderRadius: 0 }}>
            {transactions && (
              <DataTable
                value={transactionsFiltered}
                filters={filters}
                paginator={false}
                className="p-datatable-gridlines transaction-index"
                showGridlines
                rows={50}
                dataKey="id"
                loading={loading}
                responsiveLayout="scroll"
                globalFilterFields={["issuer", "transactionName", "type"]}
                emptyMessage="No transactions found."
                style={{ borderRadius: 0, minHeight: 500 }}
                onRowClick={(rowData) => {
                  navigate(`/transactions/${rowData.data.id}`)
                }}
                header={header}
              >
                <Column field="issuer" header="ISSUER" headerStyle={{ backgroundColor: "#F9FAFB", color: "#344054", alignItems: "center", width: "18%" }} />
                <Column field="transactionName" header="TRANSACTION NAME" headerStyle={{ backgroundColor: "#F9FAFB", color: "#344054", width: "18%" }} body={(rowData) => <div style={{ maxWidth: "19rem" }}>{rowData.transactionName}</div>} />
                <Column field="type" header="METHOD" headerStyle={{ backgroundColor: "#F9FAFB", color: "#344054", width: "14%" }} />
                <Column field="closingDate" header="CLOSING DATE" body={closingDateTemplate} headerStyle={{ backgroundColor: "#F9FAFB", color: "#344054", width: "15%" }} />
                <Column header="PAR AMOUNT" dataType="numeric" body={amountBodyTemplate} headerStyle={{ backgroundColor: "#F9FAFB", color: "#344054", width: "15%" }} />
                <Column field="status" header="STATUS" body={statusBodyTemplate} headerStyle={{ backgroundColor: "#F9FAFB", color: "#344054", width: "10%" }} />
                <Column header="ACTIONS" body={actionsBodyTemplate} headerStyle={{ backgroundColor: "#F9FAFB", color: "#344054", width: "10%" }} />
              </DataTable>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TransactionsIndex
