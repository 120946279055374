import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { Button } from "primereact/button"
import { Divider } from "primereact/divider"
import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password"
import AccountService from "service/AccountService"
import InputPhone from "components/Inputs/InputPhone"
import { useToast } from "hooks/useToast"
import { emailRegex, mediumPasswordRegex, strongPasswordRegex, trimObjectValues } from "utilities/format"
import munisafeLogo from "assets/login-logo.svg"
import textureImg from "assets/texture.svg"

const passwordFooter = (
  <>
    <Divider />
    <p className="mt-2">Requirements</p>
    <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
      <li>At least one lowercase</li>
      <li>At least one uppercase</li>
      <li>At least one numeric</li>
      <li>At least one special character</li>
      <li>Minimum 10 characters</li>
    </ul>
  </>
)

export const Register = () => {
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const inviteEmail = searchParams.get("inviteEmail")

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      title: "",
      email: inviteEmail ?? "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    reValidateMode: "onChange",
  })
  const password = watch("password")

  const accountService = new AccountService()

  const onSubmit = (event) => {
    setIsLoading(true)

    const body = trimObjectValues({
      firstName: event.firstName,
      // lastName: event.lastName,
      // title: event.title,
      email: event.email,
      phone: event.phone.replace(/[^\d]/g, ""),
      password: event.password,
    })

    accountService
      .register(body)
      .then(() => {
        setTimeout(() => {
          toast.current.show({
            severity: "success",
            summary: "Registration was successful",
            detail: "Please review your email inbox. An email was sent to verify your new account.",
            sticky: true,
          })
        }, 500)
        navigate("/")
      })
      .catch((error) => {
        // Email mismatch
        if (error.response.status === 400) {
          toast.current.show({
            severity: "error",
            summary: error.response.data,
            sticky: true,
          })
        }
        // Email already registered
        if (error.response.status === 403) {
          toast.current.show({
            severity: "error",
            summary: error.response.data,
            life: 5000,
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className="register-body">
      <div className="login-header">
        {/* <img src={munisafeLogo} alt="munisafe-logo" className="login-logo" /> */}
        {/* <p className="login-header-text">
          Empower Your Municipal Bond Closings with Assurance!
          <br />
          Experience Effortless Streamlining on the Utmost Secure Platform.
        </p> */}
      </div>

      <div className="grid paddin-x-30 align-items-center justify-content-center">
        {/* <div className="col-12 md:col-6">
          <div className="right-line">
            <div className="greetings">
              <div style={{ background: `url(${textureImg})` }} className="inner-greetings">
                <h4>Hi Ryne</h4>
                <p>
                  Join us to secure <span>San Francisco County</span> municipal bond closings. Sign up to view the transaction.
                </p>
                <div className="author">
                  <p>
                    <span>Dale Scott</span>, Dale Scott & Company.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-12 md:col-6" style={{ margin: "0 auto;" }}>
          <div className="card registerCard">
            <h2 className="signup-title">Sign Up</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid formgrid grid">
              <div className="spacer" />

              <div className="field col-12 ">
                <label htmlFor="firstName">Full Name</label>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Required field",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="relative">
                      <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Type here" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                      <br />
                      {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                    </div>
                  )}
                />
              </div>
              {/* <div className="field col-12 md:col-5">
                <label htmlFor="lastName">Last Name</label>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Required field",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="relative">
                      <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Last Name" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                      <br />
                      {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                    </div>
                  )}
                />
              </div> */}

              {/* <div className="spacer" /> */}

              {/* <div className="field col-12 md:col-5 col-offset-1">
                <label htmlFor="title">Title</label>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Required field",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="relative">
                      <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Title" value={field.value} onChange={(e) => field.onChange(e.target.value.trimStart())} />
                      <br />
                      {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                    </div>
                  )}
                />
              </div> */}
              <div className="field col-12 ">
                <label htmlFor="email">Official Email ID</label>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Required field",
                    },
                    pattern: {
                      value: emailRegex,
                      message: "Invalid format",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="relative">
                      <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" disabled={inviteEmail} placeholder="Email ID" value={inviteEmail ?? field.value} onChange={(e) => field.onChange(e.target.value.trim())} />
                      <br />
                      {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                    </div>
                  )}
                />
              </div>

              {/* <div className="spacer" /> */}

              <div className="field col-12 ">
                <label htmlFor="phone">Phone Number</label>
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Required field",
                    },
                    minLength: {
                      value: 14,
                      message: "Invalid phone number",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="relative">
                      <InputPhone id={field.name} value={field.value} error={fieldState.error} onChange={field.onChange} />
                      <br />
                      {fieldState.error && <span className="p-error absolute">{fieldState.error.message}</span>}
                    </div>
                  )}
                />
              </div>

              {/* <div className="spacer" /> */}

              <div className="field col-12">
                <label htmlFor="password">Password</label>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Required field",
                    },
                    minLength: {
                      value: 10,
                      message: "Minimum of 10 characters",
                    },
                    validate: {
                      containsLowerCase: (v) => /[a-z]/.test(v) || "Use at least one lower case",
                      containsUppercase: (v) => /[A-Z]/.test(v) || "Use at least one uppercase",
                      containsNumeric: (v) => /\d/.test(v) || "Use at least one numeric",
                      containsSpecialCharacter: (v) => /\W|_/.test(v) || "Use at least one special character",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="relative">
                      <Password
                        id={field.name}
                        toggleMask
                        inputClassName={fieldState.error ? "input-error" : "input-correct"}
                        placeholder="Password"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value.trim())}
                        footer={passwordFooter}
                        mediumRegex={mediumPasswordRegex}
                        strongRegex={strongPasswordRegex}
                      />

                      {fieldState.error && <span className="p-error absolute bottom-0">{fieldState.error.message}</span>}
                    </div>
                  )}
                />
              </div>

              <div className="field col-12">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Required field",
                    },
                    validate: (value) => value === password || "Passwords do not match",
                  }}
                  render={({ field, fieldState }) => (
                    <div className="relative">
                      <Password id={field.name} toggleMask feedback={false} inputClassName={fieldState.error ? "input-error" : "input-correct"} placeholder="Re-type Password" value={field.value} onChange={(e) => field.onChange(e.target.value.trim())} />

                      {fieldState.error && <span className="p-error absolute bottom-0">{fieldState.error.message}</span>}
                    </div>
                  )}
                />
              </div>

              {/* <div className="spacer" /> */}

              <div className="field col-12 md:col-12 ">
                <Button loading={isLoading} label="Submit" id="register-new-button" />
              </div>
            </form>
            <p className="privacy-policy">
              By proceeding, We agree to <a href="#">T&C</a>, <a href="#">Privacy Policy</a> & <a href="#">Tariff Rates</a>
            </p>
            <p id="already-login">
              Already have an account? <a href="/login">Sign In</a>
            </p>
          </div>
        </div>
      </div>
      <div class="login-footer">
        {/* <p className="nunderwriter">
          Not An Underwriter, <a href="/login">Go Back</a>
        </p> */}
        <ul class="footer-nav">
          <li>
            <Link to="#">Contact Us</Link>
          </li>
          <li>
            <Link to="#">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="#">Privacy policy</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
