import axios from "providers/ConfigureAxios"
import { getUrl } from "service/config/serviceUrls"

export default class AccountService {
  register(body) {
    return axios({
      method: "post",
      url: `${getUrl()}/account/register`,
      data: body,
    })
  }

  login(body) {
    return axios({
      method: "post",
      url: `${getUrl()}/account/login`,
      data: body,
    })
  }

  triggerOktaChallenge() {
    return axios({
      method: "GET",
      url: `${getUrl()}/api/authchallenge`,
    })
  }

  loginWithOktaToken(token) {
    return axios({
      method: "post",
      url: `${getUrl()}/api/validatetoken/validatetoken`,
      data: { token: token },
    })
  }

  verifyEmail(userId) {
    return axios({
      method: "get",
      url: `${getUrl()}/account/${userId}/verify-email`,
    })
  }

  sendPasswordResetLink(email) {
    return axios({
      method: "post",
      url: `${getUrl()}/account/password-reset-link`,
      data: {
        email,
      },
    })
  }

  resetPassword(headers, password) {
    return axios({
      method: "post",
      url: `${getUrl()}/account/reset-password`,
      headers,
      data: {
        password,
      },
    })
  }

  changePassword(headers, password, newPassword) {
    return axios({
      method: "post",
      url: `${getUrl()}/account/change-password`,
      headers,
      data: {
        password,
        newPassword,
      },
    })
  }
}
