import { useEffect, useMemo } from "react"
import * as SignalR from "@microsoft/signalr"
import { getUrl } from "service/config/serviceUrls"
import { useUser } from "hooks/useUser"
// import { useToast } from "hooks/useToast"

export const useNotificationsSocket = (onNotification) => {
  const { token } = useUser()

  const connection = useMemo(
    () =>
      token &&
      new SignalR.HubConnectionBuilder()
        .withUrl(`${getUrl()}/notification-updates`, {
          accessTokenFactory: () => token,
          skipNegotiation: true,
          transport: SignalR.HttpTransportType.WebSockets,
        })
        .configureLogging(SignalR.LogLevel.Information)
        .withAutomaticReconnect()
        .build(),
    [token]
  )

  // const toast = useToast()

  useEffect(() => {
    if (connection && connection?.state === SignalR.HubConnectionState.Disconnected) {
      connection
        .start()
        .then(() => {
          connection.invoke("AddToGroup")

          connection.on("UpdateNotifications", (notifications) => {
            onNotification("UpdateNotifications", notifications)
          })
          // toast.current?.show({ severity: "success", summary: notification, sticky: true })
        })
        .catch((err) => console.error("Connection error: ", err))
    }

    return () => {
      connection?.state === SignalR.HubConnectionState.Connected && connection.invoke("RemoveFromGroup").finally(() => connection.stop())
    }
  }, [connection, onNotification])
}
