import { useState } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { ConfirmDialog } from "primereact/confirmdialog"
import { ProgressSpinner } from "primereact/progressspinner"
import { Tag } from "primereact/tag"
import ParticipantNew from "./ParticipantNew"
import ParticipantService from "service/ParticipantsService"
import { useUser } from "hooks/useUser"
import constants from "constants/constants"
import trashIcon from "components/Icons/Trash"
import { formatPhoneNumber } from "utilities/format"
import { useNavigate } from "react-router-dom"
import noFilesImage from "../../../assets/images/Filesempty.png"
import { IoMdInformationCircleOutline } from "react-icons/io"
import { Tooltip } from "react-tooltip"
import { useToast } from "hooks/useToast"
import "react-tooltip/dist/react-tooltip.css"

const TransactionParticipantsGrid = ({ loading, transactionId, transactionStatus, participants, loadParticipants, roleInTR, loadTransactionTables }) => {
  const [removingParticipant, setRemovingParticipant] = useState(false)
  const [displayConfirmation, setDisplayConfirmation] = useState(false)
  const [showApproveConfirmation, setShowApproveConfirmation] = useState(false)
  const [participantId, setParticipantId] = useState(null)
  const [openNewParticipantForm, setOpenNewParticipantForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { token, userData, role } = useUser()
  const toast = useToast()
  const navigate = useNavigate()

  const selectedParticipant = participants.find(({ id }) => id === participantId)

  const participantService = new ParticipantService()

  const handleDeleteParticipantYes = async () => {
    setRemovingParticipant(true)
    const headers = {
      Authorization: `bearer ${token}`,
    }
    await participantService.delete(headers, participantId)

    if (selectedParticipant?.email === userData.email) {
      navigate("/")
      return
    }

    setDisplayConfirmation(false)
    setRemovingParticipant(false)
    loadParticipants()
  }

  const onParticipantAdded = (rowData) => {
    setOpenNewParticipantForm(false)
    loadTransactionTables()
  }

  const handleDeleteParticipantNo = () => {
    setDisplayConfirmation(false)
    setParticipantId(null)
  }

  const phoneBodyTemplate = (rowData) => nullableBodyTemplate(formatPhoneNumber(rowData.phone))

  const accessTemplate = (rowData) => {
    return (
      <div style={{ display: "flex" }}>
        <Tag
          style={{
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {rowData.status === "Pending" ? (
            <>Pending Approval</>
          ) : (
            <span className={`action-${rowData.roleCategory} ${rowData.roleCategory == "Primary" ? "FullAccess" : ""}`}>
              {rowData.roleCategory == "Primary" ? "Full Access" : rowData.roleCategory == "Secondary" ? "Limited Access" : "Restricted"}
              <span>
                <IoMdInformationCircleOutline className="ml-1 w-1rem h-1rem" data-tooltip-id={`tooltip-${rowData.roleCategory}`} />

                <Tooltip id="tooltip-Primary" content="User can see all wire details" />
                <Tooltip id="tooltip-Secondary" content="User can see limited wire details" />
                <Tooltip id="tooltip-Tertiary" content="User cannot see wire details" />
              </span>
            </span>
          )}
        </Tag>
      </div>
    )
  }

  const nullableBodyTemplate = (value) => value ?? "-"

  const addedOnBodyTemplate = ({ createdOn }) => new Date(`${createdOn}Z`).toLocaleDateString()

  const statusBodyTemplate = (rowData) => {
    const status = rowData.status === constants.PARTICIPANT_STATUS.ACCEPTED ? "success" : rowData.status === constants.PARTICIPANT_STATUS.INVITED ? "info" : "danger"
    const icon = "pi pi-fw " + (rowData.status === constants.PARTICIPANT_STATUS.ACCEPTED ? "pi-check" : rowData.status === constants.PARTICIPANT_STATUS.INVITED ? "pi-envelope" : "pi-times")
    return <Tag severity={status} icon={icon} value={rowData.status} />
  }
  const AddParticipant = () => {
    return (
      <>
        {participants.length == 0 ? (
          <div className="transaction-view-container-image-empty">
            <img className="transaction-view-image-empty" src={noFilesImage} alt="desc" />
            <p>No participant added yet.</p>
            <Button onClick={() => setOpenNewParticipantForm(true)} label="Add Participant" className="transaction-view-button-add" icon="pi pi-plus" />
          </div>
        ) : (
          <></>
        )}
      </>
    )
  }

  const actionsBodyTemplate = (rowData) => {
    const { id, email } = rowData
    return (
      <div style={{ flexDirection: "row" }} className="gridActionButtons">
        {rowData.status === "Pending" ? (
          <Button
            label="View Request"
            text
            onClick={() => {
              setParticipantId(id)
              setShowApproveConfirmation(true)
            }}
          />
        ) : (
          <Button
            label="Edit"
            text
            onClick={() => {
              setParticipantId(id)
              setDisplayConfirmation(true)
            }}
          />
        )}
        {transactionStatus === constants.TRANSACTION_STATUS.OPEN && (roleInTR === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER || userData.email === email) && (
          <Button
            label="Delete"
            text
            onClick={() => {
              setParticipantId(id)
              setDisplayConfirmation(true)
            }}
          />
        )}
      </div>
    )
  }
  const sortParticipants = (participants) => {
    const access = { Primary: 1, Secondary: 2, Tertiary: 3 }
    const sortedParticipants = participants.sort((a, b) => access[a.roleCategory] - access[b.roleCategory])
    return sortedParticipants
  }

  const actionParticipantRequest = async (action) => {
    const headers = {
      Authorization: `bearer ${token}`,
    }
    setIsLoading(true)
    try {
      await participantService.updateParticipantRequest(headers, participantId, action, transactionId)
      toast.current.show({ severity: "success", summary: action == 1 ? "Approved" : "Rejected", detail: "Participant request updated", sticky: true })
      loadParticipants()
      setParticipantId(null)
      setShowApproveConfirmation(false)
    } catch (error) {
      if (error.response.status != 200) {
        toast.current.show({ severity: "error", summary: "Error", detail: error.response.data, sticky: true })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && <ProgressSpinner />}
      <div className="col-12 pt-0" id="participants-section">
        {participantId && selectedParticipant && (
          <>
            <ConfirmDialog
              style={{ maxWidth: "29vw" }}
              header={selectedParticipant.status === constants.PARTICIPANT_STATUS.INVITED ? "Withdraw invitation" : "Remove participant"}
              message={
                selectedParticipant.status === constants.PARTICIPANT_STATUS.INVITED
                  ? `You are about to withdraw the invitation sent to ${selectedParticipant.email} to be the ${selectedParticipant.role} in this transaction. After that, if you want the same user to be the ${selectedParticipant.role}, you will need to send the invitation again.`
                  : `You are about to remove ${selectedParticipant.name} as the ${selectedParticipant.role} from this transaction.`
              }
              icon="pi pi-exclamation-triangle"
              accept={handleDeleteParticipantYes}
              acceptLabel={selectedParticipant.status === constants.PARTICIPANT_STATUS.INVITED ? "Withdraw" : "Remove"}
              reject={handleDeleteParticipantNo}
              rejectLabel="Cancel"
              visible={displayConfirmation}
              onHide={() => setDisplayConfirmation(false)}
            />
            <Dialog visible={showApproveConfirmation} style={{ width: "30vw" }} header="Participant Request" onHide={() => setShowApproveConfirmation(false)}>
              <div style={{ paddingLeft: "28px", paddingRight: "28px" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 10 }}>
                  <div className="row-2" id="row-principal-confirm-wire">
                    <label className="new-confirm-wire-label">Participant Email</label>
                    <span className="new-confirm-wire-value-1">{selectedParticipant.email}</span>
                  </div>
                  <div className="row-2" id="row-principal-confirm-wire">
                    <label className="new-confirm-wire-label">Referred By</label>
                    <span className="new-confirm-wire-value-1">{selectedParticipant.createdBy}</span>
                  </div>
                </div>
                <div className="flex justify-content-center new-wire-confirm-buttons">
                  <Button
                    onClick={(e) => {
                      actionParticipantRequest(0)
                    }}
                    className="new-wire-confirm-edit"
                    disabled={isLoading}
                  >
                    Reject Request
                  </Button>
                  <Button
                    onClick={(e) => {
                      actionParticipantRequest(1)
                    }}
                    disabled={isLoading}
                    loading={isLoading}
                    className="new-wire-confirm-submit"
                  >
                    Approve Participant
                  </Button>
                </div>
              </div>
            </Dialog>
          </>
        )}
        {participants && participants.length > 0 && (
          <>
            <DataTable loading={loading} value={sortParticipants(participants)} className="p-datatable-gridlines" showGridlines rows={10} dataKey="id" responsiveLayout="scroll" emptyMessage="No participants found.">
              <Column field="name" body={({ name }) => nullableBodyTemplate(name)} header="NAME" style={{ minWidth: "12rem" }} headerStyle={{ backgroundColor: "#f9fafb" }} />
              <Column field="email" header="RECIPIENT EMAIL" style={{ minWidth: "12rem" }} headerStyle={{ backgroundColor: "#f9fafb" }} />
              <Column field="phone" body={phoneBodyTemplate} header="CONTACT" style={{ minWidth: "12rem" }} headerStyle={{ backgroundColor: "#f9fafb" }} />
              <Column field="organization" body={({ organization }) => nullableBodyTemplate(organization)} header="ORGANIZATION" style={{ minWidth: "12rem" }} headerStyle={{ backgroundColor: "#f9fafb" }} />
              <Column field="role" header="ROLE" style={{ minWidth: "12rem" }} headerStyle={{ backgroundColor: "#f9fafb" }} />
              <Column header="ACCESS" style={{ minWidth: "12rem" }} body={accessTemplate} headerStyle={{ backgroundColor: "#f9fafb", padding: "0 1.8rem" }} />
              {roleInTR === constants.TRANSACTION_ROLES.TRANSACTION_MANAGER && <Column header="ACTIONS" body={actionsBodyTemplate} style={{ minWidth: "12rem" }} headerStyle={{ backgroundColor: "#f9fafb", padding: "0 2rem" }} />}
            </DataTable>
          </>
        )}
        {openNewParticipantForm === true && (
          <Dialog visible={true} style={{ width: "60vw" }} header="New Participant" onHide={() => setOpenNewParticipantForm(false)}>
            <ParticipantNew transactionId={transactionId} onParticipantAdded={() => onParticipantAdded()} />
          </Dialog>
        )}
      </div>
      <div>{participants.length == 0 && <AddParticipant />}</div>
    </>
  )
}

export default TransactionParticipantsGrid
