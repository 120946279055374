import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "primereact/button"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { useUser } from "hooks/useUser"
import InvoiceService from "service/InvoiceService"
import eyeIcon from "components/Icons/Eye"

const InvoicesIndex = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [invoices, setInvoices] = useState([])

  const { token } = useUser()

  const navigate = useNavigate()

  const invoiceService = new InvoiceService()

  useEffect(() => {
    loadTransactions()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const loadTransactions = () => {
    const header = { Authorization: `bearer ${token}` }
    setIsLoading(true)
    invoiceService.getAll(header).then(({ data }) => {
      setInvoices(data)
      setIsLoading(false)
    })
  }

  const actionsBodyTemplate = (rowData) => (
    <Button icon={<i className="flex mr-1">{eyeIcon}</i>} label="View" title="View this invoice" tooltip="View this invoice" tooltipOptions={{ position: "left" }} className="p-button-action p-button-raised" style={{ marginRight: ".5em" }} onClick={() => navigate("/invoices/" + rowData.id)} />
  )

  const createdOnTemplate = (row) => <span>{new Date(row.createdOn).toLocaleDateString()}</span>

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          {invoices && (
            <DataTable value={invoices} paginator className="p-datatable-gridlines" showGridlines rows={10} dataKey="id" filterDisplay="menu" loading={isLoading} responsiveLayout="scroll" emptyMessage="No invoices found.">
              <Column field="id" header="Invoice #" filter filterPlaceholder="Search by number" style={{ minWidth: "12rem" }} />
              <Column field="invoiceToName" header="Name" filterPlaceholder="Search by name" style={{ minWidth: "12rem" }} />
              <Column field="invoiceToEmail" header="Email" filter filterPlaceholder="Search by email" style={{ minWidth: "12rem" }} />
              <Column field="description" header="Description" style={{ minWidth: "12rem" }} />
              <Column field="createdOn" header="Date" body={createdOnTemplate} style={{ minWidth: "7rem" }} />
              <Column header="Actions" style={{ minWidth: "7rem" }} body={actionsBodyTemplate} />
            </DataTable>
          )}
        </div>
      </div>
    </div>
  )
}

export default InvoicesIndex
