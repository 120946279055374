import constants from "constants/constants"
import { UserContext } from "Context/UserContext"
import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import UserStorage from "storage/userStorage"
import { useOktaAuth } from "@okta/okta-react"

const UserProvider = ({ children, awsRum }) => {
  const userStorage = useMemo(() => new UserStorage(), [])

  const [token, setToken] = useState(() => userStorage.getToken())
  const [userData, setUserData] = useState(() => userStorage.getUserData())
  const [role, setRole] = useState(() => userStorage.getRole())
  const navigate = useNavigate()
  const { oktaAuth, authState } = useOktaAuth()
  const value = useMemo(
    () => ({
      setToken(token) {
        userStorage.setToken(token)
        setToken(token)
      },
      setUserData(data) {
        setUserData((userData) => {
          const newUserData = { ...userData, ...data }
          userStorage.setUserData(newUserData)
          return newUserData
        })
      },
      setRole(role) {
        const isInvalidRole = !Object.values(constants.ROLES).includes(role)

        if (isInvalidRole) {
          throw new Error(`Invalid role: ${role}`)
        }

        userStorage.setRole(role)
        setRole(role)
      },
      token,
      userData,
      role,
      isUnderwriter: userData ? userData.isUnderwriter : false,
      clear() {
        if (authState?.isAuthenticated) {
          oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + "/login", idToken: authState.idToken, revokeAccessToken: true, clearTokensBeforeRedirect: true })
        }
        console.log("after okta signout")
        setToken(null)
        setUserData(null)
        setRole(null)
        userStorage.clear()
        localStorage.clear()
        console.log("after okta signout")
      },
      awsRum,
    }),
    [role, token, userData, userStorage]
  )
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserProvider
