import * as Sentry from "@sentry/react"
import { ConfigureAxios } from "providers/ConfigureAxios"
import ToastProvider from "providers/ToastProvider"
import TrackerProvider from "providers/TrackerProvider"
import UserProvider from "providers/UserProvider"
import React, { useEffect } from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"
import AppWrapper from "./AppWrapper"
import reportWebVitals from "./reportWebVitals"
import { OktaAuth } from "@okta/okta-auth-js"
import { Security } from "@okta/okta-react"
import { AwsRum } from "aws-rum-web"

Sentry.init({
  debug: process.env.REACT_APP_SENTRY_DEBUG === "true",
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
    }),
    new Sentry.Replay(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // Adjust this value in production
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error.
  replaysSessionSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
  replaysOnErrorSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
})
const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENTID,
  redirectUri: window.location.origin + "/login/callback",
})
// const history = useHistory()
const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  console.log("login success", _oktaAuth, originalUri)
  window.location.href = `${window.location.origin}/${originalUri ? originalUri : ""}`
}

//init aws rum
var awsRum
try {
  const config = {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::115035649791:role/RUM-Monitor-us-east-1-115035649791-4150937721171-Unauth",
    identityPoolId: "us-east-1:c76060ad-7652-4eec-9e75-1341770ea0c1",
    endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
    telemetries: ["performance", "errors", "http"],
    allowCookies: true,
    enableXRay: false,
  }

  const APPLICATION_ID = "b23e420b-1a14-456c-8085-11529918667e"
  const APPLICATION_VERSION = "1.0.0"
  const APPLICATION_REGION = "us-east-1"

  awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config)
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastProvider>
        <ConfigureAxios>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <UserProvider awsRum={awsRum}>
              <TrackerProvider>
                <AppWrapper />
              </TrackerProvider>
            </UserProvider>
          </Security>
        </ConfigureAxios>
      </ToastProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
