export default class UserStorage {
  #TOKEN_KEY = "token"
  #USER_DATA_KEY = "user_data"
  #ROLE_KEY = "role"

  setToken(token) {
    localStorage.setItem(this.#TOKEN_KEY, JSON.stringify(token))
  }

  getToken() {
    return localStorage.getItem(this.#TOKEN_KEY) === 'undefined' ? {} : JSON.parse(localStorage.getItem(this.#TOKEN_KEY))
  }

  setUserData(userData) {
    localStorage.setItem(this.#USER_DATA_KEY, JSON.stringify(userData))
  }

  getUserData() {
    return JSON.parse(localStorage.getItem(this.#USER_DATA_KEY))
  }

  setRole(role) {
    localStorage.setItem(this.#ROLE_KEY, JSON.stringify(role))
  }

  getRole() {
    return JSON.parse(localStorage.getItem(this.#ROLE_KEY))
  }
	
	clear() {
		localStorage.removeItem(this.#TOKEN_KEY)
    localStorage.removeItem(this.#USER_DATA_KEY)
    localStorage.removeItem(this.#ROLE_KEY)
	}
}
