
import { Header } from "./Header"

export const HomePage = () => {
  


  return (
    <div>
      <Header />
      <div class="faq-body">
          
          <h3>Frequently Asked Questions</h3>


          <div className="qna-container">
                <h3>Why is muniSafe needed?</h3>
                <p>For many years, municipal finance professionals have used email to share sensitive closing information for financings. In today's world, email is no longer secure and its use increases the risk of cyber theft.</p>
                <h3>How does muniSafe work?</h3>
                <p>For many years, municipal finance professionals have used email to share sensitive closing information for financings. In today's world, email is no longer secure and its use increases the risk of cyber theft.</p>
                <h3>How much does muniSafe cost?</h3>
                <p>For many years, municipal finance professionals have used email to share sensitive closing information for financings. In today's world, email is no longer secure and its use increases the risk of cyber theft.</p>
                <h3>How are fees to be paid?</h3>
                <p>For many years, municipal finance professionals have used email to share sensitive closing information for financings. In today's world, email is no longer secure and its use increases the risk of cyber theft.</p>
                <h3>Will a summary of the transaction be available after the close?</h3>
                <p>For many years, municipal finance professionals have used email to share sensitive closing information for financings. In today's world, email is no longer secure and its use increases the risk of cyber theft.</p>
          </div>
      

      
      </div>
    </div>
  )
}


