import InvitationsReceivedTable from "./InvitationsReceivedTable";
import InvitationsSentTable from "./InvitationsSentTable";

export default function InvitationsIndex() {
  return (
    <>
      <InvitationsReceivedTable />
      {/* <InvitationsSentTable /> */}
    </>
  )
}
