import { useCallback, useEffect, useMemo, useState } from "react"
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { ConfirmDialog } from "primereact/confirmdialog"
import { DataTable } from "primereact/datatable"
import { Tag } from "primereact/tag"
import InvitationsService from "service/InvitationsService"
import { formatPhoneNumber } from "utilities/format"
import { useUser } from "hooks/useUser"
import { useToast } from "hooks/useToast"
import constants from "constants/constants"
import WiresService from "service/WiresService"
import { Dialog } from "primereact/dialog"
import AccountDetailsForm from "components/Accounts/AccountDetailsForm"

export default function InvitationsReceivedTable({ hideInvitationsHistory = false }) {
  const [invites, setInvites] = useState([])
  const [loadingInvites, setLoadingInvites] = useState(true)
  const [selectedInvite, setSelectedInvite] = useState(null)
  const [loadingAcceptingInvite, setLoadingAcceptingInvite] = useState(false)
  const [loadingRejectingInvite, setLoadingRejectingInvite] = useState(false)
  const [displayAcceptInviteConfirmation, setDisplayAcceptInviteConfirmation] = useState(false)
  const [displayRejectInviteConfirmation, setDisplayRejectInviteConfirmation] = useState(false)
  const [openAccountDetailsForm, setOpenAccountDetailsForm] = useState(false)

  const {invitesInvited, invitesNonInvited} = useMemo(()=>{
    // (rowData.status === constants.WIRE_STATUS.INVITED || rowData.status === constants.PARTICIPANT_STATUS.INVITED) && (
      
      let invitesInvited=[]; 
      let invitesNonInvited= [];
      for(let invite of invites){
        if( invite.status === constants.WIRE_STATUS.INVITED || invite.status === constants.PARTICIPANT_STATUS.INVITED ) {
          invitesInvited.push(invite);
        } else {
          invitesNonInvited.push(invite);
        }
      }

    return {
      invitesInvited, invitesNonInvited
    }
  },[invites])

  const { token, userData } = useUser()
  const headers = useMemo(
    () => ({
      Authorization: "bearer " + token,
    }),
    [token]
  )

  const toast = useToast()

  const invitationsService = useMemo(() => new InvitationsService(), [])
  const wireService = useMemo(() => new WiresService(), []);

  useEffect(() => {
    setLoadingInvites(true)

    Promise.all([invitationsService.getWireInvites(headers, "received"), invitationsService.getParticipantInvites(headers, "received")])
      .then(([{ data: wireInvites }, { data: participantInvites }]) => {
        const invites = [...wireInvites, ...participantInvites]

        invites.sort(({ createdOn: createdOnA }, { createdOn: createdOnB }) => new Date(createdOnB).getTime() - new Date(createdOnA).getTime())

        setInvites(invites)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setLoadingInvites(false)
      })
  }, [headers, invitationsService, userData.email])

  const tableHeader = (
    <div className="table-header-container">
      <h3 className="text-left mb-0">Invitations Received</h3>
    </div>
  )
  const tableHeader2 = (
    <div className="table-header-container">
      <h3 className="text-left mb-0">Invitations History</h3>
    </div>
  )

  const phoneBodyTemplate = useCallback(({ senderPhone }) => formatPhoneNumber(senderPhone), [])

  const typeBodyTemplate = useCallback(({ type }) => type.replace("Invite", ""), [])

  const createdOnBodyTemplate = useCallback(({ createdOn }) => new Date(createdOn + "Z").toLocaleDateString(), [])

  const statusBodyTemplate = useCallback((rowData) => {
    const invitationAccepted = rowData.status === constants.PARTICIPANT_STATUS.ACCEPTED || rowData.status === constants.WIRE_STATUS.ACCEPTED || rowData.status === constants.WIRE_STATUS.RECEIVED
    const invitationPending = rowData.status === constants.PARTICIPANT_STATUS.INVITED || rowData.status === constants.WIRE_STATUS.INVITED

    const status = invitationAccepted ? "success" : invitationPending ? "info" : "danger"
    const icon = "pi pi-fw " + (invitationAccepted ? "pi-check" : invitationPending ? "pi-envelope" : "pi-times")
    const label = invitationAccepted ? "Accepted" : invitationPending ? "Invited" : "Rejected"

    return <Tag severity={status} icon={icon} value={label} />
  }, [])

  const actionsBodyTemplate = useCallback(
    (rowData) => {
      return (
        <div className="flex flex-wrap gap-2">
          {(rowData.status === constants.WIRE_STATUS.INVITED || rowData.status === constants.PARTICIPANT_STATUS.INVITED)  && (
            <>
           {rowData.type.toLowerCase() !== 'participantinvite' && <Button
                icon="pi pi-fw pi-check"
                label="View Account Details"
                title="View Account Details"
                tooltip="View Account Details"
                tooltipOptions={{ position: "left" }}
                className="p-button-action p-button-raised"
                onClick={() => {
                  console.log('view account details clicked', rowData);
                  setSelectedInvite(rowData);
                  setOpenAccountDetailsForm(true);

                }}
                
                
              />}
              <Button
                icon="pi pi-fw pi-check"
                label="Accept"
                title="Accept this invitation"
                tooltip="Accept this invitation"
                tooltipOptions={{ position: "left" }}
                className="p-button-action p-button-raised"
                onClick={() => {
                  setLoadingAcceptingInvite(true)
                  setSelectedInvite(rowData)
                  setDisplayAcceptInviteConfirmation(true)
                }}
                loading={rowData.id === selectedInvite?.id && loadingAcceptingInvite}
                disabled={rowData.id === selectedInvite?.id && loadingRejectingInvite}
              />
              <Button
                icon="pi pi-fw pi-times"
                label="Reject"
                title="Reject this invitation"
                tooltip="Reject this invitation"
                tooltipOptions={{ position: "left" }}
                className="p-button-danger p-button-raised"
                onClick={() => {
                  setLoadingRejectingInvite(true)
                  setSelectedInvite(rowData)
                  setDisplayRejectInviteConfirmation(true)
                }}
                loading={rowData.id === selectedInvite?.id && loadingRejectingInvite}
                disabled={rowData.id === selectedInvite?.id && loadingAcceptingInvite}
              />
            </>
          )}
        </div>
      )
    },
    [loadingAcceptingInvite, loadingRejectingInvite, selectedInvite?.id]
  )

  const handleResolveInvite = useCallback(
    (inviteId, inviteType, resolve) => {
      switch (inviteType) {
        case "ParticipantInvite":
          if (resolve === "accept") {
            invitationsService
              .resolveParticipantInvite(headers, inviteId, "Accepted")
              .then(() => {
                setInvites((invites) => invites.map((invite) => (invite.id === selectedInvite.id ? { ...invite, status: "Accepted" } : invite)))
                toast.current.show({ severity: "success", summary: "Participant invitation accepted", detail: `The invitation sender ${selectedInvite.senderName} will be notified by email`, sticky: true })
              })
              .catch((error) => toast.current.show({ severity: "error", summary: "Can't accept participant invitation", detail: error.response.data, sticky: true }))
              .finally(() => {
                setDisplayAcceptInviteConfirmation(false)
                setLoadingAcceptingInvite(false)
              })
          } else if (resolve === "reject") {
            invitationsService
              .resolveParticipantInvite(headers, inviteId, "Rejected")
              .then(() => {
                setInvites((invites) => invites.map((invite) => (invite.id === selectedInvite.id ? { ...invite, status: "Rejected" } : invite)))
                toast.current.show({ severity: "success", summary: "Participant invitation rejected", detail: `The invitation sender ${selectedInvite.senderName} will be notified by email`, sticky: true })
              })
              .catch((error) => toast.current.show({ severity: "error", summary: "Can't reject participant invitation", detail: error.response.data, sticky: true }))
              .finally(() => {
                setDisplayRejectInviteConfirmation(false)
                setLoadingRejectingInvite(false)
              })
          }
          break

        case "WireInvite":
          if (resolve === "accept") {
            invitationsService
              .resolveWireInvite(headers, inviteId, "Accepted")
              .then(() => {
                setInvites((invites) => invites.map((invite) => (invite.id === selectedInvite?.id ? { ...invite, status: "Accepted" } : invite)))
                toast.current.show({ severity: "success", summary: "Wire invitation accepted", detail: `The invitation sender ${selectedInvite?.senderName} will be notified by email`, sticky: true })
              })
              .catch((error) => toast.current.show({ severity: "error", summary: "Can't accept wire", detail: error.response.data, sticky: true }))
              .finally(() => {
                setDisplayAcceptInviteConfirmation(false)
                setLoadingAcceptingInvite(false)
              })
          } else if (resolve === "reject") {
            invitationsService
              .resolveWireInvite(headers, inviteId, "Rejected")
              .then(() => {
                setInvites((invites) => invites.map((invite) => (invite.id === selectedInvite?.id ? { ...invite, status: "Rejected" } : invite)))
                toast.current.show({ severity: "success", summary: "Wire invitation rejected", detail: `The invitation sender ${selectedInvite?.senderName} will be notified by email`, sticky: true })
              })
              .catch((error) => toast.current.show({ severity: "error", summary: "Can't reject wire", detail: error.response.data, sticky: true }))
              .finally(() => {
                setDisplayRejectInviteConfirmation(false)
                setLoadingRejectingInvite(false)
              })
          }
          break

        default:
          break
      }
    },
    [headers, toast, selectedInvite?.senderName, selectedInvite?.id, invitationsService]
  )

  const handleAcceptInviteYes = useCallback(() => {
    handleResolveInvite(selectedInvite.id, selectedInvite.type, "accept")
  }, [handleResolveInvite, selectedInvite?.id, selectedInvite?.type])

  const handleAcceptInviteNo = useCallback(() => {
    setSelectedInvite(null)
    setLoadingAcceptingInvite(false)
    setDisplayAcceptInviteConfirmation(false)
  }, [])

  const handleRejectInviteYes = useCallback(() => {
    handleResolveInvite(selectedInvite.id, selectedInvite.type, "reject")
  }, [handleResolveInvite, selectedInvite?.id, selectedInvite?.type])

  const handleRejectInviteNo = useCallback(() => {
    setSelectedInvite(null)
    setLoadingRejectingInvite(false)
    setDisplayRejectInviteConfirmation(false)
  }, [])

  return (
    <>
      {selectedInvite && (
        <>
          <ConfirmDialog
            style={{ maxWidth: "50vw" }}
            header="Accept invitation"
            message={`You are about to accept the ${selectedInvite.type.replace("Invite", "").toLowerCase()} invitation sent from ${selectedInvite.senderName}`}
            icon="pi pi-exclamation-triangle"
            closable={false}
            accept={handleAcceptInviteYes}
            acceptLabel="Accept"
            reject={handleAcceptInviteNo}
            rejectLabel="Go back"
            visible={displayAcceptInviteConfirmation}
          />

          <ConfirmDialog
            style={{ maxWidth: "50vw" }}
            header="Reject invitation"
            message={`You are about to reject the ${selectedInvite.type.replace("Invite", "").toLowerCase()} invitation sent from ${selectedInvite.senderName}`}
            icon="pi pi-exclamation-triangle"
            closable={false}
            accept={handleRejectInviteYes}
            acceptLabel="Reject"
            reject={handleRejectInviteNo}
            rejectLabel="Go back"
            visible={displayRejectInviteConfirmation}
          />
          <Dialog visible={openAccountDetailsForm} style={{ width: "60vw" }} header="Account Details" onHide={() => setOpenAccountDetailsForm(false)}>
        <AccountDetailsForm  wireInfo={selectedInvite}  />
      </Dialog>
        </>
      )}

      <div className="grid table-demo">
        <div className="col-12">
          {invitesInvited && (
            <DataTable value={invitesInvited} paginator className="p-datatable-gridlines" showGridlines rows={10} dataKey="id" filterDisplay="menu" 
             loading={loadingInvites}
             responsiveLayout="scroll" emptyMessage="No invitations found." header={tableHeader}>
              <Column field="senderName" header="Name" style={{ minWidth: "12rem" }} />
              <Column field="senderPhone" header="Phone" body={phoneBodyTemplate} style={{ minWidth: "12rem" }} />
              <Column field="senderEmail" header="Email" style={{ minWidth: "12rem" }} />
              <Column field="type" header="Type" body={typeBodyTemplate} style={{ minWidth: "12rem" }} />
              <Column field="role" header="Role" style={{ minWidth: "12rem" }} />
              <Column field="createdOn" header="Created On" style={{ minWidth: "7rem" }} body={createdOnBodyTemplate} />
              <Column field="status" header="Status" body={statusBodyTemplate} style={{ minWidth: "7rem" }} />
              <Column header="Actions" body={actionsBodyTemplate} />
            </DataTable>
          )}
        </div>
      </div>

      { !hideInvitationsHistory && <div className="grid table-demo">
        <div className="col-12">
          {invitesNonInvited && (
            <DataTable value={invitesNonInvited} paginator className="p-datatable-gridlines" showGridlines rows={10} dataKey="id" filterDisplay="menu" 
             loading={loadingInvites}
             responsiveLayout="scroll" emptyMessage="No invitations found." header={tableHeader2}>
              <Column field="senderName" header="Name" style={{ minWidth: "12rem" }} />
              <Column field="senderPhone" header="Phone" body={phoneBodyTemplate} style={{ minWidth: "12rem" }} />
              <Column field="senderEmail" header="Email" style={{ minWidth: "12rem" }} />
              <Column field="type" header="Type" body={typeBodyTemplate} style={{ minWidth: "12rem" }} />
              <Column field="role" header="Role" style={{ minWidth: "12rem" }} />
              <Column field="createdOn" header="Created On" style={{ minWidth: "7rem" }} body={createdOnBodyTemplate} />
              <Column field="status" header="Status" body={statusBodyTemplate} style={{ minWidth: "7rem" }} />
              <Column header="Actions" body={actionsBodyTemplate} />
            </DataTable>
          )}
        </div>
      </div> }



    </>
  )
}
