import { useState, useEffect } from "react"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Tag } from "primereact/tag"
import OrganizationService from "service/OrganizationService"
import { useUser } from "hooks/useUser"

const OrganizationsIndex = () => {
  const [organizations, setOrganizations] = useState(null)
  const [filters, setFilters] = useState(null)
  const [loading, setLoading] = useState(true)

  const orgService = new OrganizationService()

  const { token } = useUser()

  useEffect(() => {
    const header = { Authorization: "bearer " + token }
    orgService.getOrganizations(header).then((data) => {
      setOrganizations(data.data)
      setLoading(false)
    })

    initFilters()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    })
  }

  function convertUTCDateToLocalDate(row) {  
    return (<span>
      {new Date(row.createdOn +"Z").toLocaleDateString()}
    </span>)
  }

  const statusBodyTemplate = (rowData) => {
    const status = rowData.status === "Active" ? "success" : "danger"
    const icon = "pi pi-fw " + (rowData.status === "Active" ? "pi-check-circle" : "pi-times-circle")
    return <Tag severity={status} icon={icon} value={rowData.status} />
  }

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          <DataTable value={organizations} paginator className="p-datatable-gridlines" showGridlines rows={10} dataKey="id" filters={filters} filterDisplay="menu" loading={loading} responsiveLayout="scroll" emptyMessage="No organizations / companies found.">
            <Column field="name" header="Name" filter filterPlaceholder="Search by name" style={{ minWidth: "12rem" }} />
            <Column field="createdOn" header="Created On" style={{ minWidth: "12rem" }}  body={convertUTCDateToLocalDate}/>
            <Column field="status" header="Status" body={statusBodyTemplate} style={{ minWidth: "12rem" }} />
            <Column field="usersNumber" header="# Users" style={{ minWidth: "12rem" }} />
            <Column field="transactionsNumber" header="# Transactions" style={{ minWidth: "12rem" }} />
            <Column field="wiresNumber" header="# Wires" style={{ minWidth: "12rem" }} />
          </DataTable>
        </div>
      </div>
    </div>
  )
}

export default OrganizationsIndex
