import { useEffect, useState } from "react"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { formatPhoneNumber, getCurrencyString } from "utilities/format"
import { useUser } from "hooks/useUser"
import constants from "constants/constants"

function HistoryChangesTable({ loading, historyChangesData }) {
  const [filters, setFilters] = useState(null)
  const [globalFilterValue, setGlobalFilterValue] = useState("")

  const { role } = useUser()

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      organizationName: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      entity: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      userName: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    })
    setGlobalFilterValue("")
  }

  const clearFilter = () => {
    initFilters()
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = { ...filters }
    _filters["global"].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const globalFilterFields = ["entityId", "parentEntityId", "userName", "userEmail", "userPhone", "entity", "fieldName", "oldValue", "newValue"]
  role === constants.ROLES.SUPER_ADMIN && (globalFilterFields.unshift("organizationName"))

  const formatValue = (value, fieldName) => {
    const field = fieldName.toLowerCase()

    if (field.includes("phone")) {
      if (value) return formatPhoneNumber(value)
    }

    if (field.includes("amount")) {
      if (value) return getCurrencyString(Number(value))
    }

    return value ?? "-"
  }

  const organizationNames = [...new Set(historyChangesData.map(({ organizationName }) => organizationName))]

  const organizationNameFilterTemplate = (options) => <Dropdown value={options.value} options={organizationNames} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Name" className="p-column-filter" showClear />

  const dateFilterTemplate = (options) => <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />

  const entities = ["Account Details", "Invoice", "Transaction", "Wire"]

  const entityFilterTemplate = (options) => <Dropdown value={options.value} options={entities} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Type" className="p-column-filter" showClear />

  const userNames = [...new Set(historyChangesData.map(({ userName }) => userName))]

  const userNameFilterTemplate = (options) => <Dropdown value={options.value} options={userNames} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Name" className="p-column-filter" showClear />

  const formatDate = ({ date }) =>
    new Date(date).toLocaleDateString("en-US", {
      minute: "2-digit",
      hour: "2-digit",
    })

  const formatNullable = (value) => value ?? "-"

  const header = () => (
    <div className="flex justify-content-between">
      <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword search" />
      </span>
    </div>
  )

  useEffect(() => {
    initFilters()
  }, [])

  return (
    <DataTable
      value={historyChangesData}
      paginator
      className="p-datatable-customers"
      showGridlines
      rows={10}
      dataKey="id"
      filters={filters}
      filterDisplay="menu"
      loading={loading}
      responsiveLayout="scroll"
      globalFilterFields={globalFilterFields}
      header={header}
      emptyMessage="No history changes found."
    >
      <Column header="Id" field="entityId" style={{ minWidth: "12rem" }} />
      <Column header="Parent Id" field="parentEntityId" body={({ parentEntityId }) => formatNullable(parentEntityId)} style={{ minWidth: "12rem" }} />
      <Column header="Date" dataType="date" field="date" sortable sortField="date" filter filterField="date" filterElement={dateFilterTemplate} body={formatDate} style={{ minWidth: "12rem" }} />
      {role === constants.ROLES.SUPER_ADMIN && <Column header="Organization" field="organizationName" filter filterField="organizationName" filterElement={organizationNameFilterTemplate} filterMenuStyle={{ width: "14rem" }} body={({ organizationName }) => formatNullable(organizationName)} style={{ minWidth: "12rem" }} />}
      <Column header="Name" field="userName" filter filterField="userName" filterElement={userNameFilterTemplate} filterMenuStyle={{ width: "14rem" }} style={{ minWidth: "12rem" }} />
      <Column header="Email" field="userEmail" style={{ minWidth: "12rem" }} />
      <Column header="Type" field="entity" filter filterField="entity" filterElement={entityFilterTemplate} filterMenuStyle={{ width: "14rem" }} style={{ minWidth: "12rem" }} />
      <Column header="Field" field="fieldName" style={{ minWidth: "12rem" }} />
      <Column header="Old value" field="oldValue" body={({ oldValue, fieldName }) => formatValue(oldValue, fieldName)} style={{ minWidth: "14rem" }} />
      <Column header="New value" field="newValue" body={({ newValue, fieldName }) => formatValue(newValue, fieldName)} style={{ minWidth: "10rem" }} />
    </DataTable>
  )
}

export default HistoryChangesTable
