import { useEffect, useMemo, useState } from "react"
import { useUser } from "hooks/useUser"
import HistoryChangesService from "service/HistoryChangesService"
import HistoryChangesTable from "components/HistoryChanges/HistoryChangesTable"

function HistoryChangesIndex() {
  const [historyChangesData, setHistoryChangesData] = useState([])
  const [loading, setLoading] = useState(false)

  const { token } = useUser()

  const historyChangesService = useMemo(() => new HistoryChangesService(), [])

  useEffect(() => {
    setLoading(true)
    const headers = {
      Authorization: "bearer " + token,
    }

    historyChangesService
      .getAll(headers)
      .then(({ data }) => {
        // Convert the values in the 'date' field into a Date object for enabling filtering in the table
        // Also, sort the entries by date
        setHistoryChangesData(data.map((hc) => ({ ...hc, date: new Date(hc.date) })).sort(({ date: dateA }, { date: dateB }) => dateB.getTime() - dateA.getTime()))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [historyChangesService, token])

  return <HistoryChangesTable loading={loading} historyChangesData={historyChangesData} />
}

export default HistoryChangesIndex
