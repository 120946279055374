import constants from "constants/constants"
import { useToast } from "hooks/useToast"
import { useUser } from "hooks/useUser"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { Panel } from "primereact/panel"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import OrganizationService from "service/OrganizationService"

const DashboardForm = () => {
  const [isLoading, setIsLoading] = useState(false)

  const {
    control,
    handleSubmit
  } = useForm({
    defaultValues: {
      name: ""
    },
    reValidateMode: "onChange"
  })

  const { token, setUserData, setRole } = useUser()
  const toast = useToast()

  const organizationService = new OrganizationService()

  const onSubmit = (data) => {
    setIsLoading(true)

    const headers = {
      Authorization: `bearer ${token}`,
    }
    organizationService.createOrganization(headers, data)
    .then(() => {
      const organizationName = data.name
      setUserData({ organization: organizationName })
      setRole(constants.ROLES.ORGANIZATION_ADMIN)
      toast.current.show({ severity: "success", summary: "Organization / Company created", detail: "You are the Organization / Company Admin", sticky: true })
    })
    .catch((error) => {
      toast.current.show({ severity: "error", summary: "Can't create the Organization / Company", detail: error.response.data, sticky: true })
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <Panel className="card">
      <p className="col-6 col-offset-3 card-header">You do not belong to an organization / company. If there is one you want to join, ask the Organization / Company Admin to invite you. Otherwise you can create your own one here:</p>
      <form className="p-fluid formgrid grid" onSubmit={handleSubmit(onSubmit)}>
        <div className="field col-3 col-offset-4 flex flex-column gap-3">
          <label htmlFor="name">Organization / Company Name</label>
          <Controller
            name="name"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required field",
              }
            }}
            render={({ field, fieldState }) => (
              <div className="relative mb-3">
                <InputText
                  id={field.name}
                  className={fieldState.error ? "input-error" : "input-correct"}
                  type="text"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value.trimStart())}
                />
                <br></br>
                {fieldState.error && <span className="p-error absolute bottom-1">{fieldState.error.message}</span>}
              </div>
            )}
          />
          <Button
            loading={isLoading}
            label="Create"
            className="max-w-full align-self-center"
          />
        </div>
      </form>
    </Panel>
  )
}

export default DashboardForm
