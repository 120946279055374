import { useState } from "react"
import { Chart } from "primereact/chart"
import { Fieldset } from "primereact/fieldset"
import { SelectButton } from "primereact/selectbutton"
import { Slider } from "primereact/slider"

const getLastDates = (daysNumber) => {
  const today = new Date()
  const dates = [today]
  const ONE_DAY = 24 * 60 * 60 * 1000

  for (let index = 1; index < daysNumber; index++) {
    dates.unshift(new Date(today.getTime() - ONE_DAY * index))
  }

  return dates.map((date) => date.toLocaleDateString())
}

const equals = (date1, date2) => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)

  return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()
}

const countRegisteredUsers = (users, datesNumber) => {
  const dates = getLastDates(datesNumber)

  const usersCount = dates.reduce((count, date) => ({ ...count, [date]: 0 }), {})

  users.forEach((user) => {
    Object.keys(usersCount).forEach((date) => {
      if (equals(user.createdOn, date)) {
        usersCount[date] += 1
      }
    })
  })

  return usersCount
}


export default function UserCharts({ users, activeUsersStats }) {
  const [datesNumber, setDatesNumber] = useState(7)

  const dates = getLastDates(datesNumber)
  const registeredUsersCount = countRegisteredUsers(users, datesNumber)
  const ONE_DAY = 24 * 60 * 60 * 1000
  // Filter dates outside the range specified by `datesNumber`
  const activeUsersCount = Object.keys(activeUsersStats).reduce((acc, date) => {
    if (new Date(date).getTime() < new Date().getTime() - datesNumber * ONE_DAY) {
      return acc
    } else {
      return { ...acc, [date]: activeUsersStats[date] }
    }
  }, {})

  const basicData = {
    labels: dates,
    datasets: [
      {
        label: "Registered",
        borderColor: "#1aa260",
        data: registeredUsersCount,
        fill: false,
        tension: .4,
      },
      {
        label: "Active",
        borderColor: "#4E86E3",
        data: activeUsersCount,
        fill: false,
        tension: .4,
      },
    ],
  }

  const basicOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  }

  return (
    <>
      <div className="card p-5">
        <div className="card-header flex mb-6">
          <h3>Users per day</h3>
          <div className="flex gap-8">
            <Fieldset className="flex-column gap-4 align-items-center">
              <div className="p-fieldset-legend mb-3">
                <h6 className="p-fieldset-legend-text mb-0">Number of days</h6>
              </div>
              <div className="flex gap-4 align-items-center">
                <Slider className="flex-grow-1" min={7} max={52} value={datesNumber} onChange={(e) => setDatesNumber(e.value)} />
                <span>{datesNumber}</span>
              </div>
            </Fieldset>
          </div>
        </div>
        <Chart type="line" data={basicData} options={basicOptions} />
        <div className="p-card-footer mt-4">
          <p>
            <em>Note: </em>registered users are those that have created an account on the platform. Active users are all users that have visited the site (this includes unregistered users). The active users count for the current date won't be available until tomorrow.
          </p>
        </div>
      </div>
    </>
  )
}
