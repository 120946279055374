import { Controller, useForm } from "react-hook-form"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { emailRegex, trimObjectValues } from "utilities/format"

import AccountService from "service/AccountService"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password"
import constants from "constants/constants"
import jwt_decode from "jwt-decode"
import munisafeLogo from "assets/login-logo.svg"
import { useEffect, useState } from "react"
import { useToast } from "hooks/useToast"
import { useUser } from "hooks/useUser"
import { useOktaAuth } from "@okta/okta-react"

export const Login = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const redirectToInvitationParticipantId = searchParams.get("redirectToInvitationParticipantId")
  const redirectToInvitationWireId = searchParams.get("redirectToInvitationWireId")
  const { oktaAuth, authState } = useOktaAuth()
  useEffect(() => {
    if (!authState?.isAuthenticated && process.env.REACT_APP_ENV !== "dev") {
      oktaLogin()
    }
    console.log(authState)
  }, [authState])
  const [isLoading, setIsLoading] = useState(false)

  const { setToken, setUserData, setRole } = useUser()

  const toast = useToast()

  const accountService = new AccountService()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    reValidateMode: "onChange",
  })

  const onSubmit = (event) => {
    setIsLoading(true)

    const body = trimObjectValues({
      email: event.email,
      password: event.password,
    })

    accountService
      .login(body)
      .then(({ data }) => {
        setToken(data.token)
        setUserData({
          name: data.name,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
          email: data.email,
          title: data.title,
          organization: data.organization,
          orgRole: data.orgRole,
          isUnderwriter: data.isUnderwriter,
          profilePicture: data.profilePicture,
        })
        const decoded = jwt_decode(data.token)
        setRole(decoded[`${constants.CLAIM_TYPE_NAMESPACE_2008}role`])
        if (redirectToInvitationParticipantId) {
          navigate({
            pathname: `/participant-invitation/${redirectToInvitationParticipantId}`,
          })
        } else if (redirectToInvitationWireId) {
          navigate({
            pathname: `/wire-invitation/${redirectToInvitationWireId}`,
          })
        } else {
          navigate("/")
        }
      })
      .catch((error) => {
        // Wrong password
        if (error.response.status === 400) {
          toast.current.show({ severity: "error", summary: error.response.data, life: 5000 })
        }
        // Unverified email
        if (error.response.status === 401) {
          toast.current.show({ severity: "error", summary: error.response.data, detail: "An email was sent to you to verify your account", sticky: true })
        }
        // Account inactive or blocked
        if (error.response.status === 403) {
          toast.current.show({ severity: "error", summary: "Can't login", detail: error.response.data, sticky: true })
        }
        if (error.response.status === 404) {
          toast.current.show({ severity: "error", summary: "User not found", life: 5000 })
        }
      })
      .finally(() => setIsLoading(false))
  }
  const oktaLogin = () => {
    oktaAuth.signInWithRedirect().then(() => {
      console.log("use login success")
    })
  }
  const isLocal = process.env.REACT_APP_ENV === "dev"

  return (
    <div className="login-page">
      {isLocal ? (
        <>
          <div className="login-header">
            <img src={munisafeLogo} alt="munisafe-logo" className="login-logo" />
            <p className="login-header-text inria-serif-regular">Secure the details of your municipal bond closing.</p>
            <button onClick={oktaLogin}>Login with Okta</button>
          </div>
          <div className="login-body">
            <div className="login-wrapper">
              <div className="login-panel">
                <div className="col-12 md:col-12">
                  <form onSubmit={handleSubmit(onSubmit)} className="card p-fluid">
                    <h2 className="heading">Login</h2>
                    <p className="login-text">Login with with your official Email ID</p>

                    <div className="field">
                      <label htmlFor="email" className="login-label">
                        Email
                      </label>
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Required field",
                          },
                          pattern: {
                            value: emailRegex,
                            message: "Invalid format",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <div className="relative">
                            <InputText id={field.name} className={fieldState.error ? "input-error" : "input-correct"} type="text" placeholder="Email" value={field.value} onChange={(e) => field.onChange(e.target.value.trim())} />
                            <br />
                            {fieldState.error && <span className="p-error absolute left-0">{fieldState.error.message}</span>}
                          </div>
                        )}
                      />
                    </div>

                    <div className="field">
                      <label htmlFor="password" className="login-label">
                        Password
                      </label>
                      <Controller
                        name="password"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Required field",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <div className="relative">
                            <Password id={field.name} inputClassName={fieldState.error ? "input-error" : "input-correct"} feedback={false} toggleMask placeholder="Password" value={field.value} onChange={(e) => field.onChange(e.target.value.trim())} />
                            <br />
                            {fieldState.error && <span className="p-error absolute left-0 bottom-0">{fieldState.error.message}</span>}
                          </div>
                        )}
                      />
                    </div>
                    <div className="field">
                      <Button loading={isLoading} label="Submit" id="register-button" />
                    </div>
                    <p className="forgot-password">
                      <Link to="/password-reset">Forgot Password?</Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <p id="already-login">
              New to MuniSafe? <a href="/register">Join now.</a>
            </p>
          </div>
          <div class="login-footer">
            <ul class="footer-nav">
              <li>
                <Link to="#">Contact Us</Link>
              </li>
              <li>
                <Link to="#">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="#">Privacy policy</Link>
              </li>
            </ul>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}
